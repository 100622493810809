<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            行銷應用中心
          </b-breadcrumb-item>

          <b-breadcrumb-item :to="{ name: 'BenefitCardList' }">
            權益卡列表
          </b-breadcrumb-item>

          <b-breadcrumb-item active
          >{{
              isEditing ? '編輯權益卡' : '新增權益卡'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-2 mail-sidebar pt-3">
          <div class="menu-bar">
            <ul class="menu-items">
              <li :class="{ active: step == '' }" @click="goToStep('')">
                <a>基本設定</a>
              </li>
              <li :class="{ active: step == '#customer-level', disabled: !benefitCard.id}" @click="goToStep('#customer-level')">
                <a>會員等級設定</a>
              </li>
              <li :class="{ active: step == '#benefit', disabled: !benefitCard.id}" @click="goToStep('#benefit')">
                <a>權益設定</a>
              </li>
              <li :class="{ active: step == '#content', disabled: !benefitCard.id}" @click="goToStep('#content')">
                <a>內容設定</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-10 main-view">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- 基本資料 section start -->
              <section v-if="step == ''">
                <!-- 欄位部分 Start -->
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-6 mb-3 font-weight-bold">{{ isEditing ? '編輯權益卡' : '新增權益卡' }}</h4>
                  <div class="col-12 col-xl-8">
                    <validation-error-alert
                      v-if="validationErrors"
                      :errors="validationErrors"
                    ></validation-error-alert>
                    <!-- 名稱 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*名稱欄位"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.benefitCard.title.$error ? false: null"
                        v-model="benefitCard.title"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!v$.benefitCard.title.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 名稱 End -->
                    <!-- 是否開放 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="是否開放"
                    >
                      <b-form-checkbox v-model="benefitCard.is_enabled" :value="1" :unchecked-value="0">開放</b-form-checkbox>
                    </b-form-group>
                    <!-- 是否開放 End -->
                    <!-- 開始時間 start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="開始時間"
                    >
                      <template>
                        <AppDatePicker
                          v-model="benefitCard.start_at"
                          placeholder="開始時間"
                        />
                      </template>
                    </b-form-group>
                    <!-- 開始時間 end -->
                    <!-- 結束時間 start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="結束時間"
                    >
                      <template>
                        <AppDatePicker
                          v-model="benefitCard.end_at"
                          placeholder="結束時間"
                        />
                      </template>
                    </b-form-group>
                    <!-- 結束時間 end -->
                    <!-- 順序 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="*順序"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="text"
                        :state="v$.benefitCard.order.$error ? false: null"
                        v-model="benefitCard.order"
                      ></b-form-input>
                      <b-form-invalid-feedback :state="!v$.benefitCard.order.$error">
                        此欄位為必填
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- 順序 End -->
                    <!-- 卡片主圖 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                    >
                      <template #label>
                        卡片主圖
                        <p class="text-black-50 mt-2" style="font-size: .75rem">寬 x 高：1400px x 630px</p>
                      </template>
                      <template #default>
                        <AppFileUploader
                          v-model="benefitCard.config.page_image"
                          placeholder-when-uploaded="（查看檔案）"
                          placeholder-when-empty=""
                        />
                      </template>
                    </b-form-group>
                    <!-- 卡片主圖 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 基本資料 section end -->

              <!-- 會員等級設定 section start -->
              <section v-if="step == '#customer-level'">
                <NameBlock :label="'權益卡名稱'" :name="benefitCard.title" />
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-12 mb-3 font-weight-bold">會員等級設定</h4>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-3">
                  <!-- 選擇會員等級按鈕區 start -->
                  <b-button
                    class="flex-shrink-0 mr-2"
                    variant="inverse-primary"
                    v-b-modal.modal-search-customer-level
                    :disabled="showLoading"
                  ><i class="fa fa-plus"></i>選擇會員等級</b-button>
                  <!-- 選擇會員等級按鈕區 end -->
                </div>

                <!-- 會員等級清單 Start -->
                <b-table v-show="selectedCustomerLevels.length > 0" striped hover responsive :items="selectedCustomerLevels" :fields="customerLevelFields">
                  <template #cell(title)="data">
                    <router-link :to="{ name: 'CustomerLevelsEdit', params: { id: data.item.id } }">
                      <div>{{ deepGet(data.item, 'name') }}</div>
                    </router-link>
                  </template>
                  <template #cell(description)="data">
                    <div>{{ deepGet(data.item, 'description') }}</div>
                  </template>
                  <template #cell(created_at)="data">
                    <div>{{ formatDate(deepGet(data.item, 'created_at')) }}</div>
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      class="mr-1"
                      variant="inverse-info"
                    >
                      <span class="mdi mdi-settings"></span> 設定
                    </b-button>
                    <b-button
                      class="mr-1"
                      variant="inverse-danger"
                      @click="deleteCustomerLevel(data.item)"
                    >
                      <span class="mdi mdi-delete"></span> 移除
                    </b-button>
                  </template>
                </b-table>
                <!-- 會員等級清單 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <CustomerLevel :selectedCustomerLevels="selectedCustomerLevels" @submit="handleSubmitCustomerLevel" />
              <!-- 會員等級設定 section end -->

              <!-- 權益設定 section start -->
              <section v-if="step == '#benefit'">
                <NameBlock :label="'權益卡名稱'" :name="benefitCard.title" />
                <div class="row d-flex mb-12 mb-xl-12">
                  <div class="col-12 col-xl-12 mb-12 d-flex align-items-center">
                    <h4 class="font-weight-bold">
                      權益設定
                    </h4>
                    <div v-if="isBenefitSetting" class="ml-2" v-text="benefitSettingText">
                      / 新增權益
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between mb-3" v-if="!isBenefitSetting">
                  <!-- 新增權益按鈕區 start -->
                  <b-button
                    class="flex-shrink-0 mr-2"
                    variant="inverse-primary"
                    @click="selectBenefitSetting(standardBenefitSetting)"
                    :disabled="showLoading"
                  ><i class="fa fa-plus"></i>新增權益</b-button>
                  <!-- 新增權益按鈕區 end -->
                </div>

                <!-- 編輯權益區塊 Start -->
                <BenefitSetting ref="benefitSetting" v-show="isBenefitSetting" :selectedCoupon="selectedCoupon" :selectedBenefitSetting="selectedBenefitSetting" @close="closeBenefitSetting" @submit="handleSubmitBenefitSetting" />
                <!-- 編輯權益區塊 End -->

                <!-- 權益清單 Start -->
                <b-table v-show="benefitSettings.length > 0 && !isBenefitSetting" striped hover responsive :items="sortedBenefitSettings" :fields="benefitSettingFields">
                  <template #cell(title)="data">
                    <router-link :to="{ name: 'RedeemCodeBundleEdit', params: { redeemCodeBundleId: data.item.id } }">
                      <div>{{ deepGet(data.item, 'name') }}</div>
                    </router-link>
                  </template>
                  <template #cell(tab_image)="data">
                    <b-img-lazy
                      v-if="deepGet(data.item, 'tab_image')"
                      class="thumb-image"
                      :src="deepGet(data.item, 'tab_image')"
                      alt="圖片"
                      style="width: auto"
                      height="50"
                    />
                  </template>
                  <template #cell(coupon.img_url)="data">
                    <b-img-lazy
                      v-if="deepGet(data.item, 'coupon.img_url')"
                      class="thumb-image"
                      :src="deepGet(data.item, 'coupon.img_url')"
                      alt="圖片"
                      width="50"
                      height="50"
                    />
                  </template>
                  <template #cell(updated_at)="data">
                    <div>{{ formatDate(deepGet(data.item, 'updated_at')) }}</div>
                  </template>
                  <template #cell(actions)="data">
                    <b-button
                      class="mr-1"
                      variant="inverse-info"
                      @click="selectBenefitSetting(data.item)"
                    >
                      <span class="mdi mdi-settings"></span> 編輯
                    </b-button>
                    <b-button
                      class="mr-1"
                      variant="inverse-danger"
                      @click="deleteBenefitSetting(data.item)"
                    >
                      <span class="mdi mdi-delete"></span> 移除
                    </b-button>
                  </template>
                </b-table>
                <!-- 權益清單 End -->
              </section>
              <!-- 權益設定 section end -->

              <!-- 內容設定 section start -->
              <section v-if="step == '#content'">
                <NameBlock :label="'權益卡名稱'" :name="benefitCard.title" />
                <div class="row d-flex mb-4 mb-xl-2">
                  <h4 class="col-12 col-xl-12 mb-3 font-weight-bold">內容設定</h4>
                  <div class="col-12 col-xl-8">
                    <!-- config設定 Start -->
                    <b-form-group
                      label-cols="12"
                      label-cols-lg="2"
                      label-size="sm"
                      label="內容設定"
                    >
                      <JsonEditor
                        height="128px"
                        v-model="benefitCard.config"
                        ref="statusFilterMapping"
                      />
                      <b-form-invalid-feedback>
                        {{ presentValidationErrors('benefitCard.config') }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                    <!-- config設定 End -->
                  </div>
                </div>
                <!-- 欄位部分 End -->
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </section>
              <!-- 內容設定 section end -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->

  </div>
</template>

<style>
.ckeditor-container {
  height: 400px; /* 自定義高度 */
}

.ckeditor-container .ck-editor__editable {
  min-height: 400px; /* 確保編輯區域的高度一致 */
}

.ck-editor__editable_inline {
  min-height: 200px;
  max-height: 400px; /* 如果需要限制高度 */
  height: 200px;
}
</style>

<script>
import {zh} from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import benefitCardApi from "@/apis/benefitCard";
import mergeDefaultAndDataModel from "@/utils/mergeDefaultAndDataModel";
import deepGet from "lodash/get";
import {mapGetters} from "vuex";
import collectionApi from "@/apis/collection";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import JsonEditor from "@/components/Dashboard/JsonEditor.vue";
import AppDatePicker from "@/components/AppDatePicker.vue";
import AppFileUploader from "@/components/AppFileUploader.vue";
import CustomerLevel from "@/pages/Dashboard/Share/SearchCustomerLevel.vue";
import BenefitSetting from "@/pages/Dashboard/BenefitCard/BenefitSetting.vue";
import { merge, sortBy } from "lodash";
import NameBlock from "@/pages/Dashboard/Share/NameBlock.vue";

export default {
  components: {
    NameBlock,
    BenefitSetting,
    CustomerLevel,
    AppDatePicker,
    AppFileUploader,
    JsonEditor,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      benefitCard: {
        title: { required },
        order: { required },
      }
    };
  },
  computed: {
    consts() {
      return consts
    },
    sortedBenefitSettings() {
      return sortBy(this.benefitSettings, 'order')
    },
    ...mapGetters("route", ["routeQuery"]),
  },
  data() {
    const today = new Date(); // 今天
    const tomorrow = new Date(today); // 複製今天的日期物件
    tomorrow.setDate(today.getDate() + 1); // 加 1 天
    const formatDate = (date) => {
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    };
    return {
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      isBenefitSetting: false,
      benefitSettingText: "",
      step: '',
      benefitCard: {
        title: null,
        is_enabled: 1,
        order: 1,
        config: {
          page_image: null,
          data_source : [],
          customer_levels: [],
        },
        start_at: formatDate(today),
        end_at: formatDate(tomorrow),
      },
      permissionChecker: new PermissionChecker(),
      products: [],
      benefitSettings: [],
      selectedBenefitSetting: {
      },
      standardBenefitSetting: {
        tab_name: "",
        path: "",
        order: 1,
        tab_image: "",
        form_title: "",
        form_desc: "",
        content: "",
        tab_button_text: "",
        button_text: "",
        button_action: "",
        button_url: "",
        redeem_code_bundles : [{
          hashid : "",
          quantity: 1
        }]
      },
      selectedCustomerLevels: [],
      benefitSettingFields: [
        { key: "tab_image" , label: "圖片" },
        { key: "tab_name"  , label: "名稱" },
        // { key: "coupon.name", label: "優惠券名稱" },
        // { key: "coupon.img_url", label: "優惠券圖片" },
        // { key: "redeem_code_bundles.quantity"  , label: "優惠券數量" },
        { key: "order"     , label: "順序" },
        { key: "updated_at", label: "更新日期" },
        { key: "actions"   , label: "管理" },
      ],
      customerLevelFields: [
        { key: "title", label: "名稱" },
        { key: "description", label: "說明" },
        { key: "created_at", label: "建立日期" },
        { key: "actions", label: "管理" },
      ],
      selectedCoupon: {},
      coupons: []
    }
  },
  mounted() {
    if (this.$route.name === "BenefitCardEdit") {
      this.isEditing = true;
      this.benefitCard.id = this.$route.params.id;
      this.fetchBenefitCard();
      this.navigateToStepFromCurrentRoute();
    } else {
      this.isEditing = false;
      this.showLoading = true;
      this.getMaxOrder();
    }
    this.fetchCollections();
  },
  methods: {
    deepGet,
    mergeDefaultAndDataModel,
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('zh-TW', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      }).format(date).replace(/\//g, '-');
    },
    async fetchCollections() {
      try {
        let response = await collectionApi.getCollections({
          with_items: 1,
          is_enabled: 1,
        });
        this.collectionOptions = response.data.data.map((collection) => {
          return {
            value: collection.id,
            text: collection.name + ' 共 ' + collection.items_count + ' 筆資料',
            items: collection.items,
            config: collection.config,
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    presentValidationErrors(validationKey) {
      return deepGet(this.v$, validationKey + '.$errors', []).map(error => error.$message).join(', ')
    },
    cancel() {
      this.$router.push({ name: 'BenefitCardList', query: this.routeQuery });
    },
    goToStep(step) {
      if (this.step == step) {
        return;
      }

      this.step = step
      if (location.hash !== this.step) {
        // NOTE: 這裡不使用 $router.replace，以避免 page rerender 導致 mounted 或其他 lifecycle hook 的內的 API 重複執行
        const newLocation = new URL(location.href)
        newLocation.hash = this.step
        window.history.replaceState('', '', newLocation.href);
      }
    },
    navigateToStepFromCurrentRoute() {
      const anchor = this.$route.hash;
      if (anchor) {
        this.goToStep(anchor);
      }
    },
    async submit(needValidate = true) {
      if (needValidate) {
        const result = await this.v$.$validate();
        if (!result) return false;
      }

      try {
        this.showLoading = true;
        // 編輯表單
        if (this.isEditing) {
          await benefitCardApi.updateBenefitCard(this.benefitCard.id, this.benefitCard);
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          let response = await benefitCardApi.createBenefitCard({ ...this.benefitCard });
          this.benefitCard = merge({}, this.benefitCard, response.data.data);
          this.$swal("新增成功", "", "success");
          this.isEditing = true;
          this.$router.replace({ name: "BenefitCardEdit", params: { id: response.data.data.id } })
        }

        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchBenefitCard() {
      if (! this.benefitCard.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await benefitCardApi.getBenefitCard(this.benefitCard.id);

        this.benefitCard = merge({}, this.benefitCard, response.data.data, {
          config: {
            data_source: this.benefitCard.config.data_source
          }
        });

        await this.fetchConfigs();
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async fetchConfigs() {
      try {
        const { data } = await benefitCardApi.getBenefitConfig(this.benefitCard.id, {});
        this.coupons = data.coupons;
        this.selectedCustomerLevels = data.customer_levels;

        this.benefitSettings = this.benefitCard.config.data_source.map((setting) => {
          return {
            ...setting,
          };
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getMaxOrder() {
      let response = await benefitCardApi.getMaxOrder();
      this.benefitCard.order = response.data.maxOrder+1;
      this.$set(this, 'benefitCard', this.benefitCard);
      this.showLoading = false;
    },
    async saveFields(fields) {
      try {
        this.showLoading = true;

        this.benefitCard.config.fields = fields;

        await benefitCardApi.updateBenefitCard(this.benefitCard.id, this.benefitCard);
        this.$swal("儲存成功", "", "success");
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存資料錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    handleSubmitBenefitSetting(benefitSetting, coupon) {
      // 確保 benefitSetting 是陣列
      const benefitSettingArray = Array.isArray(benefitSetting) ? benefitSetting : [benefitSetting];

      // 確定 data_source 存在，若不存在初始化為空陣列
      this.benefitCard.config.data_source = this.benefitCard.config.data_source || [];

      // 取得目前 data_source 的總數
      const currentCount = this.benefitCard.config.data_source.length;

      // 格式化時間為 yyyy-mm-dd h:i:s
      const formatDateTime = () => {
        const now = new Date();
        const pad = num => num.toString().padStart(2, '0');
        return `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(now.getDate())} ${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(now.getSeconds())}`;
      };

      // 處理每個 benefitSetting
      benefitSettingArray.forEach((setting, index) => {
        // 每次儲存時，更新 updated_at
        setting.updated_at = formatDateTime();

        // 檢查是否有 `id` 欄位
        if (!setting.id) {
          // 如果沒有 `id`，以目前總數 + 1 + index 生成唯一的 `id`
          setting.id = currentCount + index + 1;

          // 新增到 data_source
          this.benefitCard.config.data_source.push(setting);
        } else {
          // 如果有 `id`，找到 data_source 中相同 ID 的項目
          const existingIndex = this.benefitCard.config.data_source.findIndex(
            item => item.id === setting.id
          );
          if (existingIndex !== -1) {
            // 如果找到，然後進行合併
            const updatedSetting = { ...this.benefitCard.config.data_source[existingIndex], ...setting };
            // 要不要刪除 redeem_code_bundles
            if (Object.keys(coupon).length === 0) {
              this.$delete(updatedSetting, 'redeem_code_bundles');
            }
            // 更新項目
            this.$set(this.benefitCard.config.data_source, existingIndex, updatedSetting);
          } else {
            // 如果未找到，新增到 data_source
            this.benefitCard.config.data_source.push(setting);
          }
        }
      });

      // 根據 order 欄位進行降序排列
      this.benefitCard.config.data_source.sort((a, b) => b.order - a.order);

      // 使用淺拷貝更新 config，確保 Vue 能正確監聽變更
      this.benefitCard.config = {
        ...this.benefitCard.config,
        data_source: [...this.benefitCard.config.data_source],
      };

      // 更新 benefitSettings，並加入 coupon 欄位
      this.benefitSettings = this.benefitCard.config.data_source.map((setting) => {
        // 判斷是否為當前的 benefitSetting
        if (setting.id === benefitSetting.id) {
          return {
            ...setting,
            ...benefitSetting, // 更新其餘內容
          };
        }

        return {
          ...setting,
        };
      });

      // 如果有選coupon要加入coupons
      if (!this.coupons.some(existingCoupon => existingCoupon.id === coupon.id)) {
        this.coupons.push(coupon);
      }

      this.submit();
      this.closeBenefitSetting();
    },
    selectBenefitSetting(benefitSetting){
      this.isBenefitSetting = true;

      // 新增的時候自動將order，設定為總數+1
      if (!benefitSetting.id) {
        benefitSetting.order = this.benefitSettings.length +1;
      }

      //
      if (!benefitSetting.id) {
        this.benefitSettingText = "/ 新增權益";
      } else {
        this.benefitSettingText = "/ 編輯權益";
      }

      // 如果 hashid 有值，從 this.coupons 中比對 hashid
      if (Array.isArray(benefitSetting.redeem_code_bundles) && benefitSetting.redeem_code_bundles.length > 0) {
        const matchingRedeemCode = benefitSetting.redeem_code_bundles.find((bundle) =>
          bundle.hashid?.trim()
        );

        if (matchingRedeemCode) {
          // 找到匹配的 hashid，合併 quantity
          const matchingCoupon = this.coupons.find(
            (coupon) => coupon.id === matchingRedeemCode.hashid
          );

          if (matchingCoupon) {
            this.selectedCoupon = {
              ...matchingCoupon, // 將 coupon 的屬性加入
              quantity             : matchingRedeemCode.quantity, // 添加 quantity
              dateline_type        : matchingRedeemCode.dateline_type,
              redeem_code_end_at   : matchingRedeemCode.redeem_code_end_at,
              redeem_code_start_at : matchingRedeemCode.redeem_code_start_at,
              start_days_after_take: matchingRedeemCode.start_days_after_take,
              end_days_after_take  : matchingRedeemCode.end_days_after_take,
            };
          } else {
            this.selectedCoupon = {
              quantity             : matchingRedeemCode.quantity || 0, // 如果找不到 coupon，至少保留 quantity
              dateline_type        : 0,
              redeem_code_end_at   : null,
              redeem_code_start_at : null,
              start_days_after_take: 0,
              end_days_after_take  : 0,
            };
          }
        } else {
          // 沒有匹配的 hashid
          this.selectedCoupon = {};
        }
      } else {
        // redeem_code_bundles 不存在或為空
        this.selectedCoupon = {};
      }

      // 將coupon刪除
      this.selectedBenefitSetting = { ...benefitSetting }; // 使用淺拷貝避免直接修改原始資料

      this.$refs.benefitSetting.initialize(this.selectedBenefitSetting, this.selectedCoupon);
    },
    async deleteBenefitSetting(item) {
      const result = await this.$swal({
        title: "您確定要刪除嗎？",
        text: `權益名稱：${item.tab_name}`,
        type: "warning",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "不要刪除",
        confirmButtonText: "確定刪除",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        reverseButtons: true,
      })
      if (! result.value) {
        return
      }

      this.benefitCard.config = {
        ...this.benefitCard.config,
        data_source: this.benefitCard.config.data_source.filter(
          benefitSetting => benefitSetting.id !== item.id
        ),
      };

      this.benefitSettings = this.benefitSettings.filter(
        benefitSetting => benefitSetting.id !== item.id
      )

      console.log("132134");
      await this.submit(false);
    },
    closeBenefitSetting() {
      this.isBenefitSetting = false;

      window.scrollTo({
        top: 0,
      });
    },
    handleSubmitCustomerLevel(customerLevels) {
      this.selectedCustomerLevels = customerLevels.map(customerLevel => ({
        ...customerLevel, // 保留原始的 coupon 屬性
      }));

      // 使用淺拷貝來更新 config
      this.benefitCard.config = {
        ...this.benefitCard.config,
        customer_levels: this.selectedCustomerLevels.map(customerLevel => ({
          hashid: customerLevel.id,
        })),
      };
    },
    deleteCustomerLevel(item) {
      this.benefitCard.config = {
        ...this.benefitCard.config,
        customer_levels: this.benefitCard.config.customer_levels.filter(
          customerLevel => customerLevel.hashid !== item.id
        ),
      };

      this.selectedCustomerLevels = this.selectedCustomerLevels.filter(
        customerLevel => customerLevel.id !== item.id
      )
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  &.has-checkbox {
    &:after {
      content: "\f134";
      font-family: "Material Design Icons";
      font-size: 16px;
    }
  }

  &.has-checkbox.has-checkbox--checked {
    &:after {
      content: "\f133";
      color: green;
    }
  }
}
</style>
