import https from "./https";

const bodyshop = {
  searchPhone(orgId, params) {
    if (!orgId) {
      return Promise.reject("id not found.");
    }
    const searchParams = new URLSearchParams(params);

    return https.get(`/admin/organizations/${orgId}/bodyshop?${searchParams}`);
  },
};

export default bodyshop
