<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              參數設定
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              ELC 查詢
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">ELC 查詢</h4>
        </div>
      </div>

      <h6 class="mt-3">條件設定</h6>
      <b-form-group>
        <b-form-radio-group
          v-model="queryCondition"
          :options="queryConditionOptions"
        ></b-form-radio-group>
      </b-form-group>
      <template v-if="queryCondition === 0">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="手機"
        >
          <b-form-input type="text" v-model="query.phone"></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="姓名"
        >
          <b-form-input type="text" v-model="query.name"></b-form-input>
        </b-form-group>
      </template>
      <template v-if="queryCondition === 1">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="ELC Master ID"
        >
          <b-form-input type="text" v-model="query.elc_master_id"></b-form-input>
        </b-form-group>
      </template>
      <template v-if="queryCondition === 2">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="LINE UID"
        >
          <b-form-input type="text" v-model="query.line_id"></b-form-input>
        </b-form-group>
      </template>
      <!-- 是否使用 MD5 Start -->
      <b-form-checkbox v-model="query.uses_md5" value="1" unchecked-value="0">使用 MD5</b-form-checkbox>
      <!-- 是否使用 MD5 End -->
      <b-button
        class="mt-3 mr-2"
        variant="info"
        @click="handleSearch"
        :disabled="isSubmmiting"
      >
        查詢
      </b-button>

      <JsonEditor
        class="mt-4 mb-2"
        :lines="16"
        v-model="result"
      />
    </b-card>
  </div>
</template>

<script>
import elcApi from "@/apis/elc"
import JsonEditor from "@/components/Dashboard/JsonEditor.vue";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import { mapState } from "vuex";

export default {
  components: { JsonEditor },
  data() {
    return {
      result: "",
      isSubmmiting: false,
      queryCondition: 0,
      query: {
        phone: undefined,
        name: undefined,
        elc_master_id: undefined,
        line_id: undefined,
        uses_md5: 0,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    orgId() {
      return this.organization.id;
    },
    queryConditionOptions(){
      return [
        { text: "手機 + 姓名", value: 0 },
        { text: "ELC Master UID", value: 1 },
        { text: "LINE UID", value: 2 },
      ]
    },
  },
  mounted() {
    if (!this.checkPermission([consts.ELC_SETTING_QUERY])) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({
            name: "OrganizationVariableList",
          });
        });
    }
  },
  watch: {
    queryCondition(val) {
      if (val === 0) {
        this.query.elc_master_id = undefined
        this.query.line_id = undefined
      } else if (val === 1) {
        this.query.phone = undefined
        this.query.name = undefined
        this.query.line_id = undefined
      } else if (val === 2) {
        this.query.phone = undefined
        this.query.name = undefined
        this.query.elc_master_id = undefined
      }
    },
  },
  methods: {
    async handleSearch() {
      try {
        this.isSubmmiting = true;
        this.result = "查詢中...";
        const result = await elcApi.searchMember(
          this.orgId,
          Object.fromEntries(
            Object.entries(this.query)
              .filter(([key, value]) => value !== undefined) // eslint-disable-line
          )
        );
        this.result = result.data
      } catch (error) {
        this.result = error.response.data
        console.error(error)
      }
      this.isSubmmiting = false;
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>
