<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>控制中心</b-breadcrumb-item>
            <b-breadcrumb-item active>行銷規則管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-8 mb-2 font-weight-bold">行銷規則管理</h4>
        <b-input-group class="col-12 col-xl-4 mb-2">
          <b-form-input
            v-model="query.keyword"
            placeholder="搜尋"
            v-on:keyup.enter="handlePageReset"
          ></b-form-input>
          <b-input-group-append>
            <b-button @click="handlePageReset"
              ><i class="fa fa-search"></i
            ></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-end">
        <div class="col-12 col-xl-6 d-flex flex-xl-row align-items-xl-center">
          <button
            type="button"
            class="btn flex-shrink-0 ml-2 btn-inverse-info"
            v-b-modal.modal-import
          >
            <span class="mdi mdi-cloud-upload"></span> 批次匯入
          </button>
          <button
            type="button"
            class="btn flex-shrink-0 ml-2 btn-inverse-info"
            @click="exportRule"
          >
            <span class="mdi mdi-download"></span> 批次匯出
          </button>
          <b-button
            v-if="checkPermission([consts.MKT_RULE_LIST_CREATE])"
            class="flex-shrink-0 mb-xl-0 mr-3"
            variant="primary"
            :to="{ name: 'MarketingRuleCreate' }"
            ><i class="fa fa-plus"></i>新增行銷規則</b-button
          >
          <b-form-select
            v-model="query.merchant_id"
            :options="merchants"
            @change="handlePageReset"
          ></b-form-select>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table striped hover responsive :items="rules" :fields="fields">
                <template #cell(actions)="data">
                  <b-button
                    variant="inverse-primary"
                    :to="{
                      name: 'MarketingRuleDetail',
                      params: { marketingRuleId: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-eye mr-1"></span>查看
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.MKT_RULE_LIST_MODIFY])"
                    variant="inverse-warning"
                    :to="{
                      name: 'MarketingRuleEdit',
                      params: { marketingRuleId: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-lead-pencil mr-1"></span>編輯
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.MKT_RULE_LIST_DELETE])"
                    variant="inverse-danger"
                    @click="deleteMarketingRule(data.item)"
                  >
                    <span class="mdi mdi-delete mr-1"></span>刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <CustomPagination
          :currentPage="query.page"
          :totalRows="totalRows"
          :perPage="query.per_page"
          @page-change="handlePageChange"
          @per-page-change="handlePerPageChange"
        />
      </b-card-text>
    </b-card>

    <b-modal id="modal-import" title="批次匯入">
      <b-overlay :show="showLoadingUpload">
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0"
            >請上傳行銷規則 Excel 檔案</label
          >
          <b-form-file plain @input="importRule" placeholder="尚未選擇檔案" browse-text="瀏覽"></b-form-file>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="outline-primary"
          href="/excel/marketing_rule_list.xlsx"
          ><i class="fa fa-file-text"></i>下載範例檔案
        </b-button>
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import marketingRuleApi from "@/apis/marketing-rule";
import merchantApi from "@/apis/merchant";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import store from "@/store";
import option from "./option.json";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: { CustomPagination },
  mixins: [paginationMixin],
  data() {
    return {
      consts: consts,
      totalRows: 0,
      showLoading: true,
      rules: [],
      merchants: [],
      merchantId: null,
      fields: [
        {
          key: "order",
          label: "比對順序",
        },
        {
          key: "keyword",
          label: "比對關鍵字",
        },
        {
          key: "matched_type",
          label: "比對類型",
          formatter: (value) => {
            return option.matchedTypeOptions.find(
              (option) => option.value === value
            )?.text;
          },
        },
        {
          key: "matched_code",
          label: "比對目標",
          formatter: (value) => {
            return option.matchedCodeOptions.find(
              (option) => option.value === value
            )?.text;
          },
        },
        {
          key: "convert_value",
          label: "轉換內容",
        },
        {
          key: "convert_ratio",
          label: "轉換比例",
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      showLoadingUpload: false,
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        keyword: null,
        merchant_id: null,
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.getMarketingRules();
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.fetchMerchants();
      this.initialized = true;
    },
    async getMarketingRules() {
      if (!this.query.merchant_id) {
        this.rules = [];
        return;
      }
      this.showLoading = true;
      try {
        let params = {
          ...this.query,
        };

        const { data } = await marketingRuleApi.getMarketingRules(
          this.query.merchant_id,
          params
        );
        this.rules = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得行銷規則資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteMarketingRule(rule) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>行銷規則：${rule.keyword}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await marketingRuleApi.removeMarketingRule(rule.id);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 " + rule.keyword,
              });
              this.getMarketingRules();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      if (!this.query.merchant_id) {
        let defaultMerchant;
        if (data.data.length === 1) {
          defaultMerchant = data.data[0];
        } else {
          defaultMerchant = data.data
            .filter((merchant) => merchant.type === consts.MERCHANT_TYPES.LINE_TYPE)
            .shift();
        }

        if (defaultMerchant) {
          this.query.merchant_id = defaultMerchant.id;
        }
      }

      if (this.query.merchant_id) {
        await this.getMarketingRules();
      }
    },
    async importRule(file) {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", file);

      try {
        let response = await marketingRuleApi.import(
          this.query.merchant_id,
          formData
        );

        if (response.status === 200 && response.data.status === "success") {
          this.$swal.fire({
            title: "上傳成功",
            type: "success",
          });

          this.getMarketingRules();
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 422 && e.response.data.message[0]) {
          this.$swal.fire({
            title: "檔案內容有誤",
            type: "error",
            text: e.response.data.message[0] ?? null,
          });
        } else {
          this.$swal.fire({
            title: "上傳失敗",
            type: "error",
          });
        }
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-import");
      }
    },
    async exportRule() {
      let url =
        `${process.env.VUE_APP_API_BASE_URL}/admin/merchants/${this.query.merchant_id}/marketing-rules/export` +
        `?keyword=${this.query.keyword || ""}&token=${store.state.auth.token}`;

      window.open(url);
    },
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}

.vdp-datepicker {
  min-width: 15rem;
}
</style>
