<template>
  <div>
    <div class="page">
      <h1 class="form-title">請詳實填寫收件資訊</h1>

      <div class="my-4 mx-2 field">
        <div class="my-4">
          <label class="field__label field--required">
            收件姓名
          </label>
          <input
            class="field__input"
            v-model="customer_name"
            :class="{ invalid: v$.customer_name.$error }"
            placeholder="請輸入收件名稱"
          />
          <div
            v-if="v$.customer_name.$error"
            class="form__error-msg"
          >
            輸入的資料未填寫
          </div>
        </div>
        <div class="my-4">
          <label class="field__label field--required">
            收件地址
          </label>
          <input
            class="field__input"
            v-model="customer_address"
            :class="{ invalid: v$.customer_address.$error }"
            placeholder="請輸入收件地址"
          />
          <div
            v-if="v$.customer_address.$error"
            class="form__error-msg"
          >
            輸入的資料未填寫或輸入特殊字元＠
          </div>
        </div>
        <div class="my-4">
          <label class="field__label field--required">
            聯絡電話
          </label>
          <input
            class="field__input"
            v-model="customer_mobile"
            :class="{ invalid: v$.customer_mobile.$error }"
            placeholder="請輸入聯絡電話"
          />
          <div
            v-if="v$.customer_mobile.$error"
            class="form__error-msg"
          >
            輸入的資料未填寫
          </div>
        </div>
      </div>

      <div class="reward-notice s-p-3">
        <ol>
          <li>請勿必詳實填寫贈品收件資訊。</li>
          <li>
            如因填寫資訊有誤致使贈獎程序無法完成，衍生之成本與寄送責任應由贈獎者自行負擔。
          </li>
        </ol>
      </div>

      <SharedButton
        class="s-btn-bg-primary"
        type="button"
        @click="submitForm"
        :disabled="isLoading"
      >
        送出
      </SharedButton>
    </div>
  </div>
</template>

<script>
import SharedButton from "@/components/Page/Liff/Shared/Button";
import abbottAndApi from "@/apis/liff/v2/proj/abbott-and";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  components: { SharedButton },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  data() {
    return {
      customer_name: null,
      customer_address: null,
      customer_mobile: null,
      isLoading: false,
    };
  },
  validations() {
    const atSignValidation = (value) => !/@/.test(value);
    const mobile =  (val) => /09[0-9]{8}/.test(val);
    return {
      customer_name: { required },
      customer_address: { required, atSignValidation },
      customer_mobile: { required, mobile },
    };
  },
  mounted() {},
  methods: {
    async submitForm() {
      if (this.isLoading) {
        return;
      }
      try {
        this.isLoading = true
        const result = await this.v$.$validate();
        if (!result) {
          return;
        }
        await abbottAndApi.redeemLoyalty(
          this.$route.params.orgCode,
          {
            loyalty_id: this.$route.params.id,
            customer_name: this.customer_name,
            customer_address: this.customer_address,
            customer_mobile: this.customer_mobile,
          }
        );
        this.isLoading = false
        this.$router.push({
          name: "LiffProjAbbottAndRewardsCardDetail",
          params: {
            orgCode: this.$route.params.orgCode,
            id: this.$route.params.id,
          },
        });
      } catch (e) {
        this.isLoading = false
        console.error(e);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../node_modules/font-awesome/css/font-awesome.min.css";
@import "@/assets/scss/shared/components/_fields.scss";

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);
  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color);
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    b {
      color: var(--s-primary);
      font-weight: 600;
    }
  }
  &__content {
    margin: 10px 20px;
  }
}

.form-title {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  margin-top: 12px;
  margin-bottom: 12px;
}

.reward-notice {
  border-top: solid 1px #e5e5ea;
  font-size: 14px;
  font-weight: 400;
  color: #636366;

  &__title {
    font-weight: 600;
    font-size: 16px;
  }
  ol {
    list-style: decimal;
    padding: inherit;

    li {
      margin-bottom: 0.5rem;
    }
    li::marker {
      color: var(--s-primary);
      font-size: 600;
    }
  }
}

.form {
  padding: 0 5px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}
</style>
