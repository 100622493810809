import { render, staticRenderFns } from "./BenefitCardHome.vue?vue&type=template&id=6c156dfb&scoped=true&"
import script from "./BenefitCardHome.vue?vue&type=script&lang=js&"
export * from "./BenefitCardHome.vue?vue&type=script&lang=js&"
import style0 from "./BenefitCardHome.vue?vue&type=style&index=0&id=6c156dfb&lang=scss&scoped=true&"
import style1 from "./BenefitCardHome.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c156dfb",
  null
  
)

export default component.exports