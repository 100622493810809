<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>組織資料中心</b-breadcrumb-item>
            <b-breadcrumb-item active>系統紀錄</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row">
        <h4 class="col-12 mb-4 font-weight-bold">
          {{ `【${organization.name}】系統紀錄` }}
        </h4>
      </div>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            col-12 col-xl-6
            d-flex
            align-items-start align-items-xl-center
            mb-2
          "
        >
          <b-form-select
            v-model="category"
            :disabled="categories.length == 1"
            :options="categories"
            @change="fetchServerLogs(1)"
          />
        </div>
        <div
          class="
            col-12 col-xl-6
            d-flex
            align-items-start align-items-xl-center
            mb-2
          "
        >
          <b-input-group>
            <b-form-input
              v-on:keyup.enter="fetchServerLogs(1)"
              v-model="keyword"
              placeholder="搜尋"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="fetchServerLogs(1)">
                <i class="fa fa-search"></i>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="serverLogs"
        :fields="fields"
        :busy="isLoading"
        @sort-changed="handleSort"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(logs)="data">
          <b-button
            v-if="data.item.logs.length > 60"
            v-b-modal.modal-full-view
            class="flex-shrink-0 mr-2"
            variant=""
            @click="logForFullView = data.item.logs"
          >
            <span class="mdi mdi-arrow-expand-all"></span>
          </b-button>
          <span>{{ data.item.logs | hiddenString(60) }}</span>
        </template>
        <template #cell(created_at)="data">
          {{ formatDate(data.item.created_at) }}
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="organizationList"
        ></b-pagination>
      </div>
    </b-card>
    <b-modal id="modal-full-view" title="檢視完整 Log 內容">
      <JsonEditor
        height="400px"
        v-model="logForFullView"
      />
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import serverLogApi from "../../../apis/server-log";
import moment from "moment";
import * as consts from "@/consts";
import JsonEditor from "@/components/Dashboard/JsonEditor";
import _ from "lodash";

export default {
  components: { JsonEditor },
  data() {
    return {
      isLoading: false,
      serverLogs: [],
      logForFullView: null,
      keyword: "",
      category: null,
      categories: [{ value: null, text: "請選擇" }],
      orderBy: "desc",
      fields: [
        {
          key: "category",
          label: "類型",
        },
        {
          key: "name",
          label: "名稱",
        },
        {
          key: "type",
          label: "type",
          formatter: (value) => {
            return consts.SERVER_LOG_TYPES[value] ?? "";
          },
        },
        {
          key: "status",
          label: "狀態",
          formatter: (value) => {
            return consts.SERVER_LOG_STATUSES[value] ?? "";
          },
        },
        {
          key: "logs",
          label: "詳細資訊",
          formatter: (value) => {
            const string =
              typeof value === "string" ? value : JSON.stringify(value);
            return this.$options.filters.hiddenString(string, 60);
          },
        },
        {
          key: "memo",
          label: "備忘",
          formatter: (value) => {
            const string =
              typeof value === "string" ? value : JSON.stringify(value);
            return this.$options.filters.hiddenString(string, 60);
          },
        },
        {
          key: "created_at",
          label: "時間",
          sortable: true,
        },
      ],
      perPage: 15,
      currentPage: 1,
      total: 0,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.fetchServerLogCategories().then(() => this.fetchServerLogs());
  },
  watch: {
    currentPage: function (value) {
      this.fetchServerLogs(value);
    },
  },
  methods: {
    async fetchServerLogCategories() {
      const { data } = await serverLogApi.getServerLogCategories();
      this.categories = [
        { value: null, text: "請選擇" },
        ...data.data.map((category) => {
          return { value: category, text: category };
        }),
      ];
    },
    async fetchServerLogs(page) {
      try {
        this.isLoading = true;
        const { data } = await serverLogApi.getServerLogs(
          _.omitBy(
            {
              page: isNaN(page) ? this.currentPage : page,
              per_page: this.perPage || 15,
              order_by: this.orderBy,
              keyword: this.keyword || undefined,
              category: this.category || undefined,
            },
            _.isUndefined
          )
        );
        this.total = data.meta.total;
        this.perPage = data.meta.per_page;
        this.currentPage = data.meta.current_page;
        this.serverLogs = data.data;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    handleSearch() {
      this.currentPage = 1;
      this.fetchServerLogs();
    },
    handleSort(ctx) {
      this.orderBy = ctx.sortDesc ? "desc" : "asc";
      this.currentPage = 1;
      this.fetchServerLogs();
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
  },
};
</script>
