<template>
  <b-form-select
    v-bind="$attrs"
    v-on="$listeners"
  >
    <b-form-select-option
      v-for="eventOption in eventOptions"
      :key="eventOption.value"
      :value="eventOption.value"
    >
      {{ eventOption.text }}
    </b-form-select-option>
  </b-form-select>
</template>

<script>
import { mapState } from 'vuex';
import eventApi from "@/apis/event";

export default {
  data() {
    return {
      eventOptions: [],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.fetchEvents();
  },
  methods: {
    async fetchEvents() {
      const { data } = await eventApi.getEvents(this.organization, { is_all: true });

      this.eventOptions = [
        { value: null, text: "請選擇活動代碼"},
        ...data.data.map((event) => {
          return {
            value: event.id,
            text: `(${event.code}) ${event.name}`
          };
        }),
      ];
    },
  },
}
</script>
