<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{ title }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <div class="col-12 col-xl-8 mb-2 mb-xl-0 d-flex justify-content-between">
                <h4 class="mb-0">{{ title }}</h4>
              </div>
              <div class="col-12 col-xl-8 mt-3">
                <h6>上下班設定</h6>
                <b-form-radio-group
                  v-model="extra.office_duty"
                  :options="officeDutyOptions"
                ></b-form-radio-group>

                <h6 class="mt-3">請假設定</h6>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="啟用請假提醒"
                >
                  <b-form-checkbox v-model="extra.office_leave" :value="officeLeaveCheckValeu"></b-form-checkbox>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="請假起始時間"
                >
                  <div class="row">
                    <div class="col-12 col-xl-4">
                      <datepicker
                        placeholder="Select Date"
                        v-model="office_leave_start.date"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        :disabled="!isLeave"
                      ></datepicker>
                    </div>

                    <div class="col-12 col-xl-4">
                      <vue-timepicker
                        format="HH:mm"
                        v-model="office_leave_start.time"
                        :input-class="['form-control']"
                        :disabled="!isLeave"
                      ></vue-timepicker>
                    </div>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="請假結束時間"
                >
                  <div class="row">
                    <div class="col-12 col-xl-4">
                      <datepicker
                        placeholder="Select Date"
                        v-model="office_leave_end.date"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        :disabled="!isLeave"
                      ></datepicker>
                      <b-form-invalid-feedback
                        :state="!v$.office_leave_start.date.beforeEndAt.$invalid"
                      >
                        結束時間必須大於起始時間
                      </b-form-invalid-feedback>
                    </div>

                    <div class="col-12 col-xl-4">
                      <vue-timepicker
                        format="HH:mm"
                        v-model="office_leave_end.time"
                        :input-class="['form-control']"
                        :disabled="!isLeave"
                      ></vue-timepicker>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
            <div
              class="d-flex justify-content-center"
              style="margin-top: 100px"
            >
              <b-button
                class="mr-3"
                @click="goBackByConfig"
                variant="outline-danger"
              >返回
              </b-button>
              <b-button @click="handleSubmit" variant="success">儲存</b-button>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import { format, isBefore, set, getHours, getMinutes } from "date-fns";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import meApi from "@/apis/me";
import useVuelidate from "@vuelidate/core";
import mixinGoBack from '@/mixins/Dashboard/Me/goBack'

export default {
  components: { Datepicker, VueTimepicker },
  setup: () => ({ v$: useVuelidate() }),
  mixins: [mixinGoBack],

  validations() {
    return {
      office_leave_start: {
        date: {
          beforeEndAt: () => {
            const startDate = this.prepareOfficeLeaveStart();
            const endDate = this.prepareOfficeLeaveEnd();
            return !this.isLeave || isBefore(startDate, endDate);
          },
        },
      },
    };
  },

  data() {
    return {
      title: '個人化設定',
      officeDutyOptions: [
        { text: "已上班", value: 'on_duty' },
        { text: "已下班", value: 'off_duty' },
      ],
      extra: {
        office_duty: null,
        office_leave: null,
        office_leave_start: null,
        office_leave_end: null,
      },
      officeLeaveCheckValeu: '1',
      showLoading: true,
      zh,
      office_leave_start: {
        date: new Date(),
        time: {
          HH: "00",
          mm: "00",
        },
      },
      office_leave_end: {
        date: new Date(),
        time: {
          HH: "23",
          mm: "59",
        },
      },
    };
  },

  computed: {
    isLeave() {
      return this.extra.office_leave === this.officeLeaveCheckValeu;
    },
  },

  mounted() {
    this.fetchProfile();
  },

  methods: {
    async fetchProfile() {
      const response = await meApi.getProfile();
      // 初始時，db 給的 extra 是一個沒有資料的空陣列，所以要多一個判斷
      this.extra = Array.isArray(response.data.data.extra) ? this.extra : response.data.data.extra;

      if (this.extra.office_leave_start) {
        this.office_leave_start = {
          date: new Date(this.extra.office_leave_start),
          time: {
            HH: String(getHours(new Date(this.extra.office_leave_start))).padStart(2, "0"),
            mm: String(getMinutes(new Date(this.extra.office_leave_start))).padStart(2, "0"),
          },
        };
      }
      if (this.extra.office_leave_end) {
        this.office_leave_end = {
          date: new Date(this.extra.office_leave_end),
          time: {
            HH: String(getHours(new Date(this.extra.office_leave_end))).padStart(2, "0"),
            mm: String(getMinutes(new Date(this.extra.office_leave_end))).padStart(2, "0"),
          },
        };
      }
      this.showLoading = false;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      this.showLoading = true
      try {
        this.extra.office_leave_start = format(
          this.prepareOfficeLeaveStart(),
          "yyyy-MM-dd HH:mm:ss"
        );
        this.extra.office_leave_end = format(
          this.prepareOfficeLeaveEnd(),
          "yyyy-MM-dd HH:mm:ss"
        );

        let response = await meApi.updateExtraProfile(this.extra);

        if (response.status && response.status === 200) {
          this.$swal.fire({
            title: "更新成功",
            type: "success",
          });
        }
      } catch (error) {
        this.$swal.fire({
          title: "更新失敗",
          type: "error",
        });
      }
      this.showLoading = false
    },
    prepareOfficeLeaveStart() {
      return set(new Date(this.office_leave_start.date), {
        hours: Number(this.office_leave_start.time.HH),
        minutes: Number(this.office_leave_start.time.mm),
        seconds: 0,
      })
    },
    prepareOfficeLeaveEnd() {
      return set(new Date(this.office_leave_end.date), {
        hours: this.office_leave_end.time.HH,
        minutes: this.office_leave_end.time.mm,
        seconds: 59,
      })
    },
  },
};
</script>
