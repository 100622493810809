<template>
  <Loading />
</template>

<script>
import benefitCardApi from "@/apis/liff/v2/benefit-card";
import Loading from "@/components/Page/Liff/Shared/Loading";
import _ from "lodash";

export default {
  components: {
    Loading,
  },
  async mounted() {
    try {
      const res = await benefitCardApi.init('elc')
      const benefitCardCustomerId = _.get(res, 'data.benefit_card_customer_id')
      if (benefitCardCustomerId) {
        this.$router.push({ name: 'LiffBenefitCardHome', params: { benefitCardCustomerId } })
      } else {
        console.warn('Failed to retrieve benefit card for customer.')
      }
    } catch (e) {
      console.error(e)
      this.$router.push({ name: 'LiffRegisterIndex' })
    }
  },
};
</script>
