<template>
  <SubjectLayout
    :subject="subject"
    :validateError="validateError"
  >
    <SharedSelect
      v-model="selected"
      :options="options"
      :hasBorder="false"
      :placeholder="placeholder"
      :disabled="subject.disabled"
    />
  </SubjectLayout>
</template>

<script>
import collectionApi from "@/apis/liff/v2/collection";
import SubjectLayout from "@/components/DynamicForm/SubjectLayout";
import SharedSelect from "@/components/DynamicForm/Select";
import Collection from "@/models/Collection.model.js";

export default {
  components: {
    SubjectLayout,
    SharedSelect,
  },
  props: {
    subject: {
      type: Object,
      required: true,
    },
    // value: [String, Number, Array, Object],
    value: {
      type: Array,
      default: () => {[]},
    },
    validateError: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      required: false,
    },
    branchId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      options: [],
      collection: null,
    }
  },
  watch: {
    subject: {
      handler: function (val) {
        console.log(val);
        if (val.collection_id) {
          this.fetchCollectionItems();
        }
      },
      immediate: true,
    },
  },
  computed: {
    selected: {
      get() {
        if (Array.isArray(this.value)) {
          return this.value[0];
        }
        return this.value !== null ? [this.value][0] : [];
      },
      set(v) {
        if (v !== null) {
          this.$emit("input", Array.isArray(v) ? v : [v]);
        } else {
          this.$emit("input", null);
        }
      }
    }
  },
  methods: {
    async fetchCollectionItems() {
      // fetch collection items
      try {
        let response;

        if (this.branchId) {
          response = await collectionApi.getOptionsForBranch(this.subject.collection_id, this.branchId);
        } else {
          response = await collectionApi.getCollection(this.subject.collection_id);
        }

        this.collection = response.data.data;

        const collectionModel = new Collection(this.collection)

        this.options = this.collection.items.sort((a, b) => a.order - b.order).map((item) => {
          return {
            value: {
              type: 'collection_select',
              collection_item_id: item.id,
              collection_id: this.collection.id,
              text: item.is_enabled ? collectionModel.textForOption(item.id) : `(已停用)${collectionModel.textForOption(item.id)}`,
            },
            text: item.is_enabled ? collectionModel.textForOption(item.id) : `(已停用)${collectionModel.textForOption(item.id)}`,
          };
        });
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", `讀取 ${this.subject.title} 選項時發生錯誤，請重新整理後再試一次`, "error");
      }
    }
  }
}
</script>
