<template>
  <div class="liff-staff-list">
    <div class="text-center my-2" v-if="fetching">
      <b-spinner class="align-middle spinner-border"></b-spinner>
    </div>
    <div v-if="staffList.length == 0 && !fetching">
      此分店無可選用員工，請回上一頁重新選擇
    </div>
    <h4 class="text-center my-2" v-if="branchInfo">{{ branchInfo.name }}</h4>
    <div class="liff-staff-list__content" :class="{ 'need-bottom': !!chosenStaff.id }">
      <b-card v-for="staff in staffList" :key="staff.id" class="liff-staff-list__card" :ref="staff.id">
        <input type="radio" :id="staff.id" class="liff-staff-list__checkbox" :value="staff" v-model="chosenStaff">
        <label :for="staff.id" class="liff-staff-list__content-item" :class="{ 'active': chosenStaff.id === staff.id }">
          <img class="liff-staff-list__content-item-img" :src="staff.img" alt="">
          <div class="liff-staff-list__content-item-info">
            <div class="s-flex s-flex-col s-gap-2">
              <div>{{ staff.name }}</div>
              <div class="s-text-gray s-text-sm">{{ staff.position }}</div>
              <div v-if="staff.description" class="s-mt-3 s-mb-3 s-border-b s-border-gray-light"></div>
              <div v-if="staff.description" class="s-text-gray s-text-xs">{{ staff.description }}</div>
            </div>
          </div>
          <BIconCheckCircleFill v-if="chosenStaff.id === staff.id" :height="20" class="s-text-primary liff-staff-list__content-item-check" />
        </label>
      </b-card>
    </div>

    <div v-if="!!chosenStaff.id || staffList.length == 0" class="liff-staff-list__confirm">
      <div class="s-flex s-justify-between s-items-center s-mb-3" v-if="staffList.length != 0">
        <div class="s-font-medium">已選：<span class="s-text-primary">{{ chosenStaff.name }}</span></div>
        <div class="s-text-primary cursor-pointer s-font-medium s-flex-shrink-0" @click="chosenStaff = {}">重選</div>
      </div>
      <div class="s-flex s-justify-between s-items-center" style="gap: 8px;">
        <button
          class="s-btn s-liff-theme-btn-outline s-btn-sm"
          type="button"
          @click="prevStep"
        >
        上一步
        </button>
        <button
          v-if="staffList.length != 0"
          class="s-btn s-liff-theme-btn s-btn-sm"
          type="button"
          @click="nextStep">
        下一步
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import { BIconCheckCircleFill } from 'bootstrap-vue';
  // import bookingPresetApi from '@/apis/liff/v2/booking-preset';
  import staffApi from "@/apis/liff/v2/staff";
  import reservationMixin from "@/mixins/liff/reservation";

  export default {
    mixins: [reservationMixin],
    components: {
      BIconCheckCircleFill,
    },
    data() {
      return {
        chosenStaff: {},
        staffList: [],
        fetching: false,
        branchInfo: {}
      }
    },
    computed: {
      branchId() {
        return this.$route.query.branch_id
      },
      staffId() {
        return this.$route.query.staff_id
      }
    },
    mounted() {
      this.init();
    },
    methods: {
      async init() {
        this.fetching = true
        if (this.branchId) {
          this.branchInfo = await this.get('branch', this.branchId)
        }
        await this.fetchStaff();
        if (this.staffId) {
          this.chosenStaff = this.staffList.find((s) => s.id == this.staffId)
        }
        this.fetching = false
      },
      async fetchStaff() {
        await staffApi.getStaffs(this.branchId, { filter_by_module:true }).then((res) => {
          this.staffList = res.staffs.sort((a, b) => a.order - b.order).map((staff) => {
            return {
              id: staff.id,
              name: staff.name,
              position: staff.service_unit,
              img: staff.avatar_url,
              description: staff.description
            }
          });
        });
      },
      prevStep() {
        this.prevStepByList("LiffReservationStaff", {
          branch_id: this.branchId
        })
      },
      nextStep() {
        this.set('staff', {
          id: this.chosenStaff.id,
          name: this.chosenStaff.name
        })
        this.nextStepByList("LiffReservationStaff", {
          branch_id: this.branchId,
          staff_id: this.chosenStaff.id
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.liff-staff-list {

  &__checkbox {
    display: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px 16px 16px;
    &.need-bottom {
      margin-bottom: 150px;
    }

    &-item {
      display: flex;
      align-items: center;
      border: 1px solid #E5E5EA;
      border-radius: 10px;
      padding: 12px;
      color: #2C2C2E;
      font-size: 18px;
      font-weight: 500;
      gap: 12px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #F5F5F5;
      }
      &.active {
        border: 2px solid var(--s-primary);
      }
      &-img {
        width: 80px;
        height: 80px;
        border-radius: 50px;
        flex-shrink: 0;
      }
      &-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 12px;
      }
      &-check {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 32px;
        height: 32px;
        padding: 4px;
      }
    }
  }

  &__card {
		border-radius: 10px;
		border: none;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .card-body {
      padding: 0;
    }
	}

  &__confirm {
    position: fixed;
    max-width: 768px;
    margin: auto;
    background-color: #fff;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    padding: 12px;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    font-size: 13px;
  }
}

.spinner-border {
  border-width: 4px;
}

</style>
