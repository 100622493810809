<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              v-for="breadcrumb in breadcrumbs"
              :to="breadcrumb.to"
              :key="breadcrumb.name"
            >{{ breadcrumb.name }}</b-breadcrumb-item>

            <b-breadcrumb-item active>變更密碼</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-overlay :show="showLoading">
      <b-card>
        <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
          <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
            變更密碼
          </h4>
        </div>
        <b-card-body>
          <div class="row">
            <div class="col-12">
              <validation-error-alert
                v-if="validationErrors"
                :errors="validationErrors"
              ></validation-error-alert>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-xl-8 mt-3">
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="姓名"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  v-model="staff.name"
                  disabled
                ></b-form-input
              ></b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="員工編號"
                label-for="input-1"
              >
                <b-form-input
                  id="input-1"
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  v-model="staff.employee_code"
                  disabled
                ></b-form-input
              ></b-form-group>
              <b-form-group
                v-if="!!this.$route.query.profile"
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="目前密碼"
              >
                <b-form-input
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="password"
                  v-model.trim="form.oldPassword"
                  :state="v$.form.oldPassword.$error ? false : null"
                  placeholder="請填入目前密碼"
                ></b-form-input>
                <b-form-invalid-feedback :state="!v$.form.oldPassword.$error">
                  此欄位為必填
                </b-form-invalid-feedback></b-form-group
              >
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="密碼"
              >
                <b-form-input
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="password"
                  v-model.trim="form.password"
                  :state="v$.form.password.$error ? false : null"
                  placeholder="密碼不能小於 6 個字元"
                ></b-form-input>
                <b-form-invalid-feedback :state="!v$.form.password.$error">
                  此欄位為必填
                </b-form-invalid-feedback></b-form-group
              >
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="確認密碼"
              >
                <b-form-input
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="password"
                  :state="v$.form.passwordConfirmation.$error ? false : null"
                  v-model.trim="form.passwordConfirmation"
                  placeholder="密碼不能小於 6 個字元"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="!v$.form.passwordConfirmation.$error"
                >
                  需與密碼相同
                </b-form-invalid-feedback></b-form-group
              >
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="col-12">
                  <div class="float-right">
                    <b-button
                      class="mr-3"
                      @click="goBack"
                      variant="outline-danger"
                      >返回</b-button
                    >
                    <b-button @click="submit" variant="success">更新</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import staffApi from "@/apis/staff";
import superuserStaffApi from "@/apis/superuser/staff";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {mapState, mapGetters} from 'vuex';
import mixinGoBack from '@/mixins/Dashboard/Me/goBack'

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [mixinGoBack],
  validations() {
    let form = {
      password: { required },
      passwordConfirmation: {
        sameAsPassword: (v) => v === this.form.password,
      },
    }

    if (this.$route.query?.profile) {
      form.oldPassword = { required };
    }

    return { form };
  },
  computed: {
    ...mapState('auth', {
      user: state => state.user,
    }),
    ...mapGetters("general", ["role", "organization"]),
    ...mapGetters("route", ["routeQuery"]),
    fromProfilePage() {
      return this.$route.name == 'StaffChangePassword' && !!this.$route.query.profile;
    },
    fromStaffPage() {
      return this.$route.name == 'StaffChangePassword' && !this.$route.query.profile;
    },
    fromBranchPage() {
      return this.$route.name == 'BranchStaffChangePassword';
    },
    fromOrgPage() {
      return this.$route.name == 'OrganizationsStaffChangePassword';
    },
    breadcrumbs() {
      switch (true) {
        case this.fromStaffPage:
          return [
            { to: { name: 'StaffList' }, name: '帳號管理' },
            { to: { name: 'StaffList' }, name: '帳號列表' },
          ];
        case this.fromBranchPage:
          return [
            { to: { name: 'Branches' }, name: '分店管理' },
            { to: { name: 'BranchStaffs' }, name: '店內帳號' },
          ];
        case this.fromOrgPage:
          return [
            { to: { name: 'SystemSettingsHome' }, name: '全系統管理' },
            { to: { name: 'OrganizationList' }, name: '組織管理' },
            { to: { name: 'OrgRoleList', params: { orgId: this.orgId } }, name: '角色列表' },
            {
              to: { name: 'OrgRoleStaffList', params: { orgId: this.$route.params.orgId, role_id: this.$route.params.role_id } },
              name: '角色相關帳號'
            },
          ];
        default:
          return [];
      }
    },
    errorMessages() {
      if (! this.validationErrors) {
        return null;
      }

      return Object.values(this.validationErrors).flat().join("\n");
    },
  },
  data() {
    return {
      staff: {
        name: null,
        employee_code: null,
      },
      showLoading: true,
      password: null,
      passwordConfirmation: null,
      validationErrors: null,
      form: {
        password: null,
        passwordConfirmation: null,
      },
    };
  },
  mounted() {
    this.fetchStaff();
  },
  methods: {
    async fetchStaff() {
      if (this.fromOrgPage) {
        await this.fetchStaffBySuperuser();
      } else {
        await this.fetchStaffByNormal();
      }
      this.showLoading = false;
    },
    async fetchStaffBySuperuser() {
      const staff_id = this.$route.params.staff_id;

      let response = await superuserStaffApi.getOneStaff(staff_id);
      if (response.status === 200 && response.data.data) {
        this.staff = response.data.data;
      } else {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "取得帳號資料失敗",
        });
      }
    },
    async fetchStaffByNormal() {
      await this.verifyStaff();

      const staff_id = this.$route.params.staff_id;

      let response = await staffApi.getOneStaff(staff_id);
      if (response.status === 200 && response.data.data) {
        this.staff = response.data.data;
      } else {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "取得帳號資料失敗",
        });
      }
    },
    async submit() {
      const result = await this.v$.$validate();
      if (!result) return;

      this.showLoading = true;
      try {
        let response;
        if (this.fromOrgPage) {
          response = await this.submitBySuperuser();
        } else {
          response = await this.submitByNormal();
        }

        if (response.status && response.status === 200) {
          this.v$.$reset();
          this.$swal.fire({
            title: "密碼變更完成",
          }).then(() => {
            this.goBack()
          });
        }
      } catch (error) {
        console.error(error);
        if (
          error.response.status &&
          error.response.status === 422 &&
          error.response.data.message
        ) {
          this.validationErrors = error.response.data.message;
        }
        this.$swal.fire({
          title: "變更密碼失敗\n",
          text: this.errorMessages,
          type: "error",
        });
      }
      this.showLoading = false;
    },
    async submitBySuperuser() {
      const params = {
        password: this.form.password,
        password_confirmation: this.form.passwordConfirmation,
      };

      return await superuserStaffApi.changePassword(
        this.$route.params.staff_id,
        params
      );
    },
    async submitByNormal() {
      const params = {
        org_id: this.organization.id,
        old_password: this.form.oldPassword,
        password: this.form.password,
        password_confirmation: this.form.passwordConfirmation,
        from_profile: this.fromProfilePage,
      };

      return await staffApi.changePassword(
        this.$route.params.staff_id,
        params
      );
    },
    goBack() {
      if (this.fromProfilePage) {
        this.goBackByConfig()
      } else if (this.fromBranchPage) {
        this.$router.push({name: "BranchStaffs"});
      } else if (this.fromOrgPage) {
        this.$router.push({ name: 'OrgRoleStaffList', params: { orgId: this.$route.params.orgId, role_id: this.$route.params.role_id } });
      } else {
        this.$router.push({ name: "StaffList", query: this.routeQuery });
      }
    },
    async verifyStaff() {
      const staff_id = this.$route.params.staff_id;
      if (
        // 如果是本人，必須確認是否有 MY_CHANGE_PASSWORD
        (this.user.id == staff_id && ! this.$permissions.has(this.$permissions.consts.MY_CHANGE_PASSWORD)) ||
        // 如果非本人，則必須要有 STAFF_CHANGE_PASSWORD
        (this.user.id !== staff_id && ! this.$permissions.has(this.$permissions.consts.STAFF_CHANGE_PASSWORD))
      ) {
        await this.$swal.fire({
          title: '錯誤',
          type: 'error',
          text: '您沒有訪問此功能的權限，請確認身份別'
        });

        this.$router.push({ name: "DashboardHome" });
      }
    },
  },
};
</script>

<style>
.table-responsive {
  min-height: 300px;
}
</style>
