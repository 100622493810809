<template>
  <div class="dynamic-form-read-only">
    <section class="dynamic-form-read-only__subjects">
      <b-card v-for="(subject, index) in inputSubjects" class="mt-3" :key="index">
        <div class="subject">
          <div class="subject__index d-flex align-items-center">
            <span class="subject__sequence">第 {{ index + 1 }} 題</span>
            <i :class="subjectTypeIcon(subject.type)" class="mdi mdi-18px ml-3"></i>
          </div>

          <div class="subject__title">
            <b-form-input :value="subjectValue('title', subject)" :disabled="true" />
          </div>

          <div class="subject__id" tabindex="0">
            <b-form-input :value="subjectValue('_id', subject)" readonly />
            <i class="subject__copy-icon mdi mdi-18px mdi-content-copy" v-b-tooltip.click.blur="'已複製'" @click="copyAssignUrl(subjectValue('_id', subject))"></i>
          </div>
        </div>
      </b-card>
    </section>

    <div class="mt-3 d-flex justify-content-center">
      <b-button variant="outline-danger" class="mr-3" @click="$emit('cancel')">
        返回
      </b-button>
    </div>
  </div>
</template>

<script>
import { subjectTypeIcon } from '@/utils/DynamicFormUtils';
import deepGet from "lodash/get"

export default {
  props: {
    inputSubjects: {
      type: Array,
      default: () => { return [] },
    },
  },
  methods: {
    deepGet,
    subjectTypeIcon,
    subjectValue(key, subject) {
      return deepGet(subject, key) || deepGet(subject, `config[${key}]`)
    },
    async copyAssignUrl(url) {
      try {
        await navigator.clipboard.writeText(url);
      } catch (error) {
        console.warn('[DynamicFormReadOnly] Failed to copy url.')
        console.error(error.message);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.subject {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px 16px;
  grid-template-areas:
    "index  title"
    ".      id";

  $sections: index, title, id;
  @each $sectionName in $sections {
    &__#{$sectionName} {
      grid-area: $sectionName;
    }
  }

  &__sequence {
    border-radius: 4px;
    padding: 4px 12px;
    background-color: #eee;
    font-size: .875rem;
  }

  &__title {
    > input {
      background-color: transparent;
    }
  }

  &__id {
    position: relative;
    cursor: pointer;

    > input {
      background-color: transparent;
    }
  }

  &__copy-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
