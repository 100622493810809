var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoading}},[_c('div',{staticClass:"overflow-auto w-100"},[_c('div',{staticClass:"page-title-header"},[_c('div',{staticClass:"page-header d-flex col-12"},[_c('b-breadcrumb',{staticClass:"col-11 m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
            name: 'DashboardHome',
          }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',[_vm._v(" "+_vm._s(_vm.isBookingCenter() ? "登記預約中心" : "預約管理")+" ")]),(_vm.bookingPreset.name)?_c('b-breadcrumb-item',{attrs:{"to":_vm.isBookingCenter()
            ? {
              name: 'BookingCenterBookingList',
              params: { id: _vm.bookingPreset.id },
            }
            : { name: 'BookingList', params: { id: _vm.bookingPreset.id } }}},[_vm._v(" "+_vm._s(_vm.bookingPreset.name)+" ")]):_vm._e(),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("估價單")])],1),_c('b-button',{staticClass:"col-1",attrs:{"variant":"inverse-info"},on:{"click":_vm.generatePdf}},[_c('span',{staticClass:"mdi mdi-printer"}),_vm._v(" 列印 ")])],1)]),_c('div',{staticClass:"pdf-container mx-auto",attrs:{"id":"download-pdf"}},[_c('header',{staticClass:"my-4",domProps:{"innerHTML":_vm._s(_vm.getCleanedHtml(_vm.bookingPreset.config.quotation_header))}}),_c('h3',{staticClass:"quote-title"},[_vm._v("估價單")]),_c('p',{staticClass:"customer-info color-blue",domProps:{"innerHTML":_vm._s(_vm.bookingPreset.name)}}),(_vm.isCompletedFormated)?_c('table',{staticClass:"quote-table"},[_c('thead',[_c('tr',_vm._l((_vm.bookingPreset.config
              .quotation_content_header),function(header,index){return _c('th',{key:'header-' + index,style:(header.style),attrs:{"colspan":header.colspan || 1},domProps:{"innerHTML":_vm._s(header.value && header.value.trim()
                ? header.value
                : header.text || ''
                )}})}),0)]),_c('tbody',[_c('tr',_vm._l((_vm.bookingPreset.config
              .quotation_content),function(content,index){return _c('td',{key:'content-' + index,style:([content.style, _vm.tdStyles[index]]),attrs:{"colspan":content.colspan || 1},domProps:{"innerHTML":_vm._s(content.value && content.value.trim()
                ? content.value
                : content.text || ''
                )}})}),0),_c('tr',_vm._l((_vm.bookingPreset.config
              .quotation_content_footer),function(footer,index){return _c('td',{key:'footer-' + index,style:(footer.style),attrs:{"colspan":footer.colspan || 1},domProps:{"innerHTML":_vm._s(footer.value && footer.value.trim()
                ? footer.value
                : footer.text || ''
                )}})}),0)])]):_vm._e(),_c('footer',{domProps:{"innerHTML":_vm._s(_vm.getCleanedHtml(_vm.bookingPreset.config.quotation_footer))}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }