<template>
  <b-overlay :show="showLoading" rounded="sm">
    <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
      <h4 class="col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold">訂單管理</h4>
      <div
        class="
          col-12 col-xl-8
          d-flex
          flex-column flex-xl-row
          align-items-start align-items-xl-center
        "
        v-if="showFilter"
      >
        <b-button
          v-b-modal.modal-upload
          class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
          variant="inverse-info"
          ><span class="mdi mdi-cloud-upload"></span>匯入</b-button
        >
        <b-button
          class="flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2"
          variant="primary"
          :to="{
            name: 'OrderCreate',
            query: { merchant_id: this.query.merchant_id },
          }"
          ><i class="fa fa-plus" aria-hidden="true"></i>新增訂單
        </b-button>
        <b-form-select
          v-model="query.merchant_id"
          :disabled="merchants.length == 2"
          :options="merchants"
          @change="handlePageReset"
        ></b-form-select>
        <b-input-group class="ml-0 ml-xl-4">
          <b-form-input
            placeholder="訂單編號或外部編號"
            v-model="query.keyword"
            v-on:keyup.enter="handlePageReset"
          ></b-form-input>
          <b-input-group-append>
            <b-button @click="handlePageReset"
              ><i class="fa fa-search"></i
            ></b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>
    <b-table
      responsive
      striped
      hover
      :items="orders"
      :fields="filteredFields"
      @sort-changed="handleSort"
    >
      <template #cell(branch)="data">
        {{
          data.item.branch ? data.item.branch.name / data.item.branch.id : ""
        }}
      </template>

      <template #cell(order_number)="data">
        <router-link
          :to="{
            name: 'OrderDetail',
            params: {
              order_id: data.item.id,
            },
          }"
        >
          {{ data.item.order_number }}
        </router-link>
      </template>
      <template #cell(outer_order_number)="data">
        <router-link
          :to="{
            name: 'OrderDetail',
            params: {
              order_id: data.item.id,
            },
          }"
        >
          {{ data.item.outer_order_number }}
        </router-link>
      </template>
      <template #cell(customer_name)="data">
        {{ data.item.customer ? data.item.customer.name : "" }}
      </template>
      <template #cell(action)="data">
        <b-button
          class="ml-2"
          variant="inverse-primary"
          v-if="checkPermission([consts.ORDER_LIST_VIEW])"
          :to="{
            name: 'OrderDetail',
            params: {
              order_id: data.item.id,
            },
          }"
          ><span class="mdi mdi-eye mr-1"></span>查看
        </b-button>
        <b-button
          class="ml-2"
          variant="inverse-warning"
          v-if="checkPermission([consts.ORDER_MODIFY])"
          :to="{
            name: 'OrderEdit',
            params: {
              order_id: data.item.id,
            },
          }"
          ><span class="mdi mdi-lead-pencil mr-1"></span>編輯
        </b-button>
        <b-button
          class="ml-2"
          variant="inverse-danger"
          @click="handleDelete(data.item.id)"
          v-if="checkPermission([consts.ORDER_DELETE])"
          ><span class="mdi mdi-delete mr-1"></span>刪除
        </b-button>
      </template>
    </b-table>
    <CustomPagination
      :currentPage="query.page"
      :totalRows="totalRows"
      :perPage="query.per_page"
      @page-change="handlePageChange"
      @per-page-change="handlePerPageChange"
    />
    <b-modal id="modal-upload" title="批次上傳">
      <b-overlay :show="showLoadingUpload">
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0">請上傳 Excel 檔案</label>
          <b-form-file plain @input="uploadOrder" placeholder="尚未選擇檔案" browse-text="瀏覽"></b-form-file>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="
            getSampleExcelFile(
              getModuleConfig('order', 'sample_file.order_import_xls'),
              '/excel/order_list.xlsx'
            )
          "
          ><i class="fa fa-file-text"></i>下載範例檔案</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
      </template>
    </b-modal>
  </b-overlay>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { format } from "date-fns";
import orderApi from "@/apis/order";
import merchantApi from "@/apis/merchant";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import { getSampleExcelFile } from "@/utils/dashboardUtils";

export default {
  components: { CustomPagination },
  props: {
    showFilter: {
      type: Boolean,
      default: true,
    },
    excludeFields: {
      type: Array,
      default: () => [],
    },
    upperCustomerId: {
      type: [Number, String],
      default: null,
    },
    upperMerchantId: {
      type: [String],
      default: null,
    },
  },
  data() {
    return {
      consts,
      showLoading: false,
      merchants: [{ value: null, text: "請選擇" }],
      totalRows: 0,
      showLoadingUpload: false,
      fields: [
        {
          key: "order_number",
          label: "訂單編號",
          sortable: true,
        },
        {
          key: "outer_order_number",
          label: "外部編號",
          sortable: true,
        },
        {
          key: "customer_name",
          label: "顧客姓名",
        },
        {
          key: "total",
          label: "總價(含稅)",
        },
        {
          key: "memo",
          label: "消費備註",
        },
        {
          key: "order_at",
          label: "下單時間",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return format(new Date(value), "yyyy-MM-dd HH:mm");
            }
            return "";
          },
        },
        {
          key: "status_at",
          label: "最新狀態時間",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return format(new Date(value), "yyyy-MM-dd HH:mm");
            }
            return "";
          },
        },
        {
          key: "action",
          label: "管理",
        },
      ],
      orders: [],
      permissionChecker: new PermissionChecker(),
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        keyword: "",
        sort_by: "order_at",
        order_by: "desc",
        merchant_id: null,
      },
    };
  },
  computed: {
    filteredFields() {
      return this.fields.filter(
        (field) => !this.excludeFields.includes(field.key)
      );
    },
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    ...mapState("general", {
      defaultMerchantType: (state) => state.defaultMerchantType,
    }),
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    "$route.query": {
      handler(newQuery) {
        if (!this.initialized) return;
        if (Object.keys(newQuery).length !== 0) {
          this.query = { ...newQuery };
        }
        this.fetchOrders();
      },
      deep: true,
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  mounted() {
    this.fetchMerchants();
    this.initialized = true;
  },
  methods: {
    getSampleExcelFile,
    async uploadOrder(file) {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", file);
      formData.append("merchant_id", this.query.merchant_id);

      try {
        let response = await orderApi.importOrder(formData);

        if (response.status === 200 && response.data.status === "success") {
          this.$swal.fire({
            title: "上傳成功",
            type: "success",
          });

          this.query.page = 1;
          await this.fetchOrders();
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 422 && e.response.data.message) {
          this.$swal.fire({
            title: "檔案內容有誤",
            type: "error",
            text: e.response.data.message ?? null,
          });
        } else {
          this.$swal.fire({
            title: "上傳失敗",
            type: "error",
          });
        }
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-upload");
      }
    },
    async fetchOrders() {
      if (!this.query.merchant_id) return;

      this.showLoading = true;

      try {
        const params = { ...this.query };
        if (this.upperCustomerId) params.customer_id = this.upperCustomerId;

        const { data } = await orderApi.getOrders(params);

        this.orders = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        alert("取得訂單資料錯誤");
      }
      this.showLoading = false;
    },
    async fetchMerchants() {
      const { data } = await merchantApi.list();

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: `${merchant.name} (${merchant.type_name})`,
          };
        }),
      ];

      if (!this.query.merchant_id) {
        const merchants = data.data;
        let defaultMerchant =
          merchants.find(
            (merchant) => merchant.type === this.defaultMerchantType
          ) ?? merchants.find((merchant) => merchant.type === consts.MERCHANT_TYPES.LINE_TYPE);

        if (defaultMerchant) {
          this.query.merchant_id = defaultMerchant.id;
        }
      }

      if (this.upperMerchantId) {
        this.query.merchant_id = this.upperMerchantId;
      }

      if (this.query.merchant_id) {
        const hasMerchant = this.merchants.find(
          (item) => item.value === this.query.merchant_id
        );
        if (!hasMerchant) {
          this.$swal.fire({
            icon: "warning",
            title: "查無此通路",
            confirmButtonText: "確定",
            confirmButtonColor: "#d33",
            reverseButtons: true,
          });
          return;
        }
        await this.fetchOrders();
      }
    },
    async handleDelete(id) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              const response = await orderApi.deleteOrder(id);
              if (response.status === 200) {
                await this.$swal.fire({
                  title: "成功",
                  type: "success",
                  text: "刪除成功",
                });
                await this.fetchOrders();
              }
            } catch (error) {
              this.$swal("失敗", error.response.data.message, "error");
            }
          }
        });
    },
    checkPermission(permissions) {
      return this.permissionChecker.check(permissions);
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
    },
    handlePageReset() {
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5;
}
</style>
