export default {
  data() {
    return {
      moduleMap: [
        { name: "訂單紀錄", type: "order_list", value: "LiffOrderListIndex" },
        //{ name: "贈品紀錄", type: "gift_list", value: "LiffPointsExchange" },
        //{ name: "領優惠券", type: "coupon_apply", value: "LiffCouponListHome" },

        { name: "點數中心", type: "point_center", value: "LiffPointsCenter" },
        { name: "點數專區", type: "point_list", value: "LiffPoint" },
        { name: "點數紀錄", type: "point_records", value: "LiffPointsRechargeRecords" },
        { name: "點數兌換", type: "point_exchange", value: "LiffPointsExchange" },
        { name: "兌換紀錄", type: "point_exchange_records", value: "LiffPointsExchangeRecords" },
        { name: "點數移轉", type: "point_transfer", value: "LiffPointsTransfer" },
        { name: "點數紀錄", type: "point_tab_records", value: "LiffPointsRechargeRecordsTab" },
        { name: "點數兌換", type: "point_tab_exchange", value: "LiffPointsExchangeTab" },
        { name: "兌換紀錄", type: "point_tab_exchange_records", value: "LiffPointsExchangeRecordsTab" },
        { name: "點數移轉", type: "point_tab_transfer", value: "LiffPointsTransferTab" },

        { name: "團購紀錄", type: "groupbuying_list", value: "LiffCoreGroupBuyingList" },
        {
          name: "團購訂單",
          type: "groupbuying_order_list",
          value: "LiffCoreGroupBuyingOrderProcessingList",
        },

        { name: "編輯個人資料", type: "member_edit", value: "LiffMemberCenterEdit" },


        { name: "會員中心", type: "member_center", value: "LiffMemberCenterHome" },



        { name: "訊息中心", type: "chatroom_list", value: "LiffChatroomPortal" },
        { name: "發票模組", type: "invoice_list", value: "EinvoiceList" },
        { name: "集點卡", type: "rewards_card", value: "LiffRewardsCardHome" },
        { name: "購物金", type: "credit_list", value: "LiffVoucher" },
        { name: "預約紀錄", type: "booking_list", value: "LiffBooking" },


        { name: "優惠券匣", type: "coupon_list", value: "LiffCouponList" },
        { name: "我的優惠券", type: "coupon_my", value: "LiffCouponListMy" },
        { name: "優惠券紀錄", type: "coupon_records", value: "LiffCouponListRecords" },
        { name: "領取優惠券", type: "coupon_apply", value: "LiffCouponListHome" },
        { name: "我的優惠券", type: "coupon_tab_my", value: "LiffCouponMyTab" },
        { name: "優惠券紀錄", type: "coupon_tab_records", value: "LiffCouponRecordsTab" },
        { name: "領取優惠券", type: "coupon_tab_apply", value: "LiffCouponHomeTab" },


        { name: "最新消息", type: "new_list", value: "LiffNews" },


        //MemberButton
        { type: "member-center", value: "LiffMemberCenterHome" },
        { type: "member-edit", value: "LiffMemberCenterEdit" },
        { type: "edit_done", value: "LiffMemberCenterEditDone" },
        { type: "welcome", value: "LiffRegisterWelcome" },
        { type: "thanks", value: "LiffRegisterThanks" },
        { type: "intro", value: "LiffRegisterIntro" },
        { type: "register", value: "LiffRegisterIndex" },
        { type: "staff.list", value: "LiffStaffList" },
        { type: "staff.bind_success", value: "LiffStaffBindSuccess" },
        { type: "proj.abbott_and.branch_picker", value: "LiffProjAbbottAndBranchPicker" },
        { type: "elc_benefit_card", value: "LiffBenefitCardElc" },

      ],
    };
  },

};
