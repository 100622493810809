<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item @click="goToList">系統通知</b-breadcrumb-item>
            <b-breadcrumb-item active>{{ pageTitle }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-overlay :show="isLoading">
      <div class="card">
        <div class="card-body">
          <h4 class="mb-3 font-weight-bold">{{ pageTitle }}</h4>

          <validation-error-alert
            v-if="validationErrors"
            :errors="validationErrors"
          ></validation-error-alert>

          <div class="form-group form-row">
            <label class="col-sm-2 col-form-label">通知名稱：</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                v-model="notification.title"
                required
                :readonly="isReadOnly"
              />
            </div>
          </div>

          <div class="form-group form-row align-items-center">
            <label class="col-sm-2 col-form-label">分店：</label>
            <div class="col-sm-10">
              <div v-if="isReadOnly">
                <BranchTable :branches="notification.branches"></BranchTable>
              </div>
              <b-form-group v-else-if="isEditing">
                <b-form-radio-group v-model="chooseBranch">
                  <b-form-radio :value="false" class="w-100">
                    <div class="d-flex">
                      <div class="mr-4 flex-shrink-0">維持不變</div>
                      <BranchTable
                        class="flex-shrink-0 flex-grow"
                        :branches="notification.branches"
                      ></BranchTable>
                    </div>
                  </b-form-radio>

                  <b-form-radio :value="true">重新選取</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group v-if="!isReadOnly && chooseBranch">
                <b-form-radio-group v-model="branchGroup">
                  <b-form-radio
                    :value="BRANCH_GROUP.ALL"
                    v-if="
                      $permissions.has(
                        $permissions.consts.NOTIFICATION_MODIFY_CHANGE_BRANCH
                      )
                    "
                    >全部分店</b-form-radio
                  >
                  <b-form-radio :value="BRANCH_GROUP.ONE">
                    <b-form-select
                      v-model="oneBranch"
                      size="sm"
                      :disabled="branchGroup !== BRANCH_GROUP.ONE"
                    >
                      <b-form-select-option :value="null" disabled
                        >選擇分店</b-form-select-option
                      >
                      <b-form-select-option
                        v-for="branch in branchOptions"
                        :value="branch.value"
                        :key="branch.value"
                        >{{ branch.text }}</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-radio>
                  <b-form-radio
                    :value="BRANCH_GROUP.EXCEL"
                    v-if="
                      $permissions.has(
                        $permissions.consts.NOTIFICATION_MODIFY_CHANGE_BRANCH
                      )
                    "
                  >
                    <div class="form-group d-inline-flex align-items-center">
                      <label class="flex-shrink-0 mr-2 mb-0">上傳分店</label>
                      <b-form-file plain @input="uploadBranches" placeholder="尚未選擇檔案" browse-text="瀏覽"></b-form-file>
                    </div>
                  </b-form-radio>
                  <b-button
                    v-if="
                      $permissions.has(
                        $permissions.consts.NOTIFICATION_MODIFY_CHANGE_BRANCH
                      )
                    "
                    size="sm"
                    variant="outline-primary"
                    href="/excel/branch_list.xlsx"
                    ><i class="fa fa-file-text"></i>下載範例檔案</b-button
                  >
                </b-form-radio-group>
              </b-form-group>
            </div>
          </div>

          <div class="form-group form-row">
            <label class="col-sm-2 col-form-label">發送時間：</label>
            <div class="col-sm-10">
              <div class="row">
                <div class="col-sm-6">
                  <datepicker
                    placeholder="日期"
                    v-model="notification.date"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :disabled="isReadOnly"
                    :disabledDates="disabledDates"
                  ></datepicker>
                </div>
                <div class="col-sm-6 timepicker">
                  <vue-timepicker
                    input-class="form-control"
                    v-model="notification.time"
                    hide-clear-button
                    :disabled="isReadOnly"
                    :minute-interval="10"
                  ></vue-timepicker>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>通知內容</label>
            <TrixEditor v-model="notification.content" :readonly="isReadOnly" />
          </div>

          <div class="form-group form-row">
            <label class="col-sm-2 col-form-label">通知URL：</label>
            <div class="col-sm-10">
              <input
                type="text"
                class="form-control"
                v-model="notification.related_url"
                :readonly="isReadOnly"
              />
            </div>
          </div>

          <b-form-checkbox
            v-model="notification.notify_type"
            value="important"
            unchecked-value="info"
            :disabled="isReadOnly"
          >
            標註為重要通知
          </b-form-checkbox>

          <div class="float-right">
            <button class="btn btn-outline-danger" @click="goToList">
              返回
            </button>

            <b-button v-if="!isReadOnly" @click="submit" variant="success">
              <span v-if="isEditing">確認儲存</span>
              <span v-else>確認新增</span>
            </b-button>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import format from "date-fns/format";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import VueTimepicker from "vue2-timepicker";
import { mapGetters } from "vuex";
import subDays from "date-fns/subDays";

import notificationApi from "@/apis/notification";

import TrixEditor from "@/components/TrixEditor";
import BranchTable from "@/components/BranchesTable";

const BRANCH_GROUP = {
  ALL: "all",
  ONE: "one",
  EXCEL: "excel",
};

export default {
  components: { Datepicker, VueTimepicker, TrixEditor, BranchTable },
  data() {
    let date = new Date();
    const minute = date.getMinutes();
    const diffMinute = 10 - (minute % 10);
    date.setMinutes(minute + diffMinute);

    return {
      BRANCH_GROUP,
      zh,
      showBranches: false,
      chooseBranch: this.$route.name === "NotificationCreate",
      isLoading: false,
      validationErrors: null,
      branchGroup: this.$permissions.has(
        this.$permissions.consts.NOTIFICATION_MODIFY_CHANGE_BRANCH
      )
        ? "all"
        : "one",
      oneBranch: this.$store.getters["general/branchOptions"][0].value,
      excelBranches: [],
      disabledDates: { to: subDays(new Date(), 1) },
      notification: {
        title: "",
        content: "",
        notify_type: "info",
        publish_at: "",
        related_url: "",
        branch_ids: "",
        date: date,
        time: {
          HH: date.getHours().toString().padStart(2, "0"),
          mm: date.getMinutes().toString().padStart(2, "0"),
        },
        branches: [],
      },
      form: {
        is_all: false,
        branch_ids: [],
      },
    };
  },
  computed: {
    ...mapGetters("general", ["organization", "branchOptions"]),
    ...mapGetters("route", ["routeQuery"]),
    isReadOnly() {
      return this.$route.name === "NotificationView";
    },
    isEditing() {
      return this.$route.name === "NotificationForm";
    },
    pageTitle() {
      const titles = {
        NotificationCreate: "新增通知",
        NotificationForm: "編輯通知",
        NotificationView: "檢視通知",
      };
      return titles[this.$route.name] || "檢視通知";
    },
  },
  async created() {
    if (!this.$route.params.notification_id) {
      // this.clearNotification();
    } else {
      this.fetchNotification();
    }
  },
  methods: {
    handleSetBranchValue() {
      this.form.is_all = false;

      if (this.branchGroup === BRANCH_GROUP.ALL) {
        delete this.form.branch_ids;
        this.form.is_all = true;
      }
      if (this.branchGroup === BRANCH_GROUP.ONE) {
        this.form.branch_ids = [this.oneBranch];
      }
      if (this.branchGroup === BRANCH_GROUP.EXCEL) {
        this.form.branch_ids = this.excelBranches;
      }
    },

    async fetchNotification() {
      this.isLoading = true;
      const response = await notificationApi.getOneNotification(
        this.$route.params.notification_id
      );

      const data = response.data.data;

      data.date = new Date(data.publish_at);
      data.time = {
        HH: data.date.getHours().toString().padStart(2, "0"),
        mm: data.date.getMinutes().toString().padStart(2, "0"),
      };
      this.notification = data;
      this.isLoading = false;
    },
    clearNotification() {
      this.notification = {};
    },
    async uploadBranches(file) {
      let formData = new FormData();
      formData.append("file", file);

      try {
        const {
          data: { data },
        } = await notificationApi.uploadExcelNotification(
          this.organization.id,
          formData
        );

        this.excelBranches = data.map((b) => b.id);
      } catch (e) {
        console.error(e);
      }
    },
    async submit() {
      if (!this.notification.title || !this.notification.content) {
        alert("請填滿表格");
        return;
      }

      await this.handleSetBranchValue();

      const newDate = new Date(this.notification.date);
      newDate.setHours(this.notification.time.HH);
      newDate.setMinutes(this.notification.time.mm);

      const payload = {
        title: this.notification.title,
        content: this.notification.content,
        notify_type: this.notification.notify_type,
        publish_at: format(newDate, "yyyy-MM-dd HH:mm"),
        related_url: this.notification.related_url,
        ...this.form,
      };
      try {
        this.isLoading = true;
        if (this.isEditing) {
          payload.id = this.notification.id;
          await notificationApi.updateNotification(
            this.organization.id,
            payload
          );

          this.$swal("修改成功", "", "success");
        } else {
          await notificationApi.createNotification(
            this.organization.id,
            payload
          );

          this.$swal("新增成功", "", "success");
        }
        this.goToList();
      } catch (error) {
        console.error(error);
        if (
          error.response &&
          error.response.status === 422 &&
          error.response.data.message
        ) {
          this.validationErrors = error.response.data.message;
        }
      }
      this.isLoading = false;
    },
    goToList() {
      this.$router.push({ name: "NotificationList", query: this.routeQuery });
    },
  },
};
</script>

<style lang="scss" scoped>
.text-xs {
  font-size: 12px;
}
.cursor-pointer {
  cursor: pointer;
}

::v-deep input:disabled {
  background-color: #e9ecef !important;
  color: #212529 !important;
}

::v-deep .timepicker input {
  border-color: rgb(205, 214, 220) !important;
}

::v-deep .custom-control-label {
  width: 100%;
}
</style>
