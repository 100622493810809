import https from "./https"
import store from "@/store"

const exporting = {
  request(handler, params) {
    const organization = store.state.general.organization;
    return https.get(`/admin/organizations/${organization.id}/export/${handler}`, { params });
  },
}

export default exporting
