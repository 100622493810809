<template>
  <div v-if="styleType === 'unlimited'">
    <vue-timepicker
      :placeholder="placeholder"
      format="HH:mm"
      v-model="time"
      :input-class="[ 'form-control' ]"
      class="app-time-picker"
      :class="{ 'app-time-picker--is-invalid': hasError }"
      @input="handleInput"
    ></vue-timepicker>
  </div>
  <div v-else>
    <b-form-select
      v-model="time"
      @input="handleInput"
    >
      <b-form-select-option :value="null">{{ placeholder }}</b-form-select-option>
      <b-form-select-option
        v-for="timeOption in timeOptions"
        :key="timeOption"
        :value="timeOption"
      >
        {{ timeOption }}
      </b-form-select-option>
    </b-form-select>
  </div>
</template>

<script>
import parseHourAndMinute from "@/utils/parseHourAndMinute";
import deepGet from "lodash/get";
import VueTimepicker from "vue2-timepicker";

export default {
  props: {
    value: [Date, String],
    placeholder: {
      type: String,
      default: ' ',
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    styleType: {
      type: String,
      default: 'unlimited',
    },
    startTime: {
      type: String,
      default: '00:00',
    },
    endTime: {
      type: String,
      default: '21:00',
    },
    slotMinutes: {
      type: [String, Number],
      default: "30",
    },
  },
  components: {
    VueTimepicker,
  },
  data: () => ({
    time: {
      HH: null,
      mm: null,
    },
  }),
  watch: {
    value(val) {
      if (this.styleType == 'limited') {
        try {
          const [hour, minute] = parseHourAndMinute(val)
          this.time = `${hour}:${minute}`
        } catch (e) {
          console.warn('[AppTimePicker] Received invalid time format:', val)
        }
        return
      }
      this.formatValueFromInput(val)
    },
  },
  mounted() {
    if (this.styleType == 'limited') {
      this.time = this.value
      return
    }
    this.formatValueFromInput(this.value)
  },
  computed: {
    timeOptions() {
      if (!this.startTime || !this.endTime) {
        return [];
      }
      let options = [];
      const slotMinutes = Number(this.slotMinutes);
      const startTime = this.startTime;
      const endTime = this.endTime;
      const lastServeTime = this.endTime;
      const durationTime = 0;
      let currentTime = startTime;

      // 計算實際的最後服務時間
      let actualEndTime = endTime;

      // 計算 lastServeTime 減去 durationTime
      const [lastServeHours, lastServeMinutes] = lastServeTime.split(':').map(Number);
      const lastServeDate = new Date(0, 0, 0, lastServeHours, lastServeMinutes);
      lastServeDate.setMinutes(lastServeDate.getMinutes() - durationTime);

      // 計算結果時間並比較
      const lastServeEndTime = `${lastServeDate.getHours().toString().padStart(2, '0')}:${lastServeDate.getMinutes().toString().padStart(2, '0')}`;

      // 如果 lastServeEndTime 比 endTime 還早，就將 actualEndTime 設為 lastServeEndTime
      if (lastServeEndTime < endTime) {
        actualEndTime = lastServeEndTime;
      }

      /* eslint-disable no-constant-condition */
      while (true) {
        options.push(currentTime);

        // 將 currentTime 轉為 Date 物件，便於時間加法
        const [hours, minutes] = currentTime.split(':').map(Number);
        let date = new Date(0, 0, 0, hours, minutes + Number(slotMinutes));
        // 將新的時間轉回字符串格式 "HH:MM"
        currentTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

        // 停止條件：若不包含 endTime，且當前時間達到 endTime 或超過
        if (currentTime > actualEndTime) {
          break;
        }
      }
      /* eslint-enable no-constant-condition */
      return options;
    }
  },
  methods: {
    deepGet,
    formatValueFromInput(value) {
      if ([null, undefined].includes(value)) {
        // catched but do nothing, can be altered to provide default value
      } else {
        try {
          const [hour, minute] = parseHourAndMinute(value)
          this.time = { HH: hour, mm: minute }
        } catch (e) {
          console.warn('[AppTimePicker] Received invalid time format:', value)
        }
      }
    },
    handleInput(value) {
      // options
      if (this.styleType == 'limited') {
        this.$emit('input', value)
        return
      }
      // picker
      if (value.HH && value.mm) {
        this.$emit('input', `${value.HH}:${value.mm}`)
      }

      if (!value.HH && !value.mm) {
        this.$emit('input', null)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/scss/base-colors.scss";

::v-deep.app-time-picker {
  .form-control {
    border-radius: 5px;
    font-size: inherit;
  }

  &--is-invalid {
    .form-control {
      border: 1px solid map-get(base-colors.$theme-colors, danger);
    }
  }
}
</style>
