<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              v-for="item in breadcrumbItems"
              :key="item.name"
              :to="{
                name: item.to,
              }"
            >
              {{ item.name }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>批次上傳照片</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div
        class="
          d-flex
          flex-column flex-xl-row
          align-items-start align-items-xl-center
          justify-content-between
          mb-4 mb-xl-2
        "
      >
        <h4 class="mb-2 mb-xl-0 font-weight-bold">批次上傳照片</h4>
        <b-button
          class="flex-shrink-0 mb-2 mb-xl-0"
          variant="primary"
          v-b-modal.modal-upload
        >
          <i class="fa fa-plus"></i>上傳照片</b-button
        >
      </div>

      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="uploadResult"
                :fields="fields"
                show-empty
              >
                <template #cell(avatar_url)="data">
                  <div class="d-flex align-items-center justify-content-center">
                    <b-avatar
                      :src="data.item.avatar_url"
                      variant="secondary"
                      size="2rem"
                    ></b-avatar>
                  </div>
                </template>
                <template #cell(employee_code)="data">
                  <div class="text-center text-muted">
                    <p>{{ data.item.employee_code }}</p>
                  </div>
                </template>
                <template #cell(name)="data">
                  <div class="text-center text-muted">
                    <p>{{ data.item.name }}</p>
                  </div>
                </template>
                <template #empty>
                  <div class="text-center text-muted">
                    <p>尚未上傳圖片</p>
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
      </b-card-text>
    </b-card>

    <b-modal
      id="modal-upload"
      title="批次上傳照片"
    >
      <b-overlay :show="showLoading">
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0">批次上傳照片</label>
          <b-form-file plain multiple accept="image/*" v-model="images" placeholder="尚未選擇檔案" browse-text="瀏覽"></b-form-file>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
        <b-button
          size="sm"
          variant="outline-primary"
          @click="uploadImages"
        >
          上傳
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import staffApi from "@/apis/staff";

export default {
  data() {
    return {
      showLoading: false,
      images: [],
      uploadResult: [],
      fields: [
        { key: 'avatar_url', label: '照片' },
        { key: 'employee_code', label: '員工編號' },
        { key: 'name', label: '姓名' },
        { key: 'status', label: '狀態'},
        { key: 'fail_reason', label: '失敗原因'}
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    breadcrumbItems() {
      switch (this.$route.name) {
        case "StaffUploadAvatar":
          return [
            { name: "控制中心" },
            { to: "StaffList", name: "帳號管理" },
          ];
        case "BranchStaffUploadAvatar":
          return [
            { to: "Branches", name: "分店管理" },
            { to: "BranchStaffs", name: "店內帳號" }
          ];
        default:
          return [];
      }
    },
  },
  methods: {
    async uploadImages() {
      try {
        this.showLoading = true;
        await this.doUploadImages();
      } catch (error) {
        console.error(error);
        this.$swal.fire({
          icon: "error",
          title: "上傳失敗",
          text: "請重新上傳",
        });
      } finally {
        this.showLoading = false;
        this.$bvModal.hide("modal-upload");
      }
    },
    async doUploadImages() {
      this.uploadResult = [];
      const resp = await staffApi.uploadAvatars(this.images, this.$route.params.branchID);
      const successImages = resp.data.data.success_images;
      const failImages = resp.data.data.fail_images;

      successImages.forEach((image) => {
        this.uploadResult.push({
          avatar_url: image.avatar_url,
          employee_code: image.employee_code,
          name: image.name,
          status: "上傳成功",
          fail_reason: "",
        });
      });

      failImages.forEach((image) => {
        const originalImage = this.images.find((image) => image.name === image.name);

        this.uploadResult.push({
          avatar_url: URL.createObjectURL(originalImage),
          status: "上傳失敗",
          fail_reason: image.fail_reason,
        });
      });
    },
  },
}
</script>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5;
}

.advance-search {
  color: #337ab7;

  &:hover {
    color: #23527c;
    text-decoration: underline;
  }
}
</style>
