import https from "./https"
import store from "@/store"

const organization = store.state.general.organization;

const booking = {
  // 權益卡 booking_presets
  getBenefitCards(params) {
    return https.get(`admin/organizations/${organization.id}/benefit-cards`, { params });
  },
  createBenefitCard(params) {
    return https.post(`admin/organizations/${organization.id}/benefit-cards`, params);
  },
  getBenefitCard(id) {
    return https.get(`admin/organizations/${organization.id}/benefit-cards/${id}`);
  },
  updateBenefitCard(id, params) {
    return https.put(`admin/organizations/${organization.id}/benefit-cards/${id}`, params);
  },
  deleteBenefitCard(id) {
    return https.delete(`admin/organizations/${organization.id}/benefit-cards/${id}`);
  },
  getMaxOrder(){
    return https.get(`admin/organizations/${organization.id}/benefit-cards/max-order`);
  },
  getBenefitConfig(id) {
    return https.get(`admin/organizations/${organization.id}/benefit-cards/${id}/config`);
  },
}

export default booking
