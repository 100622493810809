import https from "./https";
import store from "@/store";

const collectionApi = {
  async getCollection(id) {
    const organization = store.state.liffGeneral.orgCode
    return https.get(`${organization}/liff/collections/${id}`)
  },
  async getOptionsForBranch(id, branchId) {
    const organization = store.state.liffGeneral.orgCode
    return https.get(`${organization}/liff/collections/${id}/branch/${branchId}`)
  },
};

export default collectionApi
