<template>
  <b-modal
    id="modal-search-coupon"
    title="選擇優惠券"
    @show="initializeTempCoupons"
    no-close-on-backdrop
  >
    <b-input-group>
      <b-form-input placeholder="請輸入優惠券名稱" v-model="query.keyword" @keyup.enter="fetchCoupons"></b-form-input>
      <b-input-group-append>
        <b-button @click="fetchCoupons"><i class="fa fa-search"></i></b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="text-center mt-3 mb-3 loading-spinner" v-if="isLoading">
      <b-spinner variant="secondary" small />
    </div>
    <div class="mt-3 mb-3" v-else-if="coupons.length > 0">
      <div
        v-for="coupon in coupons"
        v-bind:key="coupon.redeem_code_bundle_id"
        class="d-flex align-items-center py-2 coupon-item"
        @click="handleSelected(coupon)"
      >
        <template>
          <b-img-lazy
            v-if="coupon.img_url"
            class="mx-2 thumb-image flex-shrink-0"
            :src="coupon.img_url"
            alt="優惠券圖片"
            width="50"
            height="25"
          />
          <div v-else class="mx-2 flex-shrink-0" :style="{ width: '50px', height: '25px'}"></div>
        </template>
        <div class="mr-auto text-nowrap text-truncate">
          <div class="font-weight-bold">
            {{ coupon.name }}
          </div>
          <small class="text-muted">
            {{ format(new Date(coupon.start_at), 'yyyy-MM-dd HH:mm') }} ~ {{ format(new Date(coupon.end_at), 'yyyy-MM-dd HH:mm') }}
          </small>
        </div>
        <div class="mx-2">
          <span v-if="selectedCouponsIdList.includes(coupon.id)" class="mdi mdi-check-circle mdi-24px" />
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <div class="float-right">
          <span
            class="mr-2 mdi mdi-chevron-left mdi-36px"
            :class="meta.current_page == 1 ? 'text-muted': ''"
            @click="prevPage">
          </span>
          <span
            class="mr-5 mdi mdi-chevron-right mdi-36px"
            :class="meta.current_page == meta.last_page ? 'text-muted': ''"
            @click="nextPage">
          </span>

          <b-button
            variant="danger"
            size="sm"
            class="mr-2 ml-5"
            @click="closeModal"
          >
            取消
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            @click="submitSelectedCoupons"
          >
            確定
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { format } from "date-fns";
  import redeemCodeBundleApi from "@/apis/redeemCodeBundle";

  export default {
    props: {
      selectedCoupons: {
        type: Array,
      },
    },

    computed: {
      selectedCouponsIdList() {
        return this.tempSelectedCoupons?.map((coupon) => coupon.id) ?? []
      }
    },
    data() {
      return {
        isLoading: false,
        coupons: [],
        // 暫時放選取的 coupon，確定才 submit
        tempSelectedCoupons: [],
        // 暫時放選取的 coupon，取消要回復用
        tempCoupons: [],
        meta: {},
        query: {
          page: 1,
          per_page: 10,
          merchant_id: null,
          create_type: "0",
          keyword: "",
        },
      }
    },

    watch: {
      "query.keyword"() {
        this.query.page = 1
      },
      selectedCoupons() {
        this.tempSelectedCoupons = [...this.selectedCoupons]
      }
    },

    created() {
      this.fetchCoupons()
    },
    methods: {
      format,
      async fetchCoupons() {
        try {
          this.isLoading = true;
          const params = {
            ...this.query,
          };
          const { data } = await redeemCodeBundleApi.getRedeemCodeBundles(params);
          this.meta = data.meta;
          this.coupons = data.data;
        } catch (error) {
          console.log("");
        }
        this.isLoading = false;
      },
      initializeTempCoupons() {
        this.tempSelectedCoupons = [...this.selectedCoupons]; // 以父層傳入的已選優惠券初始化
        this.tempCoupons = [...this.selectedCoupons];        // 保存取消時的還原基準
      },
      handleSelected(coupon) {
        const index = this.tempSelectedCoupons.findIndex(({ id }) => id === coupon.id);

        if (index === -1) {
          if (this.tempSelectedCoupons.length >= 10) {
            return
          }
          this.tempSelectedCoupons.push(coupon);
        } else {
          this.tempSelectedCoupons.splice(index, 1);
        }
      },
      submitSelectedCoupons() {
        this.$bvModal.hide('modal-search-coupon');
        this.$emit('submit', this.tempSelectedCoupons);
      },
      closeModal() {
        this.$bvModal.hide('modal-search-coupon');
        this.tempSelectedCoupons = this.tempCoupons;
      },
      prevPage() {
        if (this.meta.current_page == 1) {
          return
        }
        this.query.page -= 1;
        this.fetchCoupons();
      },
      nextPage() {
        if (this.meta.current_page == this.meta.last_page) {
          return
        }
        this.query.page += 1;
        this.fetchCoupons();
      }
    }
  }
</script>

<style lang="scss">
.coupon-item:hover {
  cursor: pointer;
  background-color: #c7c7c7;
  color: white;
  width: 100%;

  .staff-list-info {
    color: white;
  }

}
</style>
