<template>
  <div>
    <Loading
      v-if="isLoading"
      loading-message="載入中..."
      :background-color="'#ffffff'"
    />

    <main>
      <img class="w-100" v-if="topImageUrl" :src="topImageUrl">

      <div class="p-3" v-if="branchSelectionIsEnabled">
        <p class="label-for-branch-selection">{{ deepGet(preorder, 'config.branch_select.default_select_text', '請選擇區域 / 櫃點') }}</p>
        <ShopSelection
          ref="shopSelection"
          v-model="branchId"
          :branch-provider="deepGet(preorder, 'config.branch_select.branch_provider')"
          :show-branch-code="deepGet(preorder, 'config.branch_select.show_branch_code', false)"
          :placeholder="deepGet(preorder, 'config.branch_select.default_select_text', '請選擇區域 / 櫃點')"
          @loading="handleBranchesAreLoading"
          @loaded="handleBranchesAreLoaded"
        />
      </div>

      <img class="w-100" v-if="bottomImageUrl" :src="bottomImageUrl">

      <footer class="footer-action-bar p-3">
        <div class="footer-action-bar__terms" v-if="terms !== null" v-html="terms"></div>

        <label class="d-flex align-items-center justify-content-center mb-3" v-if="hintForConsent !== null">
          <b-form-checkbox
            v-model="hasConsentedToTerms"
            :value="true"
            :unchecked-value="false"
          />
          {{ hintForConsent }}
        </label>

        <ShareButton
          @click="handleNextStepButtonClicked"
          class="footer-action-bar__button"
          variant="primary"
          :disabled="(hintForConsent !== null && false === hasConsentedToTerms) || (branchSelectionIsEnabled && branchId === null)"
        >
          {{ deepGet(preorder, 'config.welcome_page.button_text') || '下一步' }}
        </ShareButton>
      </footer>
    </main>
  </div>
</template>

<script>
import ShopSelection from "@/components/Page/Liff/Branch/ShopSelection";
import ShareButton from "@/components/Page/Liff/Shared/Button";
import Loading from "@/components/Page/Liff/Shared/Loading";
import themeColor from "@/mixins/liff/themeColor";
import deepGet from "lodash/get";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [themeColor],
  components: {
    ShopSelection,
    ShareButton,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      branchId: null,
      hasConsentedToTerms: false,
    };
  },
  computed: {
    ...mapGetters('liffPreorder', ['totalQuantity']),
    ...mapState('liffPreorder', { preorder: 'configuration' }),
    preorderId() {
      return this.$route.params.preorderId
    },
    topImageUrl() {
      return deepGet(this.preorder, 'config.welcome_page.top_image_url')
    },
    bottomImageUrl() {
      return deepGet(this.preorder, 'config.welcome_page.bottom_image_url')
    },
    branchSelectionIsEnabled() {
      return deepGet(this.preorder, 'config.branch_select.is_enabled', false)
    },
    hintForConsent() {
      return deepGet(this.preorder, 'config.welcome_page.hint_for_consent', null)
    },
    terms() {
      return deepGet(this.preorder, 'config.welcome_page.terms', null)
    },
  },
  async mounted() {
    if (this.branchSelectionIsEnabled) {
      try {
        await this.$refs.shopSelection.fetchBranchesOptions()
      } catch (e) {
        this.$swal.fire({ title: '找不到分店來源', type: 'error' })
      }
    }

    this.isLoading = false
  },
  methods: {
    deepGet,
    async handleNextStepButtonClicked() {
      this.$router.push({
        name: 'LiffPreorderProducts',
        params: { preorderId: this.preorderId },
        query: this.branchId ? { branch_id: this.branchId } : {},
      })
    },
    handleBranchesAreLoading() {
      // this.isLoading = true
    },
    handleBranchesAreLoaded() {
      // this.isLoading = false
    },
  },
};
</script>

<style lang="scss" scoped>
.label-for-branch-selection:before {
  content: '*';
  color: #fe0000;
}

.footer-action-bar {
  &__terms {
    margin-bottom: 16px;
    padding: 16px;
    background-color: #f8f8f8;
  }
}
</style>
