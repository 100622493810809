<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'MainGroupbuyingsList',
              }"
              >總公司團購管理
            </b-breadcrumb-item>
            <b-breadcrumb-item active>上傳商品白名單</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row">
        <div class="col-12 col-xl-8">
          <h4 class="mb-2 font-weight-bold">上傳商品白名單</h4>
        </div>
      </div>

      <div>
        <b-form-group
          class="mt-2"
          label-cols="12"
          label-cols-lg="2"
          label="團購分店："
        >
          <b-form-radio-group v-model="form.branch_source">
            <b-form-radio class="mb-2" :value="BRANCH_SOURCE.ALL"
              >全部分店</b-form-radio
            >
            <b-form-radio class="mb-2" :value="BRANCH_SOURCE.CURRENT">
              <b-form-select
                v-model="form.branch_ids"
                placeholder="選擇分店"
                size="sm"
                :disabled="form.branch_source !== BRANCH_SOURCE.CURRENT"
              >
                <b-form-select-option :value="null" disabled
                  >選擇分店
                </b-form-select-option>
                <b-form-select-option
                  v-for="branch in branchOptions"
                  :value="branch.value"
                  :key="branch.value"
                  >{{ branch.text }}
                </b-form-select-option>
              </b-form-select>
            </b-form-radio>
            <b-form-radio :value="BRANCH_SOURCE.EXCEL">
              <div class="form-group d-inline-flex align-items-center">
                <div class="flex-shrink-0 mr-2 mb-0">上傳分店</div>
                <b-form-file
                  :disabled="form.branch_source !== BRANCH_SOURCE.EXCEL"
                  name="上傳分店"
                  plain
                  @input="uploadBranches"
                  placeholder="尚未選擇檔案"
                  browse-text="瀏覽"
                >
                </b-form-file>
              </div>
              <b-button
                size="sm"
                variant="outline-primary"
                href="/excel/branch_list.xlsx"
                ><i class="fa fa-file-text"></i>下載範例檔案</b-button
              >
            </b-form-radio>
          </b-form-radio-group>
        </b-form-group>
        <b-form-group
          class="mt-2"
          label-cols="12"
          label-cols-lg="2"
          label="商品名單："
        >
          <div class="d-inline-flex align-items-center">
            <div>
              <b-form-file plain @input="uploadProducts" placeholder="尚未選擇檔案" browse-text="瀏覽"></b-form-file>
            </div>

            <b-button
              size="sm"
              variant="outline-primary"
              href="/excel/product_white_list.xlsx"
              ><i class="fa fa-file-text"></i>下載範例檔案</b-button
            >
          </div>
        </b-form-group>

        <div class="d-flex justify-content-center">
          <b-overlay
            :show="isSubmitting"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="d-inline-block"
          >
            <b-button
              variant="success"
              @click="handleSubmit"
              :disabled="isLoading || !isProductExcelLoaded"
              :busy="isLoading"
            >
              <span
                v-if="isLoading"
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span v-if="!isLoading">送出</span>
            </b-button>
          </b-overlay>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import branchApi from "@/apis/branch";
import eventProductApi from "@/apis/event-product";

const BRANCH_SOURCE = {
  ALL: "all",
  CURRENT: "current",
  EXCEL: "excel",
};

export default {
  data() {
    return {
      BRANCH_SOURCE,
      isSubmitting: false,
      form: {
        branch_source: BRANCH_SOURCE.ALL,
        branch_ids: null,
        products: [],
      },
      isLoading: false,
      isProductExcelLoaded: false,
      eventProduct: {},
      excelBranchIDs: [],
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      myBranches: (state) => state.myBranches,
    }),
    ...mapGetters("general", ["branchOptions"]),

    product() {
      return this.eventProduct.product || {};
    },
  },

  methods: {
    async handleSubmit() {
      this.isSubmitting = true;
      try {
        await this.formatPayload();
        var response = {};
        response = await eventProductApi.importEventProduct(this.form);

        if (response.status === 200) {
          await this.$swal.fire({
            title: "成功",
            type: "success",
            text: "上傳成功",
          });
        }
      } catch (error) {
        this.$swal("失敗", error.response.data.message, "error");
      }

      this.$router.push({ name: "ProductWhiteList" });
      this.isSubmitting = false;
    },

    async formatPayload() {
      if (this.form.branch_source === BRANCH_SOURCE.ALL) {
        this.form.branch_ids = [];
        this.form.branch_ids.push(
          ...this.myBranches.map((branch) => branch.id)
        );
      }
      if (this.form.branch_source === BRANCH_SOURCE.CURRENT) {
        const selectedBranchID = this.form.branch_ids;
        this.form.branch_ids = [];
        try {
          const { data } = await branchApi.getCurrentBelowBranches({
            branch_id: selectedBranchID,
          });
          this.branches = [{ value: null, text: "分店名稱" }];
          data.data.forEach((branch) => {
            if (branch.org_id === this.organization.id) {
              this.form.branch_ids.push(branch.id);
            }
          });
        } catch (error) {
          this.$swal("失敗", error.response.data.message, "error");
          return;
        }
      }
      if (this.form.branch_source === BRANCH_SOURCE.EXCEL) {
        this.form.branch_ids = [];
        this.form.branch_ids.push(...this.excelBranchIDs);
      }
    },

    async uploadProducts(file) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", file);

      try {
        const data = await eventProductApi.readProductExcel(formData);

        this.form.products.push(...data.data);
        this.isProductExcelLoaded = true;
        this.isLoading = false;
      } catch (error) {
        this.isProductExcelLoaded = false;
        let errorMessage = `匯入格式錯誤`;
        if (error.response.data.code) {
          errorMessage = `您匯入的商品頁編號：${error.response.data.code} <br/>查無此商品`;
        }

        this.$swal({
          type: "error",
          html: errorMessage,
        }).then(() => {
          this.code = null;
        });
        this.$router.push({ name: "ProductWhiteList" });
      } finally {
        this.isLoading = false;
      }
    },

    async uploadBranches(file) {
      this.isLoading = true;
      let formData = new FormData();
      formData.append("file", file);

      try {
        const {
          data: { data },
        } = await eventProductApi.readBranchExcel(formData);
        this.excelBranchIDs.push(...data.map((b) => b.id));
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
/* Default override (not using "append-to-body") */
.vdp-datepicker input.is-invalid,
.vue__time-picker input.is-invalid {
  border: 1px solid #dc3545 !important;
}

.vdp-datepicker input:disabled,
.vue__time-picker input:disabled {
  background-color: #e9ecef !important;
  color: #212529 !important;
}
</style>
