var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'Branches',
            }}},[_vm._v("分店管理 ")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("店內帳號")])],1)],1)])]),_c('b-card',[_c('div',{staticClass:"d-flex flex-column flex-xl-row mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"mb-2 mb-xl-0 font-weight-bold"},[_vm._v(" 店內帳號 ")]),_c('div',{staticClass:"\n          d-flex\n          flex-column flex-xl-row\n          align-items-center\n        "},[(_vm.$permissions.has(_vm.$permissions.consts.EXPORT_STAFF_SPECIFIC_URI))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.exportBindingQrcodes()}}},[_vm._v(" 綁定 QRCode 匯出 ")]):_vm._e(),(_vm.$permissions.has(_vm.$permissions.consts.EXPORT_STAFF_SPECIFIC_URI))?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.exportStaffWithBindingUrls()}}},[_vm._v(" 綁定連結匯出 ")]):_vm._e(),_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-2",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.exportStaff()}}},[_vm._v(" 匯出帳號 ")]),(
            _vm.$permissions.has(_vm.$permissions.consts.BRANCH_DETAIL_MODIFY_STAFF)
          )?_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0",attrs:{"variant":"primary","to":{
            name: 'BranchStaffCreate',
            params: {
              branchID: _vm.branchID,
            },
          }}},[_c('i',{staticClass:"fa fa-plus"}),_vm._v("新增帳號 ")]):_vm._e(),(_vm.$permissions.has(_vm.$permissions.consts.BRANCH_DETAIL_MODIFY_STAFF))?_c('b-dropdown',{staticClass:"flex-shrink-0 ml-2",attrs:{"variant":"primary"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fa fa-plus"}),_vm._v(" 批次上傳 ")]},proxy:true}],null,false,941651847)},[_c('b-dropdown-item',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-upload",modifiers:{"modal-upload":true}}]},[_vm._v("批次上傳帳號")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'BranchStaffUploadAvatar' }}},[_vm._v("批次上傳照片")])],1):_vm._e()],1)]),_c('b-breadcrumb',{staticClass:"d-flex items-center",attrs:{"items":_vm.breadcrumb}}),_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.staffs,"fields":_vm.fields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"cell(avatar_url)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-avatar',{attrs:{"src":data.item.avatar_url,"variant":"secondary","size":"2rem"}})],1)]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"id":"ddown23","variant":"secondary"}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"mdi mdi-menu"})]),_c('b-dropdown-item',{attrs:{"to":{
                      name: 'BranchStaffView',
                      params: {
                        staff_id: data.item.id,
                        branchID: _vm.branchID,
                      },
                    }}},[_vm._v(" 查看 ")]),(
                      _vm.$permissions.has(
                        _vm.$permissions.consts.BRANCH_DETAIL_MODIFY_STAFF
                      )
                    )?_c('b-dropdown-item',{attrs:{"to":{
                      name: 'BranchStaffForm',
                      params: {
                        staff_id: data.item.id,
                        branchID: _vm.branchID,
                      },
                    }}},[_vm._v(" 編輯 ")]):_vm._e(),(
                      _vm.$permissions.has(
                        _vm.$permissions.consts.STAFF_BRANCH_MODIFY
                      )
                    )?_c('b-dropdown-item',{attrs:{"to":{
                      name: 'BranchStaffSetBranch',
                      params: {
                        staff_id: data.item.id,
                        branchID: _vm.branchID,
                      },
                    }}},[_vm._v(" 設定所屬分店 ")]):_vm._e(),(
                      _vm.$permissions.has(
                        _vm.$permissions.consts.STAFF_CUSTOMER_VIEW
                      )
                    )?_c('b-dropdown-item',{attrs:{"to":{
                      name: 'BranchStaffCustomerList',
                      params: { staff_id: data.item.id },
                    }}},[_vm._v(" 所屬會員 ")]):_vm._e(),(
                      _vm.$permissions.has(
                        _vm.$permissions.consts.STAFF_ROLE_MODIFY
                      )
                    )?_c('b-dropdown-item',{attrs:{"to":{
                      name: 'BranchStaffSetRole',
                      params: { staff_id: data.item.id },
                    }}},[_vm._v(" 設定角色 ")]):_vm._e(),(
                      _vm.$permissions.has(
                        _vm.$permissions.consts.BRANCH_DETAIL_CHANGE_PASSWORD
                      )
                    )?_c('b-dropdown-item',{attrs:{"to":{
                      name: 'BranchStaffChangePassword',
                      params: {
                        staff_id: data.item.id,
                        branchID: _vm.branchID,
                      },
                    }}},[_vm._v(" 變更密碼 ")]):_vm._e(),(
                      _vm.$permissions.has(
                        _vm.$permissions.consts.BRANCH_DETAIL_DELETE_STAFF
                      )
                    )?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteStaff(data.item)}}},[_vm._v(" 刪除 ")]):_vm._e()],2)]}}])})],1)],1)]),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"per-page":10,"align":"center"},on:{"change":_vm.getStaffs},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1),_c('b-modal',{attrs:{"id":"modal-upload","title":"批次上傳"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
                    var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){_vm.getSampleExcelFile(_vm.getModuleConfig('staff', 'sample_file.staff_import_xls') ,'excel/staff_list.xlsx')}}},[_c('i',{staticClass:"fa fa-file-text"}),_vm._v("下載範例檔案")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return cancel()}}},[_vm._v(" 關閉 ")])]}}])},[_c('b-overlay',{attrs:{"show":_vm.showLoadingUpload}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"flex-shrink-0 mr-2 mb-0"},[_vm._v("請上傳帳號 Excel 檔案")]),_c('b-form-file',{attrs:{"plain":"","placeholder":"尚未選擇檔案","browse-text":"瀏覽"},on:{"input":_vm.uploadStaff}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }