import https from "./https";
import store from "@/store";

const branchApi = {
  getBranches(params) {
    const organization = store.state.liffGeneral.orgCode;
    return https.get(`${organization}/liff/branches`, {
      params
    });
  },

  getSingleBranch(branchId) {
    const organization = store.state.liffGeneral.orgCode;
    return https.get(`${organization}/liff/branches/${branchId}`);
  },
};

export default branchApi
