<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              參數設定 
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
              BodyShop 查詢 
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">BodyShop 查詢</h4>
        </div>
      </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="電話"
        >
          <b-input-group>
            <b-form-input
              class="mb-2 mr-sm-2 mb-sm-0"
              type="text"
              v-model="phone"
            ></b-form-input>
            <b-input-group-append>
              <b-button
                class="mr-2"
                variant="info"
                @click="handleSearch"
                :disabled="isLoading"
              >
              查詢 
              </b-button>
            </b-input-group-append>
          </b-input-group> 
        </b-form-group>
        <editor
          class="mb-2 mr-sm-2 mb-sm-0"
          v-model="result"
          width="100%"
          height="200px"
          @init="editorInit"
          lang="json"
          theme="chrome"
          :disabled="!editable"
        >
        </editor>
      </b-overlay>
    </b-card>
  </div>
</template>

<style scoped lang="scss">
.group + .group {
  border-top: 1px solid #cdd6dc !important;
}
</style>
<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import editor from "vue2-ace-editor";
import fileMixin from "@/mixins/uploadFile";
import bodyshopApi from "@/apis/bodyshop"


export default {
  mixins: [fileMixin],
  components: { editor },
  validationConfig: {
    $lazy: true,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    /*
     *  由於欄位是個動態 array ，因此 vuelidate 幾乎觸發不了驗證
     * ，因此編輯時暫時將
     *  forms = [{key: 'k', value: 'v'}]
     *  轉為form = {k:v}
     *  最後 submit 再轉換回來
     */
    return {
      code: null,
      phone: null,
      result: "",
      color: "",
      isLoading: false,
      isSubmmiting: false,
      formGroups: {},
      module: {},
      fetchedVariableId: null,
      canEdit: false,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    orgId() {
      return this.organization.id;
    },
    editable() {
      return !this.isShownByVariableCode || this.canEdit
    },
  },
  mounted() {
    if (!this.checkPermission([consts.BODYSHOP_SETTING_QUERY])) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {
          this.$router.push({
            name: "OrganizationVariableList",
          });
        });
    } else if (this.moduleId || this.isShownByVariableCode) {
      this.fetchModule();
    }
  },
  methods: {
    editorInit() {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/json");
      require("brace/mode/html");
      require("brace/theme/chrome");
    },
    async handleSearch() {
      try {
        this.isSubmmiting = true;
        this.result = "查詢中...";
        const result = await bodyshopApi.searchPhone(
          this.orgId,
          {
            phone: this.phone,
          }
        );
        this.result = JSON.stringify(result.data, null, 2);
      } catch (error) {
        this.result = JSON.stringify(error.response.data, null, 2);
        console.log(this.result)
      }
      this.isSubmmiting = false;
    },
    async handleSubmit(redirect = true) {
      const result = await this.v$.$validate();
      if (!result) return;
      this.handleUpdate(redirect);
    },
    showSuccessPopup(redirect = true) {
      this.$swal
        .fire({
          type: "success",
          text: "修改成功",
        })
        .then(() => {
          if (redirect) {
            this.$router.push({
              name: this.isByMerchant
                ? "OrganizationVariableListByMerchant"
                : "OrganizationVariableList",
            });
          }
        });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>
