import https from "./https"
import store from "@/store"

const organization = store.state.general.organization;

const collection = {
  getCollections(params) {
    return https.get(`admin/organizations/${organization.id}/collections`, { params });
  },
  async getOptionsForBranch(id, branchId) {
    return https.get(`admin/organizations/${organization.id}/collections/${id}/branch/${branchId}`)
  },
  deleteCollection(id) {
    return https.delete(`admin/organizations/${organization.id}/collections/${id}`);
  },
  createCollection(params) {
    return https.post(`admin/organizations/${organization.id}/collections`, params);
  },
  updateCollection(id, params) {
    return https.put(`admin/organizations/${organization.id}/collections/${id}`, params);
  },
  getCollection(id) {
    return https.get(`admin/organizations/${organization.id}/collections/${id}`);
  },
  getItems(id, params) {
    return https.get(`admin/organizations/${organization.id}/collections/${id}/items`, { params });
  },
  createItem(collectionId, params) {
    return https.post(`admin/organizations/${organization.id}/collections/${collectionId}/items`, params);
  },
  deleteItem(collectionId, itemId) {
    return https.delete(`admin/organizations/${organization.id}/collections/${collectionId}/items/${itemId}`);
  },
  getItem(collectionId, itemId) {
    return https.get(`admin/organizations/${organization.id}/collections/${collectionId}/items/${itemId}`);
  },
  upadteItem(collectionId, itemId, params) {
    return https.put(`admin/organizations/${organization.id}/collections/${collectionId}/items/${itemId}`, params);
  },
  syncToBranch(params) {
    return https.put(`admin/organizations/${organization.id}/collections/sync-branch-collections`, params);
  },
  getRelationsItems(collectionId, branchId, params) {
    return https.get(`admin/organizations/${organization.id}/collections/${collectionId}/branch/${branchId}/get-relations-items`, { params });
  },
  handleSyncCollectionToSingleBranch(params) {
    return https.post(`admin/organizations/${organization.id}/collections/sync-single-branch-collections`, params);
  },
  changeRelationIsEnabled(collectionId , params) {
    console.log(params)
    return https.put(`admin/organizations/${organization.id}/collections/${collectionId}/batch-update-relation-is-enabled`, params);
  }
}

export default collection
