import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState("general", {
      organizationThemeConfig: (state) => state.organizationThemeConfig,
    }),
    ...mapGetters('general', ['role']),
  },
  methods: {
    goBackByConfig() {
      const pathConfig = this.organizationThemeConfig?.dashboard?.my_console?.back_path;

      if (!pathConfig) {
        this.$router.push({name: "DashboardHome"});
        return;
      }

      let path = '/' + this.$route.params.org_code + pathConfig.default
      const roleArray = Object.keys(pathConfig.roles)
      if (roleArray.includes(this.role)) {
        path = '/' + this.$route.params.org_code + pathConfig.roles[this.role]
      }

      this.$router.push(path)
    },
  },
}
