<template>
  <div>
    <redeem-modal
      ref="redeemModal"
      :coupon="selectedRedeemCode"
      @confirmed="refresh"
    />

    <Loading v-if="fetching" />

    <img
      v-if="deepGet(benefitCard, 'config.page_image')"
      :src="deepGet(benefitCard, 'config.page_image')"
      class="w-100"
    >

    <div class="benefit-tabs" :class="{ 'pt-3': false === tabsContainImages, 'px-3': false === tabsContainImages }">
      <a
        class="benefit-tab"
        v-for="tab in dataSources"
        :key="tab.path"
        :class="{ 'active-tab': $route.params.tabPath === tab.path }"
        @click="handleTabChanges(tab)"
      >
        <img v-if="tab.tab_image" :src="tab.tab_image" />
        <template v-else>{{ tab.tab_name }}</template>
      </a>
    </div>

    <div class="px-3 overflow-x-hidden page">
      <template v-if="tabPath">
        <div class="page__title" v-if="deepGet(dataSource, 'form_title')">{{ deepGet(dataSource, 'form_title') }}</div>
        <div class="page__desc" v-if="deepGet(dataSource, 'form_desc')">{{ deepGet(dataSource, 'form_desc') }}</div>

        <b-card class="border border-1 benefit-card" no-body>
          <div class="benefit-card__body p-2">
            <div
              v-for="(i, index) in selectedRedeemCodes"
              :key="i.id"
              class="benefit-card__body__point p-2"
              :class="{ 'active': i.status == 'redeemed', 'unavailable': false === redeemCodeIsRedeemable(i) }"
              :style="{
                minWidth: `${
                  100 /
                  Math.round(
                    selectedRedeemCodes.length / (selectedRedeemCodes.length > 4 ? 2 : 1)
                  )
                }%`,
              }"
            >
              <div
                class="
                  benefit-card__body__point__stamp
                  d-flex
                  justify-content-center
                  align-items-center
                "
                @click="showRedeemModal(i)"
              >
                <span v-if="i.status == 'applied'">{{ index + 1 }}</span>
                <span v-else-if="i.status == 'applied_and_not_yet'">未開始</span>
                <span v-else-if="i.status == 'upcoming'">快開始</span>
                <img v-else-if="i.status == 'redeemed'" src="./images/stamp.svg" />
                <span v-else-if="i.status == 'expired'">已過期</span>
              </div>
            </div>
          </div>
        </b-card>

        <div class="page__content">{{ deepGet(dataSource, "content") }}</div>

        <div class="page__button s-space-y-4 px-0">
          <ShareButton
            variant="s-btn-outline-primary"
            @click="handleUseNowButtonClicked"
            v-if="deepGet(dataSource, 'use_now_button_text')"
          >
            {{ deepGet(dataSource, 'use_now_button_text') }}
          </ShareButton>
          <MemberButton
            v-if="deepGet(dataSource, 'button_text')"
            :button-text="deepGet(dataSource, 'button_text')"
            :button-url="deepGet(dataSource, 'button_url')"
            :type="deepGet(dataSource, 'button_action')"
          ></MemberButton>
          <MemberButton
            v-if="deepGet(dataSource, 'tab_button_text')"
            :button-style="`s-btn-outline-primary`"
            :button-text="deepGet(dataSource, 'tab_button_text')"
            :button-url="deepGet(dataSource, 'tab_button_url')"
            :type="deepGet(dataSource, 'tab_button_action')"
          ></MemberButton>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import benefitCardApi from "@/apis/liff/v2/benefit-card";
import ShareButton from "@/components/Page/Liff/Shared/Button";
import Loading from "@/components/Page/Liff/Shared/Loading";
import mixinStaff from "@/mixins/liff/staff";
import themeColor from "@/mixins/liff/themeColor";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import RedeemModal from "@/components/Page/Liff/CouponList/CouponModal.vue";

import { isAfter, isBefore } from "date-fns";
import _ from "lodash";

export default {
  mixins: [mixinStaff, themeColor],
  components: {
    ShareButton,
    Loading,
    MemberButton,
    RedeemModal,
  },
  data() {
    return {
      fetching: true,
      benefitCard: null,
      currentTab: null,
      redeemCodes: [],
      selectedRedeemCode: {},
    };
  },
  computed: {
    ...mapGetters("liffGeneral", ["themeConfig", "meta"]),
    ...mapGetters("liffModule", ["getModule"]),
    moduleConfig() {
      return this.getModule("liff_benefit_card")?.module_config || {};
    },
    dataSources() {
      return _.sortBy(_.get(this.benefitCard, "config.data_source", []), 'order');
    },
    dataSource() {
      return this.dataSources.find((x) => x.path == this.tabPath);
    },
    tabsContainImages() {
      return this.dataSources.some(tab => tab.tab_image)
    },
    benefitCardCustomerId() {
      return this.$route.params.benefitCardCustomerId;
    },
    tabPath() {
      return this.$route.params.tabPath;
    },
    selectedRedeemCodes() {
      return this.redeemCodes.filter(x=>x.redeem_code_bundle_id==this.dataSource.redeem_code_bundles[0].hashid)
    },
  },
  async mounted() {
    await this.init();

    if (this.dataSources.length > 0 && !this.tabPath) {
      this.handleTabChanges(this.dataSources[0])
    }

    this.fetching = false;
  },
  methods: {
    isAfter,
    isBefore,
    deepGet: _.get,
    async init() {
      this.benefitCard = _.get(
        await benefitCardApi.get(this.benefitCardCustomerId),
        "data"
      );
      await this.fetchRedeemCodes();
    },
    handleTabChanges(tab) {
      this.$router.replace({
        name: "LiffBenefitCardHome",
        params: {
          benefitCardCustomerId: this.benefitCardCustomerId,
          tabPath: tab.path,
        },
      });
    },
    handleUseNowButtonClicked() {
      const redeemCode = this.selectedRedeemCodes.find(this.redeemCodeIsRedeemable)
      if (!redeemCode) {
        return
      }

      this.selectedRedeemCode = redeemCode;
      this.$refs.redeemModal.open();
    },
    async fetchRedeemCodes() {
      this.showLoading = true;
      this.redeemCodes = _.get(
        await benefitCardApi.coupons(this.benefitCardCustomerId),
        "data"
      );
      this.showLoading = false;
    },
    redeemCodeIsRedeemable(redeemCode) {
      return false === ['applied_and_not_yet', 'expired', 'redeemed'].includes(redeemCode.status)
    },
    showRedeemModal(redeemCode) {
      if (false === this.redeemCodeIsRedeemable(redeemCode)) {
        return;
      }
      this.selectedRedeemCode = redeemCode;
      this.$refs.redeemModal.open();
    },
    refresh() {
      this.selectedRedeemCode = {};
      this.fetchRedeemCodes();
    },
  },
};
</script>

<style lang="scss" scoped>
.benefit-card {
  border-radius: 0.5rem;
  overflow-x: hidden;

  &__header {
    border-top-color: var(--s-primary) !important;
    border-top-width: 5px !important;
    font-weight: 600;
    border-bottom: solid 1px #e5e5ea;

    &__point {
      font-size: 30px;
      color: var(--s-primary);
    }
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: auto;

    &__point {
      opacity: 0.5;

      &__stamp {
        aspect-ratio: 1/1;
        border: dashed 2px #c0c4cc;
        background-color: #f2f2f7;
        color: #979797;
        border-radius: 100%;
        font-size: 20px;
        font-weight: 600;
        width: 60px;
        margin: auto;
        padding: 15px;
        white-space: nowrap;
        line-height: 1;

        img {
          max-width: 100%;
          width: 100%;
        }
      }

      &.unavailable &__stamp {
        font-size: 13px;
      }

      &__date {
        font-size: 12px;
        text-align: center;
        color: #979797;
        visibility: hidden;
      }
    }

    &__point.active {
      opacity: 1;

      .benefit-card__body__point__stamp {
        //content: url(images/stamp.svg);
        background-color: var(--s-primary);
        border: none;
        box-shadow: 0px 4px 4px rgba(44, 44, 46, 0.15);
      }

      .benefit-card__body__point__date {
        visibility: visible;
      }
    }

    //     &__point {
    //       &.active {
    //         content: '<svg width="28" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    // <path d="M14 2L17.708 9.512L26 10.724L20 16.568L21.416 24.824L14 20.924L6.584 24.824L8 16.568L2 10.724L10.292 9.512L14 2Z" fill="white" stroke="#FBFBFB" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
    // </svg>';
    //       }
    //     }
  }
}

.benefit-tabs {
  display: flex;
  background-color: var(--s-white);
  position: sticky;
  top: 0;
  z-index: 10;
  .benefit-tab {
    font-weight: 600;
    font-size: 15px;
    line-height: 21px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 52px;
    padding-bottom: 2px;

    color: var(--liff-tab_inactive_color) !important;

    //background-position: center;
    //background-size: cover;
    //background-repeat: no-repeat;

    // &.router-link-exact-active, &.active {
    //   border-bottom: 2px solid var(--liff-tab-color);
    //   color: var(--liff-tab-color);
    // }

    img {
      width: 100%;
      height: auto;
    }

    &.active-tab {
      border-bottom: 2px solid var(--liff-tab-color);
      color: var(--liff-tab-color) !important;
      padding-bottom: 0;
    }
  }
}
</style>

<style lang="scss">
.modal-backdrop {
  opacity: 0.5 !important;
}
</style>
