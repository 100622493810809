<template>
<div class="s-layout" :style="colorObject">
  <div class="s-container">
    <Header
      :meta="meta"
      :page-title="getModuleConfig('page_title')"
      :page-image="getModuleConfig('page_image')"
      :page-kv-image="getModuleConfig('page_kv_image')"
      :header-section="getModuleConfig('header_section')"
    />

    <Loading v-if="isLoading" loading-message="載入中..." :background-color="'#ffffff'"/>

    <template v-if="deepGet(bookingPreset, 'config.welcome_page.enable', false)">
      <img
        class="banner-image w-100"
        v-if="deepGet(bookingPreset, 'config.welcome_page.banner_image')"
        :src="deepGet(bookingPreset, 'config.welcome_page.banner_image')"
      />

      <div class="page">
        <div
          v-if="deepGet(bookingPreset, 'config.welcome_page.form_title')"
          class="page__title"
        >
          {{ deepGet(bookingPreset, 'config.welcome_page.form_title') }}
        </div>
        <div v-if="deepGet(bookingPreset, 'config.welcome_page.form_desc')" class="page__desc">
          {{ deepGet(bookingPreset, 'config.welcome_page.form_desc') }}
        </div>
        <div
          v-if="deepGet(bookingPreset, 'config.welcome_page.content')"
          v-html="deepGet(bookingPreset, 'config.welcome_page.content')"
          class="page__content"
        ></div>
        <div class="page__button s-space-y-4">
          <SharedButton class="s-btn-bg-primary" @click="goToContent">
            {{ deepGet(bookingPreset, 'config.welcome_page.button_text') }}
          </SharedButton>
        </div>
      </div>
    </template>
  </div>
</div>
</template>

<script>
import bookingPresetApi from '@/apis/liff/v2/booking-preset'
import SharedButton from "@/components/Page/Liff/Shared/Button";
import Loading from "@/components/Page/Liff/Shared/Loading";
import { themeColorFn } from "@/mixins/liff/themeColor";
import deepGet from "@/utils/deepGet";
import { generateModuleConfigGetter } from "@/utils/liff/vueStoreHelpers";
import { mapGetters } from "vuex";

export default {
  mixins: [
    themeColorFn({ themeConfigPage: 'booking' })
  ],
  components: {
    SharedButton,
    Loading,
  },
  data() {
    return {
      bookingPreset: {},
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
    }),
    bookingPresetId() {
      return this.$route.params.bookingPresetId
    },
  },
  async mounted() {
    await this.fetchBookingPreset();

    if (this.bookingPreset.status != 'published') {
      this.$router.push({
        name: "LiffBookingClosed",
      })
      return
    }

    const welcomePageIsEnabled = deepGet(this.bookingPreset, 'config.welcome_page.enable', false)
    if (welcomePageIsEnabled) {
      this.isLoading = false;
    } else {
      this.goToContent()
    }
  },
  methods: {
    deepGet,
    getModuleConfig: generateModuleConfigGetter('liff_booking'),
    goToContent() {
      this.$router.push({
        name: "LiffBookingContent",
      });
    },
    async fetchBookingPreset() {
      try {
        this.bookingPreset = await bookingPresetApi.get(this.bookingPresetId)
      } catch (error) {
        if (error.response.status === 404) {
          this.$swal("錯誤", "找不到此問券", "error");
        } else {
          console.error(error);
          this.$swal("錯誤", "", "error");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
  --s-danger: #fe0000;
}

.s-container {
  min-height: 100vh;
}

.page {
  padding: 16px 12px;
  background: var(--liff-content_bg_color);

  &__title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 16px;
    text-align: center;
    color: var(--liff-primary_text_color) !important;
  }

  &__desc {
    font-size: 14px;
    line-height: 22px;
    color: var(--liff-secondary_text_color) !important;
    margin-bottom: 16px;
    text-align: center;

    b {
      color: var(--primary-color) !important;
      font-weight: 600;
    }
  }

  &__button {
    padding: 12px;
    color: var(--liff-primary_text_color) !important;
  }
}
</style>
