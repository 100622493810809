<template>
  <div class="DateSearchPicker d-flex">
    <div class="DateSearchPicker__input mb-2 mb-md-0">
      <datepicker
        v-model="start_at"
        bootstrap-styling
        format="yyyy-MM-dd"
        :language="zh"
        clear-button
        :placeholder="startedPlaceholder"
        :disabled="disabled"
        @change="(e) => changeDates('start_at', e)"
      ></datepicker>
    </div>
    <div class="DateSearchPicker__middle" style="line-height: 45px">～</div>
    <div
      class="DateSearchPicker__input DateSearchPicker__input--end mb-2 mb-md-0"
    >
      <datepicker
        v-model="end_at"
        bootstrap-styling
        format="yyyy-MM-dd"
        :language="zh"
        :disabledDates="disabledEndAtDates"
        clear-button
        :placeholder="endedPlaceholder"
        :disabled="disabled"
        @change="(e) => changeDates('end_at', e)"
      ></datepicker>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  props: [
    "value",
    "disabledEndAtDates",
    "startedPlaceholder",
    "endedPlaceholder",
    "disabled"
  ],
  components: {
    Datepicker,
  },
  data() {
    return {
      zh,
    };
  },

  computed: {
    start_at: {
      get() {
        return this.value.start_at;
      },
      set(val) {
        this.$emit(`input`, {
          ...this.value,
          start_at: val,
        });
      },
    },
    end_at: {
      get() {
        return this.value.end_at;
      },
      set(val) {
        this.$emit(`input`, {
          ...this.value,
          end_at: val,
        });
      },
    },
  },
};
</script>

<style lang="scss">
.DateSearchPicker {
  &__middle {
    width: 10%;
    text-align: center;
  }

  &__input {
    width: 170px;
  }
}

@media screen and (max-width: 768px) {
  .DateSearchPicker {
    width: 100%;
    &__input {
      width: 45%;
      &--end .vdp-datepicker__calendar {
        right: 0;
      }
    }
  }
}
</style>
