<template>
  <b-overlay :show="showLoading">
    <div class="overflow-auto w-100">
      <!-- Page Header Start -->
      <div class="page-title-header">
        <div class="page-header d-flex col-12">
          <b-breadcrumb class="col-11 m-0">
            <b-breadcrumb-item :to="{
              name: 'DashboardHome',
            }">
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>
              {{ isBookingCenter() ? "登記預約中心" : "預約管理" }}
            </b-breadcrumb-item>

            <b-breadcrumb-item v-if="bookingPreset.name" :to="isBookingCenter()
              ? {
                name: 'BookingCenterBookingList',
                params: { id: bookingPreset.id },
              }
              : { name: 'BookingList', params: { id: bookingPreset.id } }
              ">
              {{ bookingPreset.name }}
            </b-breadcrumb-item>

            <b-breadcrumb-item active>估價單</b-breadcrumb-item>
          </b-breadcrumb>

          <b-button class="col-1" variant="inverse-info" v-on:click="generatePdf">
            <span class="mdi mdi-printer"></span> 列印
          </b-button>
        </div>
      </div>
      <!-- Page Header End -->

      <div class="pdf-container mx-auto" id="download-pdf">
        <header class="my-4" v-html="getCleanedHtml(bookingPreset.config.quotation_header)">
          <!--          <div class="row header-div align-items-center">-->
          <!--            <div class="col-md-6 text-center">-->
          <!--              <img src="https://storage.googleapis.com/waltily-dev/upload_images/HIYEvgCYd2kZz5iC.jpeg" alt="公司 Logo" class="img-fluid">-->
          <!--            </div>-->
          <!--            <div class="col-md-6" style="text-align: justify;">-->
          <!--              <h1 class="h4">金谷旅行社有限公司</h1>-->
          <!--              <h2 class="h5">金谷通運股份有限公司</h2>-->
          <!--              <p>服務專線：03-5313788 FAX：03-5339149</p>-->
          <!--              <p>服務地址：新竹市經國路二段100號8樓812室</p>-->
          <!--            </div>-->
          <!--          </div>-->
        </header>

        <h3 class="quote-title">估價單</h3>
        <p class="customer-info color-blue" v-html="bookingPreset.name"></p>

        <table class="quote-table" v-if="isCompletedFormated">
          <!--        <thead>-->
          <!--        <tr>-->
          <!--          <th>日期</th>-->
          <!--          <th>序號</th>-->
          <!--          <th>內容</th>-->
          <!--          <th>數量</th>-->
          <!--          <th>單位</th>-->
          <!--          <th>單價</th>-->
          <!--          <th>合計</th>-->
          <!--          <th>備註</th>-->
          <!--        </tr>-->
          <!--        </thead>-->
          <!--        <tbody>-->
          <!--        <tr>-->
          <!--          <td class="color-blue">4月8日</td>-->
          <!--          <td class="color-blue">1</td>-->
          <!--          <td class="color-blue">08:40 新竹羅技電子 → 台威科技<br>13:30 台灣桃園 → 17:00 回程</td>-->
          <!--          <td class="color-blue">1</td>-->
          <!--          <td class="color-blue">中巴</td>-->
          <!--          <td class="color-blue">10,500</td>-->
          <!--          <td>10,500</td>-->
          <!--          <td></td>-->
          <!--        </tr>-->
          <!--        <tr>-->
          <!--          <td colspan="5">此報價含(路.停車費.司機小費.乘客險.誤餐費)</td>-->
          <!--          <td>總計</td>-->
          <!--          <td>10,500</td>-->
          <!--          <td>(<span class="color-blue">含</span>稅)</td>-->
          <!--        </tr>-->
          <!--        </tbody>-->
          <thead>
            <tr>
              <th v-for="(header, index) in bookingPreset.config
                .quotation_content_header" :key="'header-' + index" v-html="header.value && header.value.trim()
                  ? header.value
                  : header.text || ''
                  " :style="header.style" :colspan="header.colspan || 1"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td v-for="(content, index) in bookingPreset.config
                .quotation_content" :key="'content-' + index" :style="[content.style, tdStyles[index]]"
                :colspan="content.colspan || 1" v-html="content.value && content.value.trim()
                  ? content.value
                  : content.text || ''
                  "></td>
            </tr>
            <tr>
              <td v-for="(footer, index) in bookingPreset.config
                .quotation_content_footer" :key="'footer-' + index" :colspan="footer.colspan || 1"
                :style="footer.style" v-html="footer.value && footer.value.trim()
                  ? footer.value
                  : footer.text || ''
                  "></td>
            </tr>
          </tbody>
        </table>

        <footer v-html="getCleanedHtml(bookingPreset.config.quotation_footer)">
          <!--        <div class="row align-items-center" style="width: 100%;margin-bottom: 20px;">-->
          <!--          <div class="col-md-6">-->
          <!--            <p>金谷運通股份有限公司</p>-->
          <!--            <p>統一編號：47126655</p>-->
          <!--            <p>經辦人：盧郁潔</p>-->
          <!--            <p>電話：03-5313788</p>-->
          <!--            <p>傳真：03-5339149</p>-->
          <!--            <p>地址：300新竹市經國路二段100號8樓812室</p>-->
          <!--          </div>-->
          <!--          <div class="stamp col-md-6" style="text-align: center">-->
          <!--            <img src="stamp-placeholder.png" alt="公司印章">-->
          <!--          </div>-->
          <!--        </div>-->

          <!--        <div>-->
          <!--          <p>款項如匯款或支票付款，請參考下列資料：</p>-->
          <!--          <p>戶名：金谷通運股份有限公司</p>-->
          <!--          <p>帳號：313-10-059599</p>-->
          <!--          <p>銀行：第一銀行竹北分行</p>-->
          <!--          <p>銀行代號：007</p>-->
          <!--          <p>支票抬頭：金谷通運股份有限公司</p>-->
          <!--        </div>-->
        </footer>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import bookingApi from "@/apis/booking";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import _ from "lodash";
import { mapGetters } from "vuex";
import html2pdf from "html2pdf.js/src";

export default {
  components: {},
  setup: () => ({ v$: useVuelidate({ $scope: false }) }),
  data() {
    return {
      consts,
      zh,
      isEditing: false,
      showLoading: false,
      validationErrors: null,
      showAddStaff: false,
      step: "",
      isCompletedFormated: false,
      bookingPreset: {
        name: null,
        config: {
          fields: [],
          quotation_header: null,
          quotation_content_header: [
            // { text: "日期", style: "" },
            // { text: "序號", style: "" },
            // { text: "內容", style: "" },
            // { text: "數量", style: "" },
            // { text: "單位", style: "" },
            // { text: "單價", style: "" },
            // { text: "合計", style: "" },
            // { text: "備註", style: "" },
          ],
          quotation_content: [
            // { text: "4月8日", style: "color: cornflowerblue;" },
            // { text: "1", style: "color: cornflowerblue" },
            // {
            //   text: "08:40 新竹羅技電子 → 台威科技<br>13:30 台灣桃園 → 17:00 回程",
            //   style: "color: cornflowerblue",
            // },
            // { text: "1", style: "color: cornflowerblue" },
            // { text: "中巴", style: "color: cornflowerblue" },
            // { text: "10,500", style: "color: cornflowerblue" },
            // { text: "10,500", style: "" },
            // { text: "", style: "" },
          ],
          quotation_content_footer: [
            // {
            //   text: "此報價含(路.停車費.司機小費.乘客險.誤餐費)",
            //   style: "",
            //   colspan: 5,
            // },
            // { text: "總計", style: "" },
            // { text: "10,500", style: "" },
            // { text: "(含稅)", style: "" },
          ],
          quotation_footer: null,
        },
      },
      config: {},
      start_at: null,
      end_at: null,
      booking: {
        customer_id: null,
        service_id: [],
        staff_id: null,
        branch_id: null,
        start_at: null,
        end_at: null,
        data: [],
      },
      bookingFormatData: {},
      customer: null,
      disabledBranchSelect: false,
      tdStyles: [
        { whiteSpace: "nowrap" }, // 日期
        { whiteSpace: "nowrap" }, // 序號
        { wordBreak: "break-word" }, // 內容
        { whiteSpace: "nowrap" }, // 數量
        { whiteSpace: "nowrap" }, // 單位
        { whiteSpace: "nowrap" }, // 單價
        { whiteSpace: "nowrap" }, // 合計
        { whiteSpace: "nowrap" }, // 備註
      ]
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("route", ["routeQuery", "routeParams"]),
    ...mapGetters("general", ["myBranches"]),
  },
  methods: {
    escapeHtml(html) {
      return `<pre><code>${html}</code></pre>`;
    },
    deepGet: _.get,
    async generatePdf() {
      const element = document.getElementById("download-pdf"); // 選擇要匯出的區塊
      const serialNumber = this.bookingPreset.config.quotation_content[1].text
      const options = {
        filename: `${serialNumber || 'exported'}.pdf`, // PDF 檔名
        html2canvas: { scale: 2, useCORS: true }, // 提高解析度
        jsPDF: { unit: "in", format: "a4", orientation: "portrait" }, // PDF 格式
      };

      html2pdf().set(options).from(element).save();
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    isBookingCenter() {
      return this.$route.name.includes("BookingCenter");
    },
    async init() {
      this.showLoading = true;
      this.bookingPreset.id = this.$route.params.id;
      await this.fetchBookingPreset();
      this.isEditing = true;

      if (
        !this.checkPermission([
          this.consts.BOOKING_MANAGE_BRANCH,
          this.BOOKING_LIST_QUOTATION,
        ])
      ) {
        this.disabledBranchSelect = true;
      }
    },
    async fetchBookingPreset() {
      if (!this.bookingPreset.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        let response = await bookingApi.getBookingPreset(this.bookingPreset.id);

        if (!response.data.data.config) {
          response.data.data.config = {
            fields: [],
          };
        }

        this.bookingPreset = response.data.data;

        this.bookingPreset.config.fields = this.bookingPreset.config.fields.map(
          (field) => {
            return {
              id: field._id ?? field.config._id, // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
              ...field,
              ...field.config,
            };
          }
        );

        this.booking.id = this.$route.params.booking_id;
        await this.fetchBooking();
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    replaceTemplateVariables(text) {
      if (!text) return text;
      return text.replace(/\{\{(.*?)\}\}/g, (match, content) => {
        // 移除開頭的 $ 符號（如果有的話）
        const path = content.trim().replace(/^\$/, '');
        const value = this.deepGet(this.bookingFormatData, path);
        return value !== undefined ? value : '';
      });
    },
    async fetchBooking() {
      try {
        const response = await bookingApi.getBooking(
          this.bookingPreset.id,
          this.booking.id
        );
        this.booking = response.data;

        const formatResponse = await bookingApi.getBookingFormatData(
          this.bookingPreset.id,
          this.booking.id
        );
        this.bookingFormatData = formatResponse.data.data;

        // 檢查欄位是否存在
        const fieldsToCheck = [
          "quotation_content",
          "quotation_content_header",
          "quotation_content_footer",
        ];

        // 將booking裡面的data對應的value寫回bookingPreset.config
        fieldsToCheck.forEach((field) => {
          const fieldValue = this.bookingPreset.config[field];

          // 確認欄位是否存在且為陣列
          if (Array.isArray(fieldValue)) {
            fieldValue.forEach((item) => {
              if (item.text && item.text != 'null') {
                item.text = (this.replaceTemplateVariables(item.text) != 'null') ?
                  this.replaceTemplateVariables(item.text) :
                  '';
              } else {
                item.text = ''
              }
            });
          }
        });

        this.showLoading = false;
        this.isCompletedFormated = true;
      } catch (error) {
        this.showLoading = false;

        console.error(error);
        this.$swal("錯誤", "讀取錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    getCleanedHtml(text) {
      // 利用 textarea 解碼 HTML 實體字符
      const textarea = document.createElement('textarea');
      textarea.innerHTML = text;
      let decodedHtml = textarea.value;

      // 移除多餘的 &nbsp; 和 <br>（如果不需要）
      decodedHtml = decodedHtml.replace(/&nbsp;/g, ' '); // 替換掉 &nbsp;
      decodedHtml = decodedHtml.replace(/<br\s*\/?>/g, ''); // 移除 <br> 標籤

      // 移除多餘的空白字元（例如空格、換行符）
      decodedHtml = decodedHtml.replace(/\s+/g, ' ').trim(); // 替換所有空白字元並去除首尾的空白

      return decodedHtml;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-div {
  width: 100%;
}

.pdf-container {
  width: 793.701px;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

header {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.logo img {
  width: 100px;
  height: auto;
}

.company-info {
  margin-left: 20px;
}

.company-info h1,
.company-info h2 {
  margin: 0;
  font-size: 20px;
}

.quote-title {
  text-align: center;
  font-size: 24px;
  margin: 10px 0;
}

.color-blue {
  color: cornflowerblue;
}

.customer-info {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}

.quote-table {
  width: 100%;
  border-collapse: collapse;
}

.quote-table th,
.quote-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.quote-table th {
  background-color: #f4f4f4;
  word-break: keep-all;
}

.quote-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.total {
  text-align: right;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

footer {
  padding-top: 30px;
  text-align: left;
  margin-left: 30px;
}

.stamp img {
  width: 100px;
  height: auto;
  margin-top: 10px;
}

.img-fluid {
  width: 100px;
  height: 50px;
}

.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon-for-done {
    display: none;
  }

  &.done {
    i {
      color: green;
    }

    .icon-for-done {
      display: unset;
    }

    .icon-for-undone {
      display: none;
    }
  }
}
</style>
