var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.showLoading,"rounded":"sm"}},[_c('div',{staticClass:"row d-flex mb-4 mb-xl-2 justify-content-between"},[_c('h4',{staticClass:"col-12 col-xl-2 mb-1 mb-xl-0 font-weight-bold"},[_vm._v("訂單管理")]),(_vm.showFilter)?_c('div',{staticClass:"\n        col-12 col-xl-8\n        d-flex\n        flex-column flex-xl-row\n        align-items-start align-items-xl-center\n      "},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-upload",modifiers:{"modal-upload":true}}],staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-2",attrs:{"variant":"inverse-info"}},[_c('span',{staticClass:"mdi mdi-cloud-upload"}),_vm._v("匯入")]),_c('b-button',{staticClass:"flex-shrink-0 mb-2 mb-xl-0 mr-3 py-2",attrs:{"variant":"primary","to":{
          name: 'OrderCreate',
          query: { merchant_id: this.query.merchant_id },
        }}},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}}),_vm._v("新增訂單 ")]),_c('b-form-select',{attrs:{"disabled":_vm.merchants.length == 2,"options":_vm.merchants},on:{"change":_vm.handlePageReset},model:{value:(_vm.query.merchant_id),callback:function ($$v) {_vm.$set(_vm.query, "merchant_id", $$v)},expression:"query.merchant_id"}}),_c('b-input-group',{staticClass:"ml-0 ml-xl-4"},[_c('b-form-input',{attrs:{"placeholder":"訂單編號或外部編號"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handlePageReset.apply(null, arguments)}},model:{value:(_vm.query.keyword),callback:function ($$v) {_vm.$set(_vm.query, "keyword", $$v)},expression:"query.keyword"}}),_c('b-input-group-append',[_c('b-button',{on:{"click":_vm.handlePageReset}},[_c('i',{staticClass:"fa fa-search"})])],1)],1)],1):_vm._e()]),_c('b-table',{attrs:{"responsive":"","striped":"","hover":"","items":_vm.orders,"fields":_vm.filteredFields},on:{"sort-changed":_vm.handleSort},scopedSlots:_vm._u([{key:"cell(branch)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.branch ? data.item.branch.name / data.item.branch.id : "")+" ")]}},{key:"cell(order_number)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'OrderDetail',
          params: {
            order_id: data.item.id,
          },
        }}},[_vm._v(" "+_vm._s(data.item.order_number)+" ")])]}},{key:"cell(outer_order_number)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'OrderDetail',
          params: {
            order_id: data.item.id,
          },
        }}},[_vm._v(" "+_vm._s(data.item.outer_order_number)+" ")])]}},{key:"cell(customer_name)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.customer ? data.item.customer.name : "")+" ")]}},{key:"cell(action)",fn:function(data){return [(_vm.checkPermission([_vm.consts.ORDER_LIST_VIEW]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-primary","to":{
          name: 'OrderDetail',
          params: {
            order_id: data.item.id,
          },
        }}},[_c('span',{staticClass:"mdi mdi-eye mr-1"}),_vm._v("查看 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.ORDER_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":{
          name: 'OrderEdit',
          params: {
            order_id: data.item.id,
          },
        }}},[_c('span',{staticClass:"mdi mdi-lead-pencil mr-1"}),_vm._v("編輯 ")]):_vm._e(),(_vm.checkPermission([_vm.consts.ORDER_DELETE]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-danger"},on:{"click":function($event){return _vm.handleDelete(data.item.id)}}},[_c('span',{staticClass:"mdi mdi-delete mr-1"}),_vm._v("刪除 ")]):_vm._e()]}}])}),_c('CustomPagination',{attrs:{"currentPage":_vm.query.page,"totalRows":_vm.totalRows,"perPage":_vm.query.per_page},on:{"page-change":_vm.handlePageChange,"per-page-change":_vm.handlePerPageChange}}),_c('b-modal',{attrs:{"id":"modal-upload","title":"批次上傳"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
        var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-primary"},on:{"click":function($event){_vm.getSampleExcelFile(
            _vm.getModuleConfig('order', 'sample_file.order_import_xls'),
            '/excel/order_list.xlsx'
          )}}},[_c('i',{staticClass:"fa fa-file-text"}),_vm._v("下載範例檔案")]),_c('b-button',{staticClass:"float-right",attrs:{"variant":"outline-danger","size":"sm"},on:{"click":function($event){return cancel()}}},[_vm._v(" 關閉 ")])]}}])},[_c('b-overlay',{attrs:{"show":_vm.showLoadingUpload}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"flex-shrink-0 mr-2 mb-0"},[_vm._v("請上傳 Excel 檔案")]),_c('b-form-file',{attrs:{"plain":"","placeholder":"尚未選擇檔案","browse-text":"瀏覽"},on:{"input":_vm.uploadOrder}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }