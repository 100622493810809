<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>控制中心</b-breadcrumb-item>
            <b-breadcrumb-item active>分店管理</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2">
        <div class="col-12 col-xl-8 d-flex items-center">
          <h4 class="font-weight-bold">分店管理</h4>
        </div>
        <div class="col-12 col-xl-4">
          <b-input-group>
            <b-form-input
              placeholder="搜尋分店編號或店名"
              v-model="query.query"
              @keyup.enter="handlePageReset"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="handlePageReset"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <div
        class="d-flex flex-column flex-xl-row mt-4 mb-3 justify-content-between"
      >
        <b-breadcrumb class="mb-2 p-0" :items="breadcrumb"></b-breadcrumb>
        <div>
          <b-button
            v-if="$permissions.has($permissions.consts.BRANCH_IMPORT_TW_CITY) && $route.name !== 'Branches' && (activeBranch && activeBranch.level < 6)"
            variant="primary"
            :disabled="activeBranch && activeBranch.branch_type === 'shop'"
            @click="generateTwBranch"
          >
            <i class="fa fa-plus"></i>
            匯入台灣縣市
          </b-button>
          <b-button
            v-if="showCreateButton"
            variant="primary"
            class="ml-4"
            :disabled="activeBranch && activeBranch.branch_type === 'shop'"
            @click="handleUpload"
          >
            <i class="fa fa-plus"></i>
            {{ $route.name === "Branches" ? "上傳最上層分店" : "上傳分店" }}
          </b-button>
          <b-button
            v-if="showCreateButton"
            class="ml-4"
            variant="primary"
            :disabled="activeBranch && activeBranch.branch_type === 'shop'"
            @click="handleCreate"
          >
            <i class="fa fa-plus"></i>
            {{ $route.name === "Branches" ? "新增最上層分店" : "新增分店" }}
          </b-button>
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="branches"
        :fields="fields"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #cell(branch_code)="data">
          <router-link
            :to="{ name: 'BranchesShow', params: { branchID: data.item.id } }"
            >{{ data.item.branch_code }}</router-link
          >
        </template>
        <template #cell(is_enabled)="data">
          <b-badge
            :variant="
                !!data.item.is_enabled === true ? 'success' : 'secondary'
              "
            class="mr-1"
          >{{
              !!data.item.is_enabled === true ? "啟用" : "停用"
            }}</b-badge
          >
        </template>

        <template #cell(actions)="data">
          <b-button
            class="ml-2"
            v-if="$permissions.has($permissions.consts.BRANCH_MODIFY)"
            variant="inverse-success"
            :to="{
              name: 'BranchUpdateImage',
              params: {
                branchID: data.item.id,
              },
            }"
          >
            <span class="mdi mdi-image-multiple mr-1"></span>設定圖片
          </b-button>
          <b-button
            v-if="
              $permissions.hasAny([
                $permissions.consts.BRANCH_DETAIL_MODIFY_STAFF,
                $permissions.consts.BRANCH_DETAIL_DELETE_STAFF,
                $permissions.consts.BRANCH_DETAIL_CHANGE_PASSWORD,
              ])
            "
            class="ml-2"
            variant="inverse-dark"
            :to="{
              name: 'BranchStaffs',
              params: {
                branchID: data.item.id,
              },
            }"
          >
            <span class="mdi mdi-account-multiple mr-1"></span>店內帳號
          </b-button>
          <b-button
            class="ml-2"
            variant="inverse-info"
            v-if="!activeBranch || (activeBranch && activeBranch.level < 6)"
            :to="{
              name: 'BranchesSubLayer',
              params: {
                branchID: data.item.id,
              },
            }"
            :disabled="
              (activeBranch && activeBranch.branch_type === 'shop') ||
              data.item.branch_type === 'shop'
            "
          >
            <span class="mdi mdi-sitemap mr-1"></span>下層
          </b-button>
          <b-button
            class="ml-2"
            :to="{ name: 'BranchesShow', params: { branchID: data.item.id } }"
            variant="inverse-primary"
          >
            <span class="mdi mdi-eye mr-1"></span>查看
          </b-button>
          <b-button
            class="ml-2"
            v-if="$permissions.has($permissions.consts.BRANCH_MODIFY)"
            variant="inverse-warning"
            :to="{
              name: 'BranchesEdit',
              params: {
                branchID: data.item.id,
              },
            }"
          >
            <span class="mdi mdi-lead-pencil mr-1"></span>編輯
          </b-button>
          <b-button
            v-if="$permissions.has($permissions.consts.BRANCH_DELETE)"
            class="ml-2"
            variant="inverse-danger"
            @click="
              handleDelete(data.item.id, data.item.branch_code, data.item.name)
            "
          >
            <span class="mdi mdi-delete mr-1"></span>刪除
          </b-button>
          <b-button
            v-if="$permissions.has($permissions.consts.BRANCH_MODIFY) && data.item.is_enabled"
            class="ml-2"
            variant="inverse-danger"
            @click="
              handleUpdateEnabled(data.item.id, data.item, false)
            "
          >
            <span class="mdi mdi-close-circle mr-1"></span>停用
          </b-button>
          <b-button
            v-if="$permissions.has($permissions.consts.BRANCH_MODIFY) && !data.item.is_enabled"
            class="ml-2"
            variant="inverse-success"
            @click="
              handleUpdateEnabled(data.item.id, data.item, true)
            "
          >
            <span class="mdi mdi-check-circle mr-1"></span>啟用
          </b-button>
        </template>
      </b-table>

      <CustomPagination
        :currentPage="query.page"
        :totalRows="total"
        :perPage="query.per_page"
        @page-change="handlePageChange"
        @per-page-change="handlePerPageChange"
      />
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import branchApi from "../../../apis/branch";
import * as consts from "@/consts";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { paginationMixin } from "@/mixins/pagination";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";

export default {
  components: { CustomPagination },
  mixins: [paginationMixin],
  data() {
    return {
      consts,
      isLoading: false,
      search: "",
      branches: [],
      activeBranch: null,
      fields: [
        {
          key: "order",
          label: "順序",
        },
        {
          key: "branch_type",
          label: "類型",
          formatter: (value) => {
            return value === "shop" ? "門市" : "分區";
          },
        },
        {
          key: "name",
          label: "名稱",
        },
        {
          key: "branch_code",
          label: "編號代碼",
        },
        {
          key: "is_enabled",
          label: "狀態",
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      total: 0,
      initialized: false,
      query: {
        page: 1,
        per_page: 10,
        query: "",
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    breadcrumb() {
      const root = {
        text: "最上層",
        to: {
          name: "Branches",
        },
      };

      const parents =
        this.activeBranch?.parents.map((parent) => {
          return {
            text: parent.parent_name,
            to: {
              name: "BranchesSubLayer",
              params: { branchID: parent.parent_id },
            },
          };
        }) ?? [];

      const active = {
        text: this.activeBranch?.name,
        href: "#",
        active: true,
      };

      return this.activeBranch ? [root, ...parents, active] : [root, active];
    },
    branchID() {
      return this.$route.params.branchID;
    },
    showCreateButton() {
      if (this.$route.name === "Branches") {
        return this.$permissions.has(
          this.$permissions.consts.BRANCH_TOP_CREATE
        );
      }
      return this.$permissions.has(this.$permissions.consts.BRANCH_MODIFY);
    },
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  mounted() {
    this.fetchBranches();

    if (this.branchID) {
      this.fetchActiveBranch();
    }
    this.initialized = true;
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      const defaultQuery = { page: 1, per_page: this.query.per_page || 10 };
      this.query = { ...defaultQuery, ...to.query };
      updateQueryFromRoute(to, this.$store, this.query);
      this.fetchBranches();
      if (this.branchID) {
        this.fetchActiveBranch();
      }
    },
  },
  methods: {
    async fetchBranches() {
      try {
        this.isLoading = true;
        // only_my_branch 會拉此會員第一層的分店，有兩個情境下會是 false
        // - 如果已經有明確指定要拉的子店時(this.$route.params.branchID 有值時)
        // - 如果有 BRANCH_TOP_VIEW 顯示最上層的權限，就不會只看會員的第一層，而是要看最上層的分店
        const { data } = await branchApi.getBranches(this.organization, {
          only_my_branch:
            !this.$route.params.branchID &&
            !this.$permissions.has(this.$permissions.consts.BRANCH_TOP_VIEW),
          parent_id: this.$route.params.branchID || "",
          ...this.query,
        });
        this.total = data.meta.total;
        this.branches = data.data;
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },

    async fetchActiveBranch() {
      try {
        const { data } = await branchApi.getBranch(this.branchID);

        this.activeBranch = data.data;
      } catch (error) {
        console.log("");
      }
    },

    async handleDelete(branchID, branchCode, branchName) {
      this.$swal
        .fire({
          type: "warning",
          title: `您確定要刪除 ${branchName} 與所有下層分店嗎？`,
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>店名：${branchName}</div>
                <div>分店編號：${branchCode}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除全部",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await branchApi.deleteBranch(branchID);
              await this.fetchBranches();
            } catch (error) {
              console.log("");
            }
            this.isLoading = false;
          }
        });
    },

    handleUpload() {
      if (this.activeBranch) {
        this.$router.push({
          name: "BranchesImport",
          params: {
            branchID: this.branchID,
          },
        });
      } else {
        this.$router.push({
          name: "BranchesRootImport",
        });
      }
    },
    async handleUpdateEnabled(id, branch, flag) {
      this.$swal.fire({
        title: `您確定要${flag ? '啟用' : '停用' } ${branch.name} 與所有下層分店嗎？`,
        type: "warning",
        html: `
          <div class="d-block">
            <div class="my-3">
              <div>店名：${branch.name}</div>
              <div>分店編號：${branch.branch_code}</div>
            </div>
          </div>`,
        confirmButtonText: `確認${flag ? '啟用' : '停用' }全部`,
        cancelButtonText: `不${flag ? '啟用' : '停用' }`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.isLoading = true;
            await branchApi.changeBranchEnabled(id, {
              is_enabled: flag,
            }).then(() => {
              this.fetchBranches();
            });
            this.isLoading = false;
          } catch (error) {
            console.log(error)
          }
        }
      });
    },
    async generateTwBranch() {
      this.$swal.fire({
        title: `是否匯入台灣縣市至 ${this.activeBranch.name}？`,
        type: "warning",
        confirmButtonText: "是",
        cancelButtonText: "否",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          try {
            this.isLoading = true;
            await branchApi.generateTwBranches(this.branchID, {})
              .then(() => {
                this.fetchBranches();
              });
            this.isLoading = false;
          } catch (error) {
            console.log(error)
          }
        }
      });
    },
    handleCreate() {
      if (this.activeBranch) {
        this.$router.push({
          name: "BranchesCreate",
          params: {
            branchID: this.branchID,
          },
        });
      } else {
        this.$router.push({
          name: "BranchesRootCreate",
        });
      }
    },
  },
};
</script>
