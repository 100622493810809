import https from "./https";

const bodyshop = {
  searchMember(orgId, params) {
    if (!orgId) {
      return Promise.reject("id not found.");
    }
    const searchParams = new URLSearchParams(params);

    return https.get(`/admin/organizations/${orgId}/elc?${searchParams}`);
  },
};

export default bodyshop
