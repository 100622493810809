import https from "./https";
import store from "@/store";

const point = {
  async getAvailablePoints(eventCode) {
    const organization = store.state.liffGeneral.orgCode

    let response
    if (eventCode) {
      response = await https.get(`${organization}/liff/loyalty-programs/events/${eventCode}/available-points`)
    } else {
      response = await https.get(`${organization}/liff/loyalty-programs/available-points`)
    }
    return response.data.data
  },

  async getExpiringSoonPoints(eventCode) {
    const organization = store.state.liffGeneral.orgCode

    let response
    if (eventCode) {
      response = await https.get(`${organization}/liff/loyalty-programs/events/${eventCode}/expiring-soon-points`)
    } else {
      response = await https.get(`${organization}/liff/loyalty-programs/expiring-soon-points`)
    }
    return response.data.data
  },

  async getUsedPoints(eventCode) {
    const organization = store.state.liffGeneral.orgCode

    let response
    if (eventCode) {
      response = await https.get(`${organization}/liff/loyalty-programs/events/${eventCode}/used-points`)
    } else {
      response = await https.get(`${organization}/liff/loyalty-programs/used-points`)
    }
    return response.data.data
  },

  async listGifts(eventCode, page) {
    const organization = store.state.liffGeneral.orgCode

    let response
    if (eventCode) {
      response = await https.get(`${organization}/liff/loyalty-programs/events/${eventCode}/gifts?page=${page}`)
    } else {
      response = await https.get(`${organization}/liff/loyalty-programs/gifts?page=${page}`)
    }
    return response.data
  },

  async redeemGift(eventCode, gift) {
    const organization = store.state.liffGeneral.orgCode

    if (eventCode) {
      await https.put(`${organization}/liff/loyalty-programs/events/${eventCode}/gifts/${gift.id}/_redeem`)
    } else {
      await https.put(`${organization}/liff/loyalty-programs/gifts/${gift.id}/_redeem`)
    }
    return true
  },

  async listRedeemLogs(eventCode, page) {
    const organization = store.state.liffGeneral.orgCode
    let response
    if (eventCode) {
      response = await https.get(`${organization}/liff/loyalty-programs/events/${eventCode}/redeem-logs?page=${page}`)
    } else {
      response = await https.get(`${organization}/liff/loyalty-programs/redeem-logs?page=${page}`)
    }
    return response.data
  },

  async listPointsLogs(eventCode, page) {
    const organization = store.state.liffGeneral.orgCode
    let response
    if (eventCode) {
      response = await https.get(`${organization}/liff/loyalty-programs/events/${eventCode}/points-logs?page=${page}`)
    } else {
      response = await https.get(`${organization}/liff/loyalty-programs/points-logs?page=${page}`)
    }
    return response.data
  },

  async listSponsors() {
    const organization = store.state.liffGeneral.orgCode
    let response = await https.get(`${organization}/liff/loyalty-programs/sponsors`)
    return response.data
  },
  async transferPoints(uuid, points) {
    const organization = store.state.liffGeneral.orgCode
    let response = await https.put(`${organization}/liff/loyalty-programs/transfer`, {
      sponsor_uuid: uuid,
      points
    })
    return response.data
  },
  async getLoyaltyLevel() {
    const organization = store.state.liffGeneral.orgCode
    let response = await https.get(`${organization}/liff/loyalty-programs/loyalty-level`)
    return response.data
  },
}

export default point
