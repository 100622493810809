<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>內容管理中心</b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'CollectionList' }" active>
              資料集管理
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-8 mb-2 font-weight-bold">資料集管理</h4>
        <b-input-group class="col-12 col-xl-4 mb-2">
          <b-form-input v-model="keyword" placeholder="搜尋"></b-form-input>
          <b-input-group-append>
            <b-button @click="fetchCollections">
              <i class="fa fa-search"></i>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.COLLECTION_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0"
            variant="primary"
            :to="{ name: 'CollectionCreate' }"
            ><i class="fa fa-plus"></i>新增資料表</b-button
          >
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="collections"
                :fields="fields"
              >
                <template #cell(is_enabled)="data">
                  <b-badge
                    v-if="data.item.is_enabled == true"
                    variant="success"
                  >
                    啟用
                  </b-badge>
                  <b-badge
                    v-if="data.item.is_enabled == false"
                    variant="secondary"
                  >
                    停用
                  </b-badge>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.COLLECTION_ITEM_MANAGE])"
                    variant="inverse-success"
                    :to="{
                      name: 'CollectionItemList',
                      params: { id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 項目管理
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.COLLECTION_EDIT])"
                    variant="inverse-warning"
                    :to="{
                      name: 'CollectionEdit',
                      params: { id: data.item.id },
                    }"
                  >
                    <span class="mdi mdi-lead-pencil"></span> 編輯
                  </b-button>
                  <b-button
                    class="mr-1"
                    v-if="checkPermission([consts.COLLECTION_DELETE])"
                    variant="inverse-danger"
                    @click="deleteCollection(data.item)"
                  >
                    <span class="mdi mdi-delete"></span> 刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            @change="fetchCollections"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import { format } from "date-fns";
import collectionApi from "@/apis/collection";
import { mapState } from "vuex";

export default {
  data() {
    return {
      consts: consts,
      collections: [],
      keyword: "",
      showLoading: false,
      fields: [
        { key: "name", label: "資料集名稱", sortable: true },
        { key: "code", label: "資料集代碼", sortable: true },
        { key: "items_count", label: "目前資料筆數", sortable: true },
        {
          key: "created_at",
          label: "建立時間",
          sortable: true,
          formatter: (value) => {
            return format(new Date(value), "yyyy-MM-dd HH:mm:ss");
          },
        },
        { key: "is_enabled", label: "啟用", sortable: true },
        { key: "actions", label: "管理" },
      ],
      currentPage: 1,
      perPage: 20,
      totalRows: 0,
      formUrl: null,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  mounted() {
    this.fetchCollections();
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    async fetchCollections() {
      try {
        this.showLoading = true;
        let response = await collectionApi.getCollections({
          keyword: this.keyword,
          per_page: this.perPage,
          page: this.currentPage,
        });
        this.collections = response.data.data;
        this.totalRows = response.data.data.total;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取表單列表失敗", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async deleteCollection(collection) {
      try {
        this.showLoading = true;
        const result = await this.$swal({
          type: "warning",
          title: "您確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>刪除項目：${collection.name}</div>
              </div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        });

        if (!result.value) {
          return;
        }

        await collectionApi.deleteCollection(collection.id);
        this.$swal("刪除成功", "", "success");
        await this.fetchCollections();
      } catch (error) {
        console.error(error);
        this.$swal("刪除失敗", "", "error");
      } finally {
        this.showLoading = false;
      }
    },
  },
};
</script>
