<template>
  <div>
    <!-- Page Header Start -->
    <div class="page-title-header">
      <div class="page-header">
        <b-breadcrumb class="m-0">
          <b-breadcrumb-item
            :to="{
              name: 'DashboardHome',
            }"
          >
            <i class="fa fa-home"></i>
          </b-breadcrumb-item>
          <b-breadcrumb-item>
            內容管理中心
          </b-breadcrumb-item>

          <b-breadcrumb-item :to="{ name: 'CollectionList' }">
            資料集管理
          </b-breadcrumb-item>

          <b-breadcrumb-item v-if="collection.name" :to="{ name: 'CollectionItemList', params: { id: collection.id }}">
            {{ collection.name }}
          </b-breadcrumb-item>

          <b-breadcrumb-item active
            >{{
              isEditing ? '編輯項目' : '新增項目'
            }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </div>
    </div>
    <!-- Page Header End -->

    <!-- Form Start -->
    <div class="email-wrapper wrapper">
      <div class="row bg-white">
        <div class="col-md-12">
          <b-overlay :show="showLoading">
            <b-card>
              <!-- begin: dynamic form -->
              <div>
                <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">{{ isEditing ? "編輯項目" : "新增項目" }} - {{ collection.name }}</h4>
                <div class="col-12 col-xl-8 mt-3">
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="分類"
                  >
                    <b-form-input
                      type="text"
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="item.type"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="排序"
                  >
                    <b-form-input
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="item.order"
                      type="number"
                      min="0"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="是否啟用"
                  >
                    <b-form-checkbox
                      class="mb-2 mr-sm-2 mb-sm-0"
                      v-model="item.is_enabled"
                    ></b-form-checkbox>
                  </b-form-group>

                  <b-form-group
                    v-if="isBranchVisible"
                    label-cols="12"
                    label-cols-lg="2"
                    label-size="sm"
                    label="分店">
                    <b-form-select
                      v-model="item.branch_id"
                      :options="branchOptions"
                      :disabled="disabledBranchSelect"
                    ></b-form-select>
                  </b-form-group>

                  <DynamicFormDashboard
                    ref="dynamicForm"
                    :input-subjects="collection.config.fields"
                    v-model="inputForm"
                  ></DynamicFormDashboard>
                </div>
                <!-- 表單底部 Start -->
                <div class="d-flex justify-content-center mt-4">
                  <b-button
                    class="mr-3"
                    @click="cancel"
                    variant="outline-danger"
                  >
                    返回
                  </b-button>
                  <b-button
                    @click="submit"
                    variant="success"
                  >
                    儲存
                  </b-button>
                </div>
                <!-- 表單底部 End -->
              </div>
              <!-- end: dynamic form -->
            </b-card>
          </b-overlay>
        </div>
      </div>
    </div>
    <!-- Form End -->

  </div>
</template>

<script>
import { zh } from "vuejs-datepicker/dist/locale";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import collectionApi from "@/apis/collection";
import DynamicFormDashboard from "@/components/DynamicForm/DynamicFormDashboard"
import {mapGetters} from "vuex";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";

export default {
  components: {
    DynamicFormDashboard,
  },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      collection: {
        name: { required },
        code: { required },
      },
    };
  },
  data() {
    return {
      zh,
      isEditing: false,
      showLoading: false,
      isBranchVisible: false,
      disabledBranchSelect: true,
      validationErrors: null,
      step: '',
      collection: {
        name: null,
        code: null,
        description: null,
        config: {
          fields: [],
        }
      },
      inputForm: {},
      item: {
        order: 0,
        is_enabled: true,
        branch_id: null,
        data: []
      },
    }
  },
  computed: {
    ...mapGetters('general', [
      'myBranches',
      'currentBranch'
    ]),
    branchOptions() {
      let options = this.myBranches
        .map(branch => {
          let branchName = `${branch.branch_code} ${branch.name}`
          return {
            value: branch.id,
            text: branchName
          }
        })
      options = [
        {
          value: null,
          text: '無分店'
        },
        ...options
      ]

      if (!options.find((o) => o.value == this.item.branch_id)) {
        options.push({
          value: this.item.branch_id,
          text: this.item.branch.name
        })
      }
      return options
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init () {
      this.collection.id = this.$route.params.id;
      await this.fetchCollection();
      if (this.$route.name === "CollectionItemEdit") {
        this.isEditing = true;
        this.item.id = this.$route.params.item_id;
        await this.fetchItem();
      } else {
        this.item.order = this.collection.items.reduce((max, item) => {
          return item.order > max ? item.order : max;
        }, 0) + 1;
        this.isEditing = false;

        if (this.collection.config.related_branch && this.currentBranch) {
          this.item.branch_id = this.currentBranch.id;
        }
      }

      if (this.collection.config.related_branch) {
        this.isBranchVisible = true;
        if (this.checkPermission([consts.COLLECTION_ITEM_MANAGE_BRANCH])) {
          this.disabledBranchSelect = false;
        }
      }
    },
    cancel() {
      this.$router.push({ name: 'CollectionItemList', params: {id: this.collection.id} });
    },
    async submit() {
      const validate = await this.$refs.dynamicForm.validate();
      if (!validate) return;

      try {
        this.showLoading = true;

        // 將 item.data 組起來
        let data = [];

        for (let i = 0; i < this.collection.config.fields.length; i ++) {
          const field = this.collection.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦

          data.push({
            field_id: fieldId,
            title: field.title ?? field.config.title,
            value: this.inputForm[fieldId],
          });
        }

        // 編輯表單
        if (this.isEditing) {
          await collectionApi.upadteItem(this.collection.id, this.item.id, {
            data,
            is_enabled : this.item.is_enabled,
            order: this.item.order,
            type: this.item.type,
            branch_id: this.item.branch_id,
          })
          this.$swal("儲存成功", "", "success");
        }
        // 新增表單
        else {
          await collectionApi.createItem(this.collection.id, {
            data,
            is_enabled : this.item.is_enabled,
            order: this.item.order,
            branch_id: this.item.branch_id,
            type: this.item.type
          });
          this.$swal("新增成功", "", "success");
          this.cancel();
        }
        return true;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "儲存失敗", "error");
        return false;
      } finally {
        this.showLoading = false;
      }
    },
    async fetchCollection() {
      if (! this.collection.id) {
        this.$swal("錯誤", "無法取得讀取 ID", "error");
        return;
      }

      try {
        this.showLoading = true;
        let response = await collectionApi.getCollection(this.collection.id);

        if (! response.data.data.config) {
          response.data.data.config = {
            fields: [],
          };
        }

        this.collection = response.data.data;

        if (!this.collection.config?.fields || this.collection.config.fields.length === 0) {
          this.$swal("提醒", "此資料集尚未設定欄位，須設定欄位後才能新增及編輯項目", "warning");
          this.$router.push({
            name: "CollectionList",
          });
          return;
        }

        this.collection.config.fields = this.collection.config.fields.map((field) => {
          return {
            id: field._id ?? field.config._id, // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
            ...field,
          }
        });

        let editInputForm = {};

        for (let i = 0; i < this.collection.config.fields.length; i ++) {
          const field = this.collection.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
          editInputForm[fieldId] = null;
        }
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取資料錯誤", "error");
        this.cancel();
      } finally {
        this.showLoading = false;
      }
    },
    async fetchItem() {
      try {
        this.showLoading = true;
        const response = await collectionApi.getItem(this.collection.id, this.item.id);
        this.item = response.data.data;

        let editInputForm = {};

        for (let i = 0; i < this.collection.config.fields.length; i ++) {
          const field = this.collection.config.fields[i];
          const fieldId = field._id ?? field.config._id; // 這裡是為了因應新版 DynamicFormEditor 變動而捕的防禦
          editInputForm[fieldId] = this.item.data.find((data) => data.field_id === fieldId)?.value ?? null;
        }

        this.inputForm = editInputForm;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取錯誤", "error");
      } finally {
        this.showLoading = false;
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  }
}
</script>

<style lang="scss" scoped>
.menu-items > li {
  cursor: pointer;

  &.disabled {
    background-color: #ddd;
    opacity: 0.5;
    pointer-events: none;
  }

  .icon-for-done {
    display: none;
  }

  &.done {
    i {
      color: green;
    }

    .icon-for-done {
      display: unset;
    }

    .icon-for-undone {
      display: none;
    }
  }
}
</style>
