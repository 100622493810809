<template>
  <div class="page">
    <div class="page__title">
      {{ getModuleConfig("redeem_success_page.form_title") }}
    </div>
    <div
      class="page__desc"
      v-html="getModuleConfig('redeem_success_page.form_desc')"
    ></div>

    <div>
      <Loading v-if="showLoading" loading-message="載入中..." />
      <div v-else-if="!showLoading">
        <div class="coupon">
          <div class="coupon--barcode s-mb-4" v-if="qrcode1">
            <p v-if="showBarcodeError1" class="s-text-danger">
              barcode 產生錯誤，請聯絡相關人員
            </p>
            <img
              v-else
              :id="barcodeImgIdOne"
              src="@/pages/Dashboard/Coupon/barcode.png"
            />
          </div>
          <div class="coupon--barcode s-mb-4" v-if="qrcode2">
            <p v-if="showBarcodeError2" class="s-text-danger">
              barcode 產生錯誤，請聯絡相關人員
            </p>
            <img
              v-else
              :id="barcodeImgIdTwo"
              src="@/pages/Dashboard/Coupon/barcode.png"
            />
          </div>
          <div
            v-html="getModuleConfig('redeem_success_page.content')"
            class="p-4 shadow-lg rounded redeem--content"
          ></div>
        </div>
      </div>
    </div>

    <div
      v-if="
        getModuleConfig('redeem_success_page.button_text') ||
        getModuleConfig('redeem_success_page.cancel_button_text')
      "
      class="p-3 fixed-bottom bg-white footer"
    >
      <MemberButton
        :button-text="getModuleConfig('redeem_success_page.button_text')"
        :button-url="getModuleConfig('redeem_success_page.button_url')"
        :type="getModuleConfig('redeem_success_page.button_action')"
      ></MemberButton>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Page/Liff/Shared/Loading";
import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
import couponMixin from "@/mixins/liff/coupon";
import bwipjs from "bwip-js";
import { mapGetters } from "vuex";

export default {
  mixins: [couponMixin],
  components: { Loading, MemberButton },
  data() {
    return {
      showLoading: false,
      showBarcodeError1: false,
      showBarcodeError2: false,
      canvasElement: null,
      qrcode1: this.$route.query.qrcode1,
      qrcode2: this.$route.query.qrcode2,
    };
  },

  computed: {
    barcodeImgIdOne() {
      return `coupon-barcode-${this.qrcode1}`;
    },
    barcodeImgIdTwo() {
      return `coupon-barcode-${this.qrcode2}`;
    },
    ...mapGetters({
      thanksMessage: "projAbbottAnd/thanksMessage",
    }),
    messages() {
      return this.thanksMessage ?? '';
    },
  },
  mounted() {
    if (this.qrcode1) {
      this.generateBarcodeOne();
    }
    if (this.qrcode2) {
      this.generateBarcodeTwo();
    }
  },

  methods: {
    generateBarcodeOne() {
      if (!this.canvasElement) {
        this.canvasElement = document.createElement("canvas");
      }

      try {
        bwipjs.toCanvas(this.canvasElement, {
          bcid: "code128", // Barcode type
          text: this.qrcode1,
          scale: 2, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: "center", // Always good to set this
        });

        let barcodeDom = document.getElementById(this.barcodeImgIdOne);
        if (!barcodeDom || !this.barcodeImgIdOne) {
          this.showBarcodeError1 = true;
          return;
        }

        barcodeDom.src = this.canvasElement.toDataURL("image/png");
        this.showBarcodeError1 = false;
      } catch (e) {
        console.log(e);
        this.showBarcodeError1 = true;
      }
    },
    generateBarcodeTwo() {
      if (!this.canvasElement) {
        this.canvasElement = document.createElement("canvas");
      }

      try {
        bwipjs.toCanvas(this.canvasElement, {
          bcid: "code128", // Barcode type
          text: this.qrcode2,
          scale: 2, // 3x scaling factor
          height: 10, // Bar height, in millimeters
          includetext: true, // Show human-readable text
          textxalign: "center", // Always good to set this
        });

        let barcodeDom = document.getElementById(this.barcodeImgIdTwo);

        if (!barcodeDom || !this.barcodeImgIdTwo) {
          this.showBarcodeError2 = true;
          return;
        }

        barcodeDom.src = this.canvasElement.toDataURL("image/png");
        this.showBarcodeError2 = false;
      } catch (e) {
        console.log(e);
        this.showBarcodeError2 = true;
      }
    },
  },
};
</script>

<style lang="scss">
.redeem {
  &--content {
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.coupon {
  display: flex;
  flex-direction: column;
  text-align: center;

  &--barcode {
    max-width: 100%;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
  }
}
</style>
