<template>
  <b-modal
    id="modal-search-customer-level"
    title="選擇會員等級"
    @show="initializeTempCustomerLevels"
    no-close-on-backdrop
  >
    <b-input-group>
      <b-form-input placeholder="搜尋名字、描述" v-model="query.keyword" @keyup.enter="getCustomerLevelsList"></b-form-input>
      <b-input-group-append>
        <b-button @click="getCustomerLevelsList"><i class="fa fa-search"></i></b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="text-center mt-3 mb-3 loading-spinner" v-if="isLoading">
      <b-spinner variant="secondary" small />
    </div>
    <div class="mt-3 mb-3" v-else-if="customerLevels.length > 0">
      <div
        v-for="customerLevel in customerLevels"
        v-bind:key="customerLevel.id"
        class="d-flex align-items-center py-2 customerLevel-item"
        @click="handleSelected(customerLevel)"
      >
        <div class="mr-auto text-nowrap text-truncate ml-2">
          <div class="font-weight-bold">
            {{ customerLevel.name }}
          </div>
          <div>
            {{ customerLevel.description}}
          </div>
          <small class="text-muted">
            {{ format(new Date(customerLevel.start_at), 'yyyy-MM-dd HH:mm') }} ~ {{ format(new Date(customerLevel.end_at), 'yyyy-MM-dd HH:mm') }}
          </small>
        </div>
        <div class="mx-2">
          <span v-if="selectedCustomerLevelsIdList.includes(customerLevel.id)" class="mdi mdi-check-circle mdi-24px" />
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <div class="float-right">
          <span
            class="mr-2 mdi mdi-chevron-left mdi-36px"
            :class="meta.current_page == 1 ? 'text-muted': ''"
            @click="prevPage">
          </span>
          <span
            class="mr-5 mdi mdi-chevron-right mdi-36px"
            :class="meta.current_page == meta.last_page ? 'text-muted': ''"
            @click="nextPage">
          </span>

          <b-button
            variant="danger"
            size="sm"
            class="mr-2 ml-5"
            @click="closeModal"
          >
            取消
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            @click="submitSelectedCustomerLevels"
          >
            確定
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { format } from "date-fns";
  import customerLevelApi from "@/apis/customer-levels";

  export default {
    props: {
      selectedCustomerLevels: {
        type: Array,
      },
    },

    computed: {
      selectedCustomerLevelsIdList() {
        return this.tempSelectedCustomerLevels?.map((customerLevel) => customerLevel.id) ?? []
      }
    },
    data() {
      return {
        isLoading: false,
        customerLevels: [],
        // 暫時放選取的 customerLevel，確定才 submit
        tempSelectedCustomerLevels: [],
        // 暫時放選取的 customerLevel，取消要回復用
        tempCustomerLevels: [],
        meta: {},
        query: {
          page: 1,
          per_page: 10,
          is_enabled: true,
          is_processing: true,
          keyword: "",
        },
      }
    },
    watch: {
      "query.keyword"() {
        this.query.page = 1
      },
      selectedCustomerLevels() {
        this.tempSelectedCustomerLevels = [...this.selectedCustomerLevels]
      },
    },
    created() {
      this.getCustomerLevelsList()
    },
    methods: {
      format,
      async getCustomerLevelsList() {
        try {
          this.isLoading = true;

          const { data } = await customerLevelApi.list({
            ...this.query,
          });

          this.customerLevels = data.data;
          this.totalRows      = data.meta.total;
          this.meta           = data.meta;
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;
        }
      },
      initializeTempCustomerLevels() {
        this.tempSelectedCustomerLevels = [...this.selectedCustomerLevels]; // 以父層傳入的已選優惠券初始化
        this.tempCustomerLevels = [...this.selectedCustomerLevels];        // 保存取消時的還原基準
      },
      handleSelected(customerLevel) {
        const index = this.tempSelectedCustomerLevels.findIndex(({ id }) => id === customerLevel.id);

        if (index === -1) {
          if (this.tempSelectedCustomerLevels.length >= 10) {
            return
          }
          this.tempSelectedCustomerLevels.push(customerLevel);
        } else {
          this.tempSelectedCustomerLevels.splice(index, 1);
        }
      },
      submitSelectedCustomerLevels() {
        this.$bvModal.hide('modal-search-customer-level');
        this.$emit('submit', this.tempSelectedCustomerLevels);
      },
      closeModal() {
        this.$bvModal.hide('modal-search-customer-level');
        this.tempSelectedCustomerLevels = this.tempCustomerLevels;
      },
      prevPage() {
        if (this.meta.current_page == 1) {
          return
        }
        this.query.page -= 1;
        this.getCustomerLevelsList();
      },
      nextPage() {
        if (this.meta.current_page == this.meta.last_page) {
          return
        }
        this.query.page += 1;
        this.getCustomerLevelsList();
      }
    }
  }
</script>

<style lang="scss">
.customerLevel-item:hover {
  cursor: pointer;
  background-color: #c7c7c7;
  color: white;
  width: 100%;

  .staff-list-info {
    color: white;
  }

}
</style>
