<template>
  <b-form-group
    label-cols="12"
    label-cols-lg="2"
    label-size="sm"
    :label="label"
  >
    <div v-html="name"></div>
  </b-form-group>
</template>

<script>
export default {
  name: "BookingPresetName",
  props: {
    name: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
