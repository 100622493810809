<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'AutoMarketingMemberEvent',
              }"
              >會員事件設定</b-breadcrumb-item
            >
            <b-breadcrumb-item active>{{ title }} </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <div class="email-wrapper wrapper">
      <div class="row align-items-stretch">
        <div class="mail-sidebar d-none d-lg-block col-md-2 pt-3 bg-white">
          <div class="menu-bar">
            <ul class="menu-items">
              <li :class="step == '' ? 'active' : ''" @click="goToStep('')">
                <a>基本設定</a>
                <i class="fa fa-check-circle-o" aria-hidden="true"></i>
              </li>
              <li
                :class="step == '#trigger' ? 'active' : ''"
                @click="goToStep('#trigger')"
              >
                <a>觸發時間</a>
                <i class="fa fa-check-circle-o" aria-hidden="true"></i>
              </li>
              <li
                :class="step == '#event' ? 'active' : ''"
                @click="goToStep('#event')"
              >
                <a>事件內容</a>
                <i class="fa fa-check-circle-o" aria-hidden="true"></i>
              </li>
            </ul>
          </div>
        </div>
        <div class="mail-view d-none d-md-block col-md-9 col-lg-10 bg-white">
          <b-card>
            <!-- 基本設定 -->
            <section class="mb-5" v-if="step == ''">
              <h4 class="mb-2 font-weight-bold">基本設定</h4>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="通路"
                label-for="merchant"
              >
                <b-form-select
                  v-model="form.merchant_id"
                  :options="merchants"
                  :disabled="isFetchMerchants || isReadOnly"
                ></b-form-select>
                <b-form-invalid-feedback :state="!v$.form.merchant_id.$error"
                  >此欄位為必填</b-form-invalid-feedback
                >
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="標題"
              >
                <b-form-input
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  :state="v$.form.title.$error ? false : null"
                  v-model="form.title"
                  :readonly="isReadOnly"
                ></b-form-input>
                <b-form-invalid-feedback :state="!v$.form.title.$error">
                  此欄位為必填
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="使用期間"
              >
                <div
                  class="
                    d-flex
                    flex-column flex-xl-row
                    align-items-start align-items-xl-center
                  "
                >
                  <datepicker
                    placeholder="選擇日期"
                    v-model="form.start_at"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :disabled="isReadOnly"
                    :disabledDates="disabledDates"
                    clear-button
                    :input-class="v$.form.start_at.$error ? 'is-invalid' : ''"
                  ></datepicker>
                  <span class="mx-2">~</span>
                  <datepicker
                    placeholder="選擇日期"
                    v-model="form.end_at"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :disabled="isReadOnly"
                    :disabledDates="disabledDates"
                    clear-button
                    :input-class="v$.form.end_at.$error ? 'is-invalid' : ''"
                  ></datepicker>
                </div>
                <b-form-invalid-feedback
                  :state="!v$.form.start_at.beforeEndAt.$invalid"
                >
                  結束時間必須大於起始時間
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="類型"
              >
                <b-form-input
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  :state="v$.form.type.$error ? false : null"
                  v-model="form.type"
                  :readonly="isReadOnly"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="!v$.form.type.$error"
                >
                  此欄位為必填
                </b-form-invalid-feedback>
              </b-form-group> -->
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="是否啟用"
              >
                <b-form-checkbox
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  :state="v$.form.is_enabled.$error ? false : null"
                  v-model="form.is_enabled"
                  :readonly="isReadOnly"
                ></b-form-checkbox>
                <b-form-invalid-feedback :state="!v$.form.is_enabled.$error">
                  此欄位為必填
                </b-form-invalid-feedback>
              </b-form-group>
              <!-- <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="細節設定"
              >
                <b-form-input
                  class="mb-2 mr-sm-2 mb-sm-0"
                  type="text"
                  :state="v$.form.config.$error ? false : null"
                  v-model="form.config"
                  :readonly="isReadOnly"
                ></b-form-input>
                <b-form-invalid-feedback
                  :state="!v$.form.config.$error"
                >
                  此欄位為必填
                </b-form-invalid-feedback>
              </b-form-group> -->
              <div
                class="d-flex justify-content-center"
                style="margin-top: 100px"
              >
                <b-button
                  class="mr-3"
                  variant="outline-danger"
                  @click="goListPage"
                  >返回
                </b-button>
                <b-button
                  v-if="!isReadOnly"
                  @click="handleSubmit"
                  variant="success"
                  >儲存</b-button
                >
              </div>
            </section>

            <!-- 觸發時間 -->
            <section class="mb-5" v-if="step == '#trigger'">
              <h4 class="mb-2 font-weight-bold">觸發時間</h4>
              <div v-if="form.trigger.type" class="flex-fill">
                <!--生日-->
                <template v-if="form.trigger.type == 'birthday'">
                  <div class="form-inline mb-2">
                    <b-form-radio
                      v-model="form.trigger.value"
                      value="day"
                      @change="
                        () => {
                          form.trigger = {
                            type: form.trigger.type,
                            value: 'day',
                          };
                        }
                      "
                    ></b-form-radio>
                    每天
                  </div>

                  <div class="form-inline mb-2">
                    <b-form-radio
                      v-model="form.trigger.value"
                      value="week"
                      @change="
                        () => {
                          form.trigger = {
                            type: form.trigger.type,
                            value: 'week',
                          };
                        }
                      "
                    >
                    </b-form-radio>
                    每週
                  </div>

                  <div class="form-inline mb-2">
                    <b-form-radio
                      v-model="form.trigger.value"
                      value="month"
                      @change="
                        () => {
                          form.trigger = {
                            type: form.trigger.type,
                            value: 'month',
                          };
                        }
                      "
                    ></b-form-radio>
                    每月
                  </div>
                </template>

                <!--LINE-->
                <template v-else-if="form.trigger.type == 'line_follow'">
                  <div>加入好友立即觸發</div>
                </template>

                <!--完成註冊-->
                <template v-else-if="form.trigger.type == 'registered'">
                  <div>註冊後立即觸發</div>
                </template>
                <!--打卡-->
                <template v-else-if="form.trigger.type == 'checkin'">
                  <div class="form-inline mb-2">
                    連續
                    <b-form-input
                      v-model.number="form.trigger['continuous']"
                      class="w-auto mx-2"
                      type="number"
                      size="sm"
                      min="1"
                      :state="
                        v$.form.trigger['continuous'] &&
                        v$.form.trigger['continuous'].$error
                          ? false
                          : null
                      "
                    />
                    天
                  </div>
                </template>
                <!--登入-->
                <template v-else-if="form.trigger.type == 'login'">
                  <div class="form-inline mb-2">
                    <b-form-input
                      v-model.number="form.trigger['frequency']"
                      class="w-auto mx-2"
                      type="number"
                      size="sm"
                      min="1"
                      :state="
                        v$.form.trigger['frequency'] &&
                        v$.form.trigger['frequency'].$error
                          ? false
                          : null
                      "
                    />
                    次
                  </div>
                </template>
                <!--特殊時段登入-->
                <template v-else-if="form.trigger.type == 'special-login'">
                  <div class="form-inline mb-2 d-flex flex-nowrap">
                    <b-form-radio
                      v-model="form.trigger.value"
                      value="dateRange"
                      @change="
                        () => {
                          form.trigger = {
                            type: form.trigger.type,
                            value: 'dateRange',
                            dateRange: {
                              from: new Date(),
                              to: new Date(),
                            },
                            timeRange: {
                              from: { HH: null, mm: null },
                              to: { HH: null, mm: null },
                            },
                          };
                        }
                      "
                    ></b-form-radio>
                    <div>
                      <div
                        class="
                          d-flex
                          flex-column flex-xl-row
                          align-items-start align-items-xl-center
                        "
                      >
                        <datepicker
                          placeholder="選擇日期"
                          v-model="form.trigger.dateRange.from"
                          bootstrap-styling
                          format="yyyy-MM-dd"
                          :language="zh"
                          clear-button
                          :disabled="form.trigger.value != 'dateRange'"
                          :disabledDates="disabledDates"
                          :input-class="
                            v$.form.trigger['dateRange'].from &&
                            v$.form.trigger['dateRange'].from.$error
                              ? 'is-invalid'
                              : ''
                          "
                        ></datepicker>
                        <span class="mx-2">~</span>
                        <datepicker
                          placeholder="選擇日期"
                          v-model="form.trigger.dateRange.to"
                          bootstrap-styling
                          format="yyyy-MM-dd"
                          :language="zh"
                          clear-button
                          :disabled="form.trigger.value != 'dateRange'"
                          :disabledDates="disabledDates"
                          :input-class="
                            v$.form.trigger['dateRange'].to &&
                            v$.form.trigger['dateRange'].to.$error
                              ? 'is-invalid'
                              : ''
                          "
                        ></datepicker>
                      </div>
                      <b-form-invalid-feedback
                        class="w-100"
                        :state="
                          v$.form.trigger['dateRange'].beforeEndAt &&
                          !v$.form.trigger.dateRange.beforeEndAt.$invalid
                        "
                      >
                        結束時間必須大於起始時間
                      </b-form-invalid-feedback>
                    </div>
                  </div>

                  <div class="form-inline mb-2 d-flex flex-nowrap">
                    <b-form-radio
                      v-model="form.trigger.value"
                      value="timeRange"
                      @change="
                        () => {
                          form.trigger = {
                            type: form.trigger.type,
                            value: 'timeRange',
                            dateRange: {
                              from: new Date(),
                              to: new Date(),
                            },
                            timeRange: {
                              from: { HH: null, mm: null },
                              to: { HH: null, mm: null },
                            },
                          };
                        }
                      "
                    ></b-form-radio>
                    <div>
                      <div
                        class="
                          d-flex
                          flex-column flex-xl-row
                          align-items-start align-items-xl-center
                        "
                      >
                        <vue-timepicker
                          placeholder="選擇時間"
                          v-model="form.trigger.timeRange.from"
                          :disabled="form.trigger.value != 'timeRange'"
                          :input-class="[
                            'form-control',
                            v$.form.trigger['timeRange'].from &&
                            (v$.form.trigger['timeRange'].from.HH.$error ||
                              v$.form.trigger['timeRange'].from.mm.$error)
                              ? 'is-invalid'
                              : '',
                          ]"
                        ></vue-timepicker>
                        <span class="mx-2">~</span>
                        <vue-timepicker
                          placeholder="選擇時間"
                          v-model="form.trigger.timeRange.to"
                          :disabled="form.trigger.value != 'timeRange'"
                          :input-class="[
                            'form-control',
                            v$.form.trigger['timeRange'].to &&
                            (v$.form.trigger['timeRange'].to.HH.$error ||
                              v$.form.trigger['timeRange'].to.mm.$error)
                              ? 'is-invalid'
                              : '',
                          ]"
                        ></vue-timepicker>
                      </div>
                      <b-form-invalid-feedback
                        class="w-100"
                        :state="
                          v$.form.trigger.timeRange.beforeEndAt &&
                          !v$.form.trigger.timeRange.beforeEndAt.$invalid
                        "
                      >
                        結束時間必須大於起始時間
                      </b-form-invalid-feedback>
                    </div>
                  </div>
                </template>

                <!--單筆訂單 & 單月購買 & 期間購買-->
                <template
                  v-if="
                    ['purchase', 'month-purchase', 'range-purchase'].includes(
                      form.trigger.type
                    )
                  "
                >
                  <div class="form-inline mb-2">
                    <div
                      v-if="form.trigger.type == 'range-purchase'"
                      class="
                        d-flex
                        flex-column flex-xl-row
                        align-items-start align-items-xl-center
                      "
                    >
                      <datepicker
                        placeholder="選擇日期"
                        v-model="form.trigger.from"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        clear-button
                        :disabledDates="disabledDates"
                        :input-class="
                          v$.form.trigger.from && v$.form.trigger.from.$error
                            ? 'is-invalid'
                            : ''
                        "
                      ></datepicker>
                      <span class="mx-2">~</span>
                      <datepicker
                        placeholder="選擇日期"
                        v-model="form.trigger.to"
                        bootstrap-styling
                        format="yyyy-MM-dd"
                        :language="zh"
                        clear-button
                        :disabledDates="disabledDates"
                        :input-class="
                          v$.form.trigger.to && v$.form.trigger.to.$error
                            ? 'is-invalid'
                            : ''
                        "
                      ></datepicker>
                    </div>
                    <b-form-invalid-feedback
                      class="w-100"
                      :state="
                        v$.form.trigger.from.beforeEndAt &&
                        !v$.form.trigger.from.beforeEndAt.$invalid
                      "
                    >
                      結束時間必須大於起始時間
                    </b-form-invalid-feedback>
                  </div>

                  <div class="form-inline mb-2">
                    <b-form-radio
                      v-model="form.trigger.value"
                      value="frequency"
                      @change="
                        () => {
                          let v = {
                            type: form.trigger.type,
                            value: 'frequency',
                            frequency: 1,
                          };

                          if (v.type == 'range-purchase') {
                            v.from = form.trigger.from;
                            v.to = form.trigger.to;
                          }

                          form.trigger = v;
                        }
                      "
                    ></b-form-radio>
                    <b-form-input
                      v-model.number="form.trigger['frequency']"
                      class="w-auto mx-2"
                      type="number"
                      size="sm"
                      :disabled="form.trigger.value != 'frequency'"
                      min="1"
                      :state="
                        v$.form.trigger.frequency &&
                        v$.form.trigger.frequency.$error
                          ? false
                          : null
                      "
                    />
                    次
                  </div>

                  <div class="form-inline mb-2">
                    <b-form-radio
                      v-model="form.trigger.value"
                      value="item"
                      @change="
                        () => {
                          let v = {
                            type: form.trigger.type,
                            value: 'item',
                            item: 1,
                          };

                          if (v.type == 'range-purchase') {
                            v.from = form.trigger.from;
                            v.to = form.trigger.to;
                          }

                          form.trigger = v;
                        }
                      "
                    ></b-form-radio>
                    <b-form-input
                      v-model.number="form.trigger['item']"
                      class="w-auto mx-2"
                      type="number"
                      size="sm"
                      :disabled="form.trigger.value != 'item'"
                      min="1"
                      :state="
                        v$.form.trigger.item && v$.form.trigger.item.$error
                          ? false
                          : null
                      "
                    />
                    項商品
                  </div>

                  <div class="form-inline mb-2">
                    <b-form-radio
                      v-model="form.trigger.value"
                      value="amount"
                      @change="
                        () => {
                          let v = {
                            type: form.trigger.type,
                            value: 'amount',
                            amount: 1,
                          };

                          if (v.type == 'range-purchase') {
                            v.from = form.trigger.from;
                            v.to = form.trigger.to;
                          }

                          form.trigger = v;
                        }
                      "
                    ></b-form-radio>
                    滿
                    <b-form-input
                      v-model.number="form.trigger['amount']"
                      class="w-auto mx-2"
                      type="number"
                      size="sm"
                      :disabled="form.trigger.value != 'amount'"
                      min="1"
                      :state="
                        v$.form.trigger.amount && v$.form.trigger.amount.$error
                          ? false
                          : null
                      "
                    />
                    金額
                  </div>
                </template>

                <div
                  class="d-flex justify-content-center"
                  style="margin-top: 100px"
                >
                  <b-button
                    class="mr-3"
                    variant="outline-danger"
                    @click="goListPage"
                    >返回
                  </b-button>
                  <b-button
                    v-if="!isReadOnly"
                    @click="handleSubmit"
                    variant="success"
                    >儲存</b-button
                  >
                </div>
              </div>
            </section>

            <!-- 事件內容 -->
            <section class="mb-5" v-if="step == '#event'">
              <h4 class="mb-2 font-weight-bold">事件內容</h4>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="貼標"
              >
                <div class="border rounded p-1">
                  <b-badge
                    v-for="(tag, idx) in form.response.tags"
                    :key="idx"
                    variant="dark"
                    class="m-1"
                  >
                    {{ tag }}
                    <button
                      class="btn btn-xs p-0"
                      @click="form.response.tags.splice(idx, 1)"
                    >
                      <i class="fa fa-remove text-white" />
                    </button>
                  </b-badge>
                  <b-input
                    type="text"
                    class="border-0"
                    maxlength="40"
                    placeholder="輸入標籤"
                    @keyup="
                      (e) => {
                        let v = null;

                        if (e.key == ',') {
                          v = e.target.value.slice(0, -1);
                        } else if (e.key == 'Enter') {
                          v = e.target.value;
                        }

                        if (v && !form.response.tags.includes(v)) {
                          form.response.tags.push(v);
                          e.target.value = '';
                        }
                      }
                    "
                  />
                </div>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="發放優惠卷"
              >
                <div class="d-flex align-items-center mb-2">
                  <b-button
                    class="ml-0 btn-inverse-primary"
                    :disabled="showLoading|| isFetchCoupon"
                    v-b-modal.modal-search-coupon
                  >
                    選擇優惠券
                  </b-button>
                  <div v-if="showLoading || isFetchCoupon" class="ml-2">
                    <b-spinner variant="secondary" small />
                  </div>
                </div>
                <div>
                  <div
                    v-for="coupon in selectedCoupons"
                    v-bind:key="coupon.id"
                    class="border d-inline-block p-2 rounded mb-2 mr-2"
                  >
                    <span class="mdi mdi-18px mdi-close mr-2 cursor-pointer" @click="handleUpdateCoupon(coupon)"></span>
                    <span>{{ coupon.name }}</span>
                  </div>
                </div>
                <!-- <b-form-checkbox
                  v-model="form.response.coupon.limite"
                >
                  每人限定一張
                </b-form-checkbox> -->
              </b-form-group>
              <AutoMarketingSearchCoupon :selectedCoupons="selectedCoupons" @submit="handleSubmitCoupon" />

              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="發放徽章"
              >
                <b-form-select
                  class="mb-2"
                  disabled
                  v-model="form.response.badge.id"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="發放點數"
              >
                <div class="form-inline mb-2">
                  <b-form-radio
                    v-model="form.response.point.type"
                    value="fixed"
                    disabled
                    @change="
                      () => {
                        form.response.point = {
                          type: 'fixed',
                          point: 1,
                        };
                      }
                    "
                  >
                  </b-form-radio>

                  固定點數
                  <b-form-input
                    v-model.number="form.response.point.point"
                    class="w-auto mx-2"
                    type="number"
                    size="sm"
                    :disabled="true || form.response.point.type != 'fixed'"
                    min="1"
                    :state="
                      v$.form.response.point.point &&
                      v$.form.response.point.point.$error
                        ? false
                        : null
                    "
                  />
                  點
                </div>

                <div class="form-inline mb-2">
                  <b-form-radio
                    v-model="form.response.point.type"
                    value="multiple"
                    disabled
                    @change="
                      () => {
                        form.response.point = {
                          type: 'multiple',
                          multiple: 1,
                        };
                      }
                    "
                  >
                  </b-form-radio>

                  訂單金額
                  <b-form-input
                    v-model.number="form.response.point.multiple"
                    class="w-auto mx-2"
                    type="number"
                    size="sm"
                    :disabled="true || form.response.point.type != 'multiple'"
                    min="1"
                    :state="
                      v$.form.response.point.multiple &&
                      v$.form.response.point.multiple.$error
                        ? false
                        : null
                    "
                  />
                  倍點數
                </div>

                <div class="form-inline mb-2">
                  <b-form-radio
                    v-model="form.response.point.type"
                    value="level"
                    disabled
                    @change="
                      () => {
                        form.response.point = {
                          type: 'level',
                          levelAmount: 1,
                          levelPoint: 1,
                        };
                      }
                    "
                  >
                  </b-form-radio>

                  每
                  <b-form-input
                    v-model.number="form.response.point.levelAmount"
                    class="w-auto mx-2"
                    type="number"
                    size="sm"
                    :disabled="true || form.response.point.type != 'level'"
                    min="1"
                    :state="
                      v$.form.response.point.levelAmount &&
                      v$.form.response.point.levelAmount.$error
                        ? false
                        : null
                    "
                  />
                  贈
                  <b-form-input
                    v-model.number="form.response.point.levelPoint"
                    class="w-auto mx-2"
                    type="number"
                    size="sm"
                    :disabled="true || form.response.point.type != 'level'"
                    min="1"
                    :state="
                      v$.form.response.point.levelPoint &&
                      v$.form.response.point.levelPoint.$error
                        ? false
                        : null
                    "
                  />
                  點
                </div>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="sm"
                label="發送推播"
              >
              </b-form-group>

              <div
                class="d-flex justify-content-center"
                style="margin-top: 100px"
              >
                <b-button
                  class="mr-3"
                  variant="outline-danger"
                  @click="goListPage"
                  >返回
                </b-button>
                <b-button
                  v-if="!isReadOnly"
                  @click="handleSubmit"
                  variant="success"
                  >儲存</b-button
                >
              </div>
            </section>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, minValue } from "@vuelidate/validators";
import { isBefore, set, subDays } from "date-fns";
import Datepicker from "vuejs-datepicker";
import { zh } from "vuejs-datepicker/dist/locale";
import _ from "lodash";
import moment from "moment";
import autoMarketingApi from "@/apis/auto-marketing";
import redeemCodeBundleApi from "@/apis/redeemCodeBundle";
import merchantApi from "@/apis/merchant";
import AutoMarketingSearchCoupon from "./AutoMarketingSearchCoupon.vue";
import * as consts from "@/consts";

const triggers = [
  {
    value: "birthday",
    text: "生日事件",
    options: [
      {
        text: "每天",
        value: "day",
      },
      {
        text: "每週",
        value: "week",
      },
      {
        text: "每月",
        value: "month",
      },
    ],
    default: {
      type: "birthday",
      value: "day",
    },
  },
  {
    value: "line_follow",
    text: "好友事件",
    default: {
      type: "line_follow",
    },
  },
  {
    value: "registered",
    text: "註冊事件",
    default: {
      type: "registered",
    },
  },
  {
    value: "checkin",
    text: "打卡",
    options: ["continuous"],
    default: {
      type: "checkin",
      continuous: 1,
      value: "continuous",
    },
  },
  {
    value: "login",
    text: "登入",
    options: ["frequency"],
    default: {
      type: "login",
      value: "frequency",
      frequency: 1,
    },
  },
  {
    value: "special-login",
    text: "特殊時段登入",
    options: ["dateRange", "timeRange"],
    default: {
      type: "special-login",
      value: "dateRange",
      dateRange: { from: new Date(), to: new Date() },
      timeRange: { from: { HH: null, mm: null }, to: { HH: null, mm: null } },
    },
  },
  {
    value: "purchase",
    text: "單筆訂單",
    options: ["frequency", "item", "amount"],
    default: {
      type: "purchase",
      value: "frequency",
      frequency: 1,
    },
  },
  {
    value: "month-purchase",
    text: "單月購買",
    options: ["frequency", "item", "amount"],
    default: {
      type: "month-purchase",
      value: "frequency",
      frequency: 1,
    },
  },
  {
    value: "range-purchase",
    text: "期間購買",
    options: ["frequency", "item", "amount"],
    default: {
      type: "range-purchase",
      value: "frequency",
      from: new Date(),
      to: new Date(),
      frequency: 1,
    },
  },
];

export default {
  components: { Datepicker, AutoMarketingSearchCoupon },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    let trigger = {
      type: { required },
    };

    //Trigger 打卡
    trigger["continuous"] =
      this.form.trigger.type == "checkin" &&
      this.form.trigger.value == "continuous"
        ? { required, minValue: minValue(1) }
        : {};

    //Trigger 登入
    trigger["frequency"] =
      this.form.trigger.type == "login" &&
      this.form.trigger.value == "frequency"
        ? { required, minValue: minValue(1) }
        : {};

    //Trigger 特殊時段登入
    trigger["dateRange"] =
      this.form.trigger.type == "special-login" &&
      this.form.trigger.value == "dateRange"
        ? {
            from: { required },
            to: { required },
            beforeEndAt: () => {
              if (
                !this.form.trigger.dateRange.from ||
                !this.form.trigger.dateRange.to
              ) {
                return true;
              }

              const startDate = set(
                new Date(this.form.trigger.dateRange.from),
                {
                  hours: 0,
                  minutes: 0,
                }
              );
              const endDate = set(new Date(this.form.trigger.dateRange.to), {
                hours: 23,
                minutes: 59,
              });
              return isBefore(startDate, endDate);
            },
          }
        : {};

    trigger["timeRange"] =
      this.form.trigger.type == "special-login" &&
      this.form.trigger.value == "timeRange"
        ? {
            from: { HH: { required }, mm: { required } },
            to: { HH: { required }, mm: { required } },
            beforeEndAt: () => {
              if (
                !this.form.trigger.timeRange.from ||
                !this.form.trigger.timeRange.to
              ) {
                return true;
              }

              const startDate = set(new Date(), {
                hours: this.form.trigger.timeRange.from.HH,
                minutes: this.form.trigger.timeRange.from.mm,
              });
              const endDate = set(new Date(), {
                hours: this.form.trigger.timeRange.to.HH,
                minutes: this.form.trigger.timeRange.to.mm,
              });
              return isBefore(startDate, endDate);
            },
          }
        : {};

    //Trigger 單筆訂單 & 單月購買 & 期間購買
    trigger["frequency"] =
      ["purchase", "month-purchase", "range-purchase"].includes(
        this.form.trigger.type
      ) && this.form.trigger.value == "frequency"
        ? { required, minValue: minValue(1) }
        : {};

    trigger["item"] =
      ["purchase", "month-purchase", "range-purchase"].includes(
        this.form.trigger.type
      ) && this.form.trigger.value == "item"
        ? { required, minValue: minValue(1) }
        : {};

    trigger["amount"] =
      ["purchase", "month-purchase", "range-purchase"].includes(
        this.form.trigger.type
      ) && this.form.trigger.value == "amount"
        ? { required, minValue: minValue(1) }
        : {};

    //Trigger 期間購買
    trigger["from"] =
      this.form.trigger.type == "range-purchase"
        ? {
            required,
            beforeEndAt: () => {
              if (!this.form.trigger.from || !this.form.trigger.to) {
                return true;
              }

              const startDate = set(new Date(this.form.trigger.from), {
                hours: 0,
                minutes: 0,
              });
              const endDate = set(new Date(this.form.trigger.to), {
                hours: 23,
                minutes: 59,
              });
              return isBefore(startDate, endDate);
            },
          }
        : {};
    trigger["to"] =
      this.form.trigger.type == "range-purchase" ? { required } : {};

    //Response
    let response = {
      point: {},
    };

    //Response 發放點數
    response.point["point"] =
      this.form.response.point.type == "fixed"
        ? { required, minValue: minValue(1) }
        : {};

    response.point["multiple"] =
      this.form.response.point.type == "multiple"
        ? { required, minValue: minValue(1) }
        : {};

    response.point["levelAmount"] =
      this.form.response.point.type == "level"
        ? { required, minValue: minValue(1) }
        : {};

    response.point["levelPoint"] =
      this.form.response.point.type == "level"
        ? { required, minValue: minValue(1) }
        : {};

    let validation = {
      form: {
        title: { required },
        start_at: {
          required,
          beforeEndAt: () => {
            if (!this.form.start_at || !this.form.end_at) {
              return true;
            }

            const startDate = set(new Date(this.form.start_at), {
              hours: 0,
              minutes: 0,
            });
            const endDate = set(new Date(this.form.end_at), {
              hours: 23,
              minutes: 59,
            });
            return isBefore(startDate, endDate);
          },
        },
        end_at: { required },
        type: { required },
        is_enabled: { required },
        config: { required },
        merchant_id: { required },
        trigger,
        response,
      },
    };
    return validation;
  },

  data() {
    return {
      zh,
      showLoading: false,
      isEditing: false,
      validationErrors: null,
      disabledDates: { to: subDays(new Date(), 1) },
      step: "",
      form: {
        title: null,
        start_at: new Date(),
        end_at: new Date(),
        type: null,
        is_enabled: true,
        config: null,
        merchant_id: null,
        trigger: {
          type: "",
          value: "",
        },
        response: {
          tags: [],
          coupon: [],
          badge: { id: null },
          point: { type: "fixed" },
        },
      },
      merchants: [],
      isFetchMerchants: false,
      selectedCoupons: [],
      isFetchCoupon: false,
    };
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),

    isReadOnly() {
      return this.$route.name === "AutoMarketingView";
    },
    isEdit() {
      return this.$route.name == "AutoMarketingEdit";
    },
    type() {
      if (this.isEdit) {
        return this.form.type;
      }
      return this.$route.params.type;
    },
    title() {
      return triggers.find(({ value }) => value === this.type)?.text ?? "";
    },
  },

  async created() {
    if (this.isEdit || this.isReadOnly) {
      await this.fetchAutoMarketing();
    }

    this.getMerchants();
    this.triggerChange();
    this.initSelectedCoupons();
  },
  mounted(){
    this.navigateToStepFromCurrentRoute();
  },
  methods: {
    moment,
    navigateToStepFromCurrentRoute() {
      const anchor = this.$route.hash;
      if (anchor) {
        this.goToStep(anchor);
      }
    },
    isObject(object) {
      return _.isObject(object);
    },
    triggerChange() {
      this.form.trigger = triggers.find(
        ({ value }) => value === this.type
      )?.default;
      this.form.type = this.type;
    },
    goListPage() {
      this.$router.push({ name: "AutoMarketingMemberEvent" });
    },
    async fetchAutoMarketing() {
      this.showLoading = true;

      try {
        const { data } = await autoMarketingApi.getMarketingConfig(
          this.$route.params.config_id
        );
        const {
          id,
          merchant_id,
          title,
          type,
          start_at,
          end_at,
          is_enabled,
          config,
        } = data.data;
        this.form = {
          ...this.form,
        };
        this.form.id = id;
        this.form.merchant_id = merchant_id;
        this.form.title = title;
        this.form.type = type;
        this.form.start_at = start_at;
        this.form.end_at = end_at;
        this.form.is_enabled = is_enabled;
        this.form.config = config;

        config.actions.forEach((action) => {
          if (action.type === "waltily_tagging") {
            this.form.response.tags = action.tags;
          } else if (action.type === "assign_redeem_code") {
            // if (this.form.response.coupon.id == null) {
            //   this.form.response.coupon.id = [];
            // }
            this.form.response.coupon.push(action);
          }
        })
      } catch (e) {
        console.error(e);
      }
      this.showLoading = false;
    },
    async handleSubmit() {
      // const result = await this.v$.$validate();
      // if (!result) return;
      this.form.config = {
        actions: [
          // 標籤
          {
            type: "waltily_tagging",
            tags: this.form.response.tags.map((tag) => tag),
          },
        ],
        conditions: [
          {
            type: this.type,
          },
        ],
      };

      // 選擇優惠券
      if (this.selectedCoupons.length) {
        this.selectedCoupons.forEach(coupon => {
          this.form.config.actions.push(
            {
              type: "assign_redeem_code",
              bundle_id: coupon.redeem_code_bundle_id,
              bundle_hashid: coupon.id,
              name: coupon.name,
              is_push_line: true,
              repeat_assign: true, // 預設為 true
            },
          )
        })
      }

      if (this.type === "birthday") {
        this.form.config.conditions = [
          {
            type: this.type,
            timing: this.form.trigger.value,
          },
        ];
      }

      try {
        const payload = this.form;
        if (this.isEdit) {
          await autoMarketingApi.updateMarketingConfig(payload);
        } else {
          await autoMarketingApi.storeMarketingConfig(payload);
        }
        this.goListPage();
      } catch (e) {
        console.log(e);
      }
    },
    async getMerchants() {
      this.isFetchMerchants = true;
      const { data } = await merchantApi.list();
      this.isFetchMerchants = false;

      this.merchants = [
        { value: null, text: "請選擇" },
        ...data.data.map((merchant) => {
          return {
            value: merchant.id,
            text: merchant.type_name
              ? `${merchant.name} (${merchant.type_name})`
              : merchant.name,
          };
        }),
      ];

      const lineMerchant = data.data.filter((merchant) => {
        return merchant.type === consts.MERCHANT_TYPES.LINE_TYPE;
      });

      if (lineMerchant && !this.form.merchant_id) {
        this.form.merchant_id = lineMerchant[0].id;
      }
    },

    async initSelectedCoupons() {
      try {
        this.isFetchCoupon = true;

        const res = await Promise.all(
          (this.form.response.coupon ?? []).map((coupon) => {
            return redeemCodeBundleApi.getRedeemCodeBundle(coupon.bundle_hashid);
          })
        );

        this.selectedCoupons = res.map(({ data: { data } }) => data);
      } catch (e) {
        console.log(e);
      }
      this.isFetchCoupon = false;
    },
    handleUpdateCoupon(coupon) {
      const index = this.selectedCoupons.findIndex(({ id }) => id === coupon.id);

        if (index === -1) {
          this.selectedCoupons.push(coupon);
        } else {
          this.selectedCoupons.splice(index, 1);
        }
    },
    handleSubmitCoupon(coupon) {
      this.selectedCoupons =[...coupon];
    },
    goToStep(step) {
      this.step = step;

      if (this.$route.hash !== this.step) {
        this.$router.replace({ hash: this.step });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.accordion {
  .card {
    overflow: visible;
  }
}

::v-deep .form-control.is-invalid {
  border-color: #dc3545;
}
</style>
