<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item>銷售應用中心</b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'PreorderList',
                params: {
                  id: this.$route.params.id,
                },
              }"
            >預購單列表
            </b-breadcrumb-item
            >
            <b-breadcrumb-item active>預購單資料</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row mb-3 align-items-center">
          <div class="col-12 col-xl-2">
            <h4 class="font-weight-bold">預購單資料</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <b-form-group :disabled=readonly>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="8"
                label="訂單編號(內部)"
                disabled
              >
                <b-form-input v-model="preorder.preorder_number"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="8"
                label="分店名稱 & ID"
                disabled
              >
                <b-form-input :value="preorder.branch ? preorder.branch.name + ' ' + preorder.branch.id : '無'"></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="preorder.customer"
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="8"
                label="會員姓名"
                disabled
              >
                <b-form-input v-model="preorder.customer.name"></b-form-input>
              </b-form-group>
              <b-form-group
                v-if="preorder.customer"
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="8"
                label="外部會員ID"
                disabled
              >
                <b-form-input v-model="preorder.customer.outer_code"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="8"
                label="訂單狀態"
                :disabled="checkPermission([consts.PREORDER_STATUS_MODIFY]) === false"
              >
                <b-form-select v-model="preorder.status" :options="statusOptions"></b-form-select>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="8"
                label="下單時間"
                disabled
              >
                <b-form-input :value="dateFormatter(preorder.created_at)"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="8"
                label="更新時間"
                disabled
              >
                <b-form-input :value="dateFormatter(preorder.updated_at)"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="8"
                label="預購單金額"
                disabled
              >
                <b-form-input v-model="preorder.total_amount"></b-form-input>
              </b-form-group>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group :disabled=readonly>
              <b-form-group
                v-for="(data, key) in preorder.data"
                :key="key"
                label-cols="12"
                label-cols-lg="4"
                label-size="m"
                content-cols="12"
                content-cols-lg="8"
                :label="data.title"
                disabled
              >
                <b-form-input v-model="data.value"></b-form-input>
              </b-form-group>
            </b-form-group>
          </div>
        </div>
        <div class="row mt-4 align-items-center">
          <div class="col-12 col-xl-2">
            <h4 class="font-weight-bold">訂單明細</h4>
          </div>
        </div>
        <div class="text-right" v-if="!readonly">
          <b-button
            class="flex-shrink-0 mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            @click="addItemHandler"
            v-if="false"
          ><i class="fa fa-plus" aria-hidden="true"></i
          >新增商品
          </b-button
          >
        </div>
        <b-table
          striped
          hover
          :items="preorder.items"
          :fields="fields"
          :busy="showLoading"
        >
          <template #cell(name)="data">
            <div>
              {{ data.item.title }}
              {{ data.item.sku_outer_code }}
            </div>
          </template>
          <template #cell(price)="data">
            <div>
              {{ data.item.price }}
            </div>
          </template>
          <template #cell(quantity)="data">
            <div>
              {{ data.item.quantity }}
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-button class="mr-4" variant="outline-danger" @click="handleCancel">
            <template>返回</template>
          </b-button>
          <b-button v-if="checkPermission([consts.PREORDER_STATUS_MODIFY])" variant="success" @click="handleSubmit">
            <template>更新</template>
          </b-button>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import * as consts from "@/consts";
import {format, set, formatISO} from "date-fns";
import orderApi from "../../../apis/order";
import { mapGetters, mapState } from "vuex";
// import Datepicker from "vuejs-datepicker";
import {zh} from "vuejs-datepicker/dist/locale";
import getHours from "date-fns/getHours/index";
import getMinutes from "date-fns/getMinutes/index";
// import VueTimepicker from "vue2-timepicker";
// import merchantApi from "../../../apis/merchant";
import preorderApi from "@/apis/preorder";
import PermissionChecker from "@/utils/PermissionChecker";

export default {
  data() {
    return {
      consts: consts,
      zh,
      showLoading: false,
      preorderSetting: {
        config: {},
      },
      preorder: {
        order_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        status_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        confirm_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        completed_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        items: [],
        customer: {
          id: null,
          outer_code: null,
          name: null,
        },
        branch: {
          name: null,
        },
      },
      fields: [
        {key: "preorder_item_number", label: "訂單明細編號"},
        {key: "name", label: "商品標題",},
        {key: "price", label: "售價",},
        {key: "quantity", label: "數量",},
        {key: "total_price", label: "小計",},
      ],
      orderStatusOptions: [
        {value: null, text: '請選擇狀態'},
        {value: 'Confirmed', text: '已成立'},
        {value: 'Finish', text: '已完成'},
        {value: 'Cancel', text: '已取消'},
        {value: 'WaitingToShipping', text: '出貨準備中'},
        {value: 'WaitingToCreditCheck', text: '付款確認中'},
        {value: 'ConfirmedToShipping', text: '已確認待出貨'},
        {value: 'WaitingToPay', text: '待付款'},
        {value: 'WaitingToModifyStore', text: '門市閉店，待消費者重選'},
      ],
      deliveryStatusOptions: [
        {value: "Finish", text: "已取貨"},
        {value: "Cancel", text: "已取消"},
        {value: "AllocatedCode",  text: "已配號"},
        {value: "VerifySuccess",  text: "超商驗收成功"},
        {value: "VerifyFailLost", text: "超商驗收失敗"},
        {value: "VerifyFailAbnormalPackage",  text: "超商驗收失敗"},
        {value: "VerifyFailRenovation", text: "超商驗收失敗"},
        {value: "VerifyFailErrorCode",  text: "超商驗收失敗"},
        {value: "VerifyFailInvalIdCode",  text: "超商驗收失敗"},
        {value: "ShippingFail", text: "超商逾期未取"},
        {value: "ShippingProcessing", text: "出貨處理中"},
        {value: "ShippingArrived",  text: "貨到門市"},
        {value: "CashOnDeliveryTransferring", text: "宅配轉運中"},
        {value: "CashOnDeliveryNotAtHome",  text: "宅配不在家"},
        {value: "CashOnDeliveryDistributing", text: "宅配已配送"},
        {value: "CashOnDeliveryFailDamage", text: "宅配異常損壞"},
        {value: "CashOnDeliveryFailLost", text: "宅配異常遺失"},
        {value: "CashOnDeliveryFail", text: "宅配出貨失敗"},
        {value: "CashOnDeliveryAddressError", text: "宅配地址錯誤"},
        {value: "CashOnDeliveryForwarding", text: "宅配轉寄配送中"}
      ]
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    readonly() {
      return this.$route.name === "OrderDetail"
    },
    isEdit() {
      return this.$route.name === "OrderEdit"
    },
    isCreate() {
      return this.$route.name === "OrderCreate"
    },
    statusOptions() {
      if (!this.preorderSetting?.config?.status) {
        return [];
      }

      return this.preorderSetting.config.status.map((status) => {
        return {
          value: status.value,
          text: status.title,
        };
      })
    }
  },
  mounted() {
    this.fetchPreorder();
  },
  methods: {
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    format,
    formatToDateObject(datetime) {
      if (!datetime) {
        return {}
      }
      return {
        date: new Date(datetime),
        time: {
          HH: String(getHours(new Date(datetime))).padStart(2, "0"),
          mm: String(getMinutes(new Date(datetime))).padStart(2, "0"),
        },
      }
    },
    async fetchPreorder() {
      try {
        this.showLoading = true;

        let response = await preorderApi.getPreorder(this.$route.params.preorder_id);
        this.preorder = response.data;
        this.preorderSetting = response.data.preorder_setting;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取預購單資料失敗", "error");
      } finally {
        this.showLoading = false;
      }
    },
    handleCancel() {
      this.$router.push({ name: "PreorderList", params: { id: this.$route.params.id } });
    },
    formatToDatetimeString(datetime) {
      if (datetime.date) {
        return formatISO(
          set(new Date(datetime.date), {
              hours: Number(datetime.time.HH),
              minutes: Number(datetime.time.mm),
              seconds: 0,
            },
          )
        )
      } else {
        return {}
      }
    },
    async handleSubmit() {
      try {
        this.showLoading = true;
        await preorderApi.updatePreorder(this.$route.params.preorder_id, this.preorder);
        this.$swal("成功", "更新成功", "success");
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "更新失敗", "error");
      } finally {
        this.showLoading = false;
      }
    },
    async goUpdate() {
      try {
        await orderApi.updateOrder(this.order.id, this.order);
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            this.$router.push({ name: "OrderList", query: this.routeQuery });
          });
      } catch (error) {
        console.error(error);
      }
    },
    async goCreate() {
      try {
        await orderApi.createOrder(this.order);
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            const params = { name: "OrderList", query: this.routeQuery };
            this.$router.push(params);
          });
      } catch (error) {
        console.error(error);
      }
    },
    addItemHandler() {
      this.order.items.push({
        id: null,
        sku_name: null,
        sku_code: null,
        price: 0,
        quantity: 0,
        total_discount: 0,
        status: null,
        status_at: null,
        confirm_at: null,
        delivery_status: null,
        delivery_type: null,
        delivery_shipping_code: null,
        return_shipping_code: null,
        return_shipping_id: null,
        return_status: null,
      })
    },
    removeItemHandler(index) {
      this.order.items = this.order.items.filter((item, i) => i !== index);
      this.$emit('input', this.order);
    },
    dateFormatter: (value) => {
      if (!value) return "無";
      return format(new Date(value), "yyyy-MM-dd HH:mm");
    },
  },
};
</script>
