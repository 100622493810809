<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'ProductList',
              }"
            >商品管理
            </b-breadcrumb-item
            >
            <b-breadcrumb-item active>商品資料</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <b-overlay :show="showLoading" rounded="sm">
        <div class="row mb-2 align-items-center">
          <div class="col-12 col-xl-2">
            <h4 class="font-weight-bold">商品明細</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-form-group :disabled="readonly">
              <b-form-group
                label-cols="12"
                label-cols-lg="1"
                label-size="m"
                content-cols="12"
                content-cols-lg="11"
                label="商品名稱"
              >
                <b-form-input v-model="product.title"></b-form-input>
              </b-form-group>

              <b-form-group
                label-cols="12"
                label-cols-lg="1"
                label-size="m"
                content-cols="12"
                content-cols-lg="11"
                label="商品說明"
              >
                <b-form-input v-model="product.description"></b-form-input>
              </b-form-group>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :disabled="readonly">
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="商品分類"
              >
                <b-form-input v-model="product.category"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="售價"
              >
                <b-form-input type="number" v-model="product.price"></b-form-input>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="2"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="原價"
              >
                <b-form-input type="number" v-model="product.original_price"></b-form-input>
              </b-form-group>
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group :disabled="readonly">
              <b-form-group
                label-cols="12"
                label-cols-lg="3"
                label-size="l"
                content-cols="12"
                content-cols-lg="8"
                label="開始販售時間"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="Select Date"
                      v-model="product.available_start_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                    ></datepicker>
                  </div>
                  <div class="ml-2 w-50">
                    <vue-timepicker
                      format="HH:mm"
                      v-model="product.available_start_at.time"
                      :input-class="['form-control']"
                    ></vue-timepicker>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="3"
                label-size="l"
                content-cols="12"
                content-cols-lg="8"
                label="結束販售時間"
              >
                <div class="d-flex">
                  <div class="w-50">
                    <datepicker
                      placeholder="Select Date"
                      v-model="product.available_end_at.date"
                      bootstrap-styling
                      format="yyyy-MM-dd"
                      :language="zh"
                    ></datepicker>
                  </div>
                  <div class="ml-2 w-50">
                    <vue-timepicker
                      format="HH:mm"
                      v-model="product.available_end_at.time"
                      :input-class="['form-control']"
                    ></vue-timepicker>
                  </div>
                </div>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="3"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="是否上架"
              >
                <b-form-checkbox v-model="product.is_close" switch></b-form-checkbox>
              </b-form-group>
              <b-form-group
                label-cols="12"
                label-cols-lg="3"
                label-size="m"
                content-cols="12"
                content-cols-lg="6"
                label="商品類型"
              >
                <b-form-radio-group v-model="product.type" :options="productTypeOptions">
                </b-form-radio-group>
              </b-form-group>
            </b-form-group>
          </div>
        </div>
        <div class="row mt-4 mb-2 align-items-center">
          <div class="col-12 col-xl-2">
            <h4 class="font-weight-bold">商品規格</h4>
          </div>
        </div>
        <div class="text-right" v-if="!readonly">
          <b-button
            class="flex-shrink-0 mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            @click="addSkuHandler"
          ><i class="fa fa-plus" aria-hidden="true"></i
          >新增規格
          </b-button
          >
        </div>
        <b-table striped hover :items="product.skus" :fields="fields">
          <template #cell(sku_code)="data">
            <div v-if="readonly">
              {{ data.item.sku_code }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="12"
                content-cols-sm="12">
                <b-form-input v-model="data.item.sku_code"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(sku_name)="data">
            <div v-if="readonly">
              {{ data.item.sku_name }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="12"
                content-cols-sm="12">
                <b-form-input v-model="data.item.sku_name"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(quantity)="data">
            <div v-if="readonly">
              {{ data.item.quantity }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="12"
                content-cols-sm="12">
                <b-form-input type="number" v-model="data.item.quantity"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(suggest_price)="data">
            <div v-if="readonly">
              {{ data.item.suggest_price }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="12"
                content-cols-sm="12">
                <b-form-input type="number" v-model="data.item.suggest_price"></b-form-input>
              </b-form-group>
            </div>
          </template>
          <template #cell(price)="data">
            <div v-if="readonly">
              {{ data.item.price }}
            </div>
            <div v-else>
              <b-form-group
                label-cols="12"
                label-cols-sm="0"
                label-size="sm"
                content-cols="12"
                content-cols-sm="12">
                <b-form-input type="number" v-model="data.item.price"></b-form-input>
              </b-form-group>
            </div>
          </template>
        </b-table>
        <div class="d-flex justify-content-center" v-if="!readonly" style="margin-top: 80px">
          <b-button class="mr-4" variant="outline-danger" @click="handleCancel">
            <template>返回</template>
          </b-button>
          <b-button variant="success" @click="handleSubmit">
            <template v-if="isEdit">確認編輯</template>
            <template v-if="isCreate">確認新增</template>
          </b-button>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import productApi from "../../../../apis/product";
import Datepicker from "vuejs-datepicker";
import {zh} from "vuejs-datepicker/dist/locale";
import branchApi from "../../../../apis/branch";
import { mapGetters, mapState } from "vuex";
import getHours from "date-fns/getHours/index";
import getMinutes from "date-fns/getMinutes/index";
import VueTimepicker from "vue2-timepicker";
import {format, set, formatISO} from "date-fns";


export default {
  components: {Datepicker, VueTimepicker},
  data() {
    return {
      zh,
      showLoading: false,
      product: {
        merchant_id: null,
        available_start_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        available_end_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        skus: []
      },
      fields: [
        {key: "sku_code", label: "規格序號", class: 'wide-col'},
        {key: "sku_name", label: "規格名稱", class: 'wide-col'},
        {key: "quantity", label: "可售庫存", class: 'narrow-col'},
        {key: "suggest_price", label: "建議售價", class: 'narrow-col'},
        {key: "price", label: "原價", class: 'narrow-col'},
      ],
      branches: [],
      productTypeOptions: [
        {text: '一般', value: 'normal'},
        {text: '團購品', value: 'groupbuying'},
        {text: '實體', value: 'physical'},
        {text: '虛擬', value: 'virtual'},
        {text: '服務', value: 'service'},
        {text: '贈品', value: 'gift'},
        {text: '兌換品', value: 'redeem_gift'},
        {text: '線上課程', value: 'course'},
        {text: '一對一課程', value: 'course-single'},
        {text: '一對多課程', value: 'course-multi'},
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("route", ["routeQuery"]),
    readonly() {
      return this.$route.name === "ProductDetailV2"
    },
    isEdit() {
      return this.$route.name === "ProductEditV2"
    },
    isCreate() {
      return this.$route.name === "ProductCreateV2"
    }
  },
  mounted() {
    if (!this.isCreate) {
      this.fetchProduct()
    }
    this.fetchMyBranch()

    if (this.isCreate && this.$route.query.merchant_id) {
      this.product.merchant_id = this.$route.query.merchant_id
    }
  },
  methods: {
    format,
    formatToDateObject(datetime) {
      return {
        date: new Date(datetime),
        time: {
          HH: String(getHours(new Date(datetime))).padStart(2, "0"),
          mm: String(getMinutes(new Date(datetime))).padStart(2, "0"),
        },
      }
    },
    async fetchProduct() {
      this.showLoading = true;

      try {
        const {data} = await productApi.getProduct(this.$route.params.product_id);
        this.product = data.data;
        this.product.branch_id = this.product.branch ? this.product.branch.id : '';
        this.product.available_start_at = this.formatToDateObject(this.product.available_start_at)
        this.product.available_end_at = this.formatToDateObject(this.product.available_end_at)

      } catch (error) {
        console.error(error);
        alert("取得群商品料錯誤");
      }
      this.showLoading = false;
    },
    async fetchMyBranch() {
      if (this.branches.length > 0) {
        return;
      }
      console.log('go fetch my branch')
      try {
        const {data} = await branchApi.getCurrentBelowBranches();
        data.data.forEach((branch) => {
          if (branch.org_id === this.organization.id) {
            this.branches.push({
              text: branch.name,
              value: branch.id,
            });
          }
        });
      } catch (error) {
        console.log(error);
        alert("取得分店資料錯誤");
      }
    },
    handleCancel() {
      this.$router.push({ name: "ProductList", query: this.routeQuery });
    },
    formatToDatetimeString(datetime) {
      return formatISO(
        set(new Date(datetime.date), {
            hours: Number(datetime.time.HH),
            minutes: Number(datetime.time.mm),
            seconds: 0,
          },
        )
      )
    },
    async handleSubmit() {
      this.showLoading = true;

      if (isNaN(Number(this.product.price)) || Number(this.product.price) <= 0) {
        this.$swal.fire({
          icon: "error",
          text: "售價必須輸入有效的數字",
          type: "error",
          confirmButtonText: "確定"
        });
        this.showLoading = false;
        return;
      }

      if (isNaN(Number(this.product.original_price)) || Number(this.product.original_price) <= 0) {
        this.$swal.fire({
          icon: "error",
          text: "原價必須輸入有效的數字",
          type: "error",
          confirmButtonText: "確定"
        });
        this.showLoading = false;
        return;
      }

      if (Number(this.product.price) > Number(this.product.original_price)) {
        this.$swal
          .fire({
            icon: "error",
            text: "售價不得大於原價",
            type: "error",
            confirmButtonText: "確定"
          });
        this.showLoading = false;
        return;
      }

      this.product.available_start_at = this.formatToDatetimeString(this.product.available_start_at)
      this.product.available_end_at = this.formatToDatetimeString(this.product.available_end_at)

      if (this.product.skus.length > 0) {
        for (let i = 0; i < this.product.skus.length; i++) {
          const sku = this.product.skus[i];

          // 確保兩個值都是數字
          const suggestPrice = Number(sku.suggest_price);
          const price = Number(sku.price);

          if (isNaN(suggestPrice) || isNaN(price)) {
            this.$swal.fire({
              icon: "error",
              text: `第 ${i + 1} 筆 商品規格 的建議售價或售價必須是有效的數字`,
              type: "error",
              confirmButtonText: "確定"
            });
            this.showLoading = false;
            return;
          }

          if (suggestPrice > price) {
            this.$swal.fire({
              icon: "error",
              text: `第 ${i + 1} 筆 商品規格 的建議售價不能大於售價`,
              type: "error",
              confirmButtonText: "確定"
            });
            this.showLoading = false;
            return;
          }
        }
      }

      if (this.isEdit) {
        await this.goUpdate()
      }
      if (this.isCreate) {
        await this.goCreate()
      }

      this.showLoading = false;
    },
    async goUpdate() {
      try {
        await productApi.updateProduct(this.product.id, this.product);
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            this.$router.push({ name: "ProductList", query: this.routeQuery });
          });
      } catch (error) {
        console.error(error);
      }
    },
    async goCreate() {
      try {
        await productApi.createProduct(this.product);
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            this.$router.push({ name: "ProductList", query: this.routeQuery });
          });
      } catch (error) {
        console.error(error);
      }
    },
    addSkuHandler() {
      this.product.skus.push({
        id: null,
        sku_code: null,
        sku_name: null,
        quantity: 0,
        suggest_price: 0,
        price: 0,
      })
    },
    removeItemHandler(index) {
      this.product.skus = this.product.skus.filter((item, i) => i !== index);
      this.$emit('input', this.product);
    },
  },
};
</script>
<style>
.wide-col {
  width: 30%; /* 調整寬度為所需百分比 */
}

.narrow-column {
  width: 12%; /* 調整寬度為所需百分比 */
}
</style>
