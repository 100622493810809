<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <Loading
        v-if="isLoading"
        loading-message="載入中..."
        :background-color="'#ffffff'"
      />

      <Header
        :meta="meta"
        :page-title="getModuleConfig('page_title')"
        :page-image="getModuleConfig('page_image')"
        :page-kv-image="getModuleConfig('page_kv_image')"
        :header-section="getModuleConfig('header_section')"
      />

      <div class="page">
        <div class="py-4 text-center">
          <img src="@/assets/images/icon-check-circle.svg" />
          <div class="mt-3 font-weight-bold">此預約不存在</div>
        </div>


        <div class="footer-action-bar">
          <MemberButton
            button-text="回官方帳號"
            type="OA"
          >
          </MemberButton>
        </div>
      </div>
    </div>
  </div>
  </template>

  <script>
  import MemberButton from "@/components/Page/Liff/Shared/MemberButton.vue";
  import Loading from "@/components/Page/Liff/Shared/Loading";
  import { themeColorFn } from "@/mixins/liff/themeColor";
  import { generateModuleConfigGetter } from "@/utils/liff/vueStoreHelpers";

  export default {
    components: {
      MemberButton,
      Loading,
    },
    mixins: [
      themeColorFn({ themeConfigPage: 'booking' })
    ],
    data: () => ({
      isLoading: true,
    }),
    computed: {

    },
    async mounted() {
      this.isLoading = false
    },
    methods: {
      getModuleConfig: generateModuleConfigGetter('liff_booking'),
    },
  };
  </script>

  <style lang="scss" scoped>
  @import "../../../assets/css/liff/main.css";
  @import "../../../assets/css/liff/liff_init_theme_config.css";

  .s-layout {
    color: var(--liff-primary_text_color);
    background-color: var(--liff-layout_bg_color);
    --s-gray: #979797 !important;
    --s-gray-light: #f0f0f0 !important;
    --s-danger: #fe0000;
  }

  .s-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .page {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 12px;
    background: var(--liff-content_bg_color);
  }

  .liff-reservation-form {
    width: 100%;

    &__card {
      border-radius: 10px;
      border: none;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

      .card-header {
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #e5e5ea;
        font-size: 16px;
        font-weight: 500;
        padding: 16px 16px 12px 16px;
      }
    }

    &__field-value {
      min-height: 1.5rem;
      border-bottom: 1px solid #e5e5ea;
      line-height: 1.5rem;
      font-size: 0.875rem;
    }
  }

  .footer-action-bar {
    width: 100%;

    button {
      width: 100%;
    }

    button + button {
      margin-top: 16px;
    }
  }
  </style>
