<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>{{ title }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <div class="col-12 col-xl-8 mb-2 mb-xl-0 d-flex justify-content-between">
                <h4 class="mb-0">{{ title }}</h4>
                <b-button
                  v-if="!isEdit && $permissions.has($permissions.consts.MY_CHANGE_PROFILE)"
                  variant="inverse-warning"
                  :to="{ name: 'Profile', query: { 'mode': 'edit' } }"
                >編輯基本資料
                </b-button>
              </div>
              <div class="col-12 col-xl-8 mt-3">
                <validation-error-alert
                  v-if="validationErrors"
                  :errors="validationErrors"
                ></validation-error-alert>
                  <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  :label="displayText('staff.staff_belong_branch', '所屬分店')"
                >
                  <div v-for="(branch) in branches" :key="branch.id" class="mb-2 d-flex">
                    <b-input-group class="col-7 pl-0">
                      <b-form-select
                        class="form-control"
                        v-model="branch.id"
                        disabled
                      >
                        <b-form-select-option :value="null" disabled>
                          {{ displayText('staff.branch_select' ,'請選擇分店') }}
                        </b-form-select-option>
                        <b-form-select-option
                          v-for="(branchOption, index) in branchOptions"
                          :key="index"
                          :value="branchOption.id"
                        >{{ displayBranchName(branchOption) }}
                        </b-form-select-option
                        >
                      </b-form-select>
                    </b-input-group>
                    <b-form-group
                      label-cols="4"
                      label-size="sm"
                      :label="displayText('staff.branch_sort' ,'店內順序')"
                      class="mb-0"
                    >
                      <b-form-input
                        class="mb-2 mr-sm-2 mb-sm-0"
                        type="number"
                        min=1
                        v-model="branch.order"
                        @change="orderChanged"
                        disabled
                      ></b-form-input
                    ></b-form-group>
                  </div>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  :label="
                    '*' + displayTextStaff('staff_code', '員工編號')
                  "
                  v-if="hiddenField('employee_code')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :value="staff.employee_code"
                    :readonly="true"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label-for="input-1"
                  :label="
                    displayRequireSign('name') + displayTextStaff('staff_name', '* 姓名')
                  "
                  v-if="hiddenField('name')"
                >
                  <b-form-input
                    id="input-1"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    :state="v$.staff.name.$error ? false : null"
                    v-model="staff.name"
                    :readonly="!isEdit"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!v$.staff.name.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  v-if="$permissions.has($permissions.consts.SALES_BIND_URI)"
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="聊天室綁定連結"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="chatBindUrl"
                    readonly
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="$permissions.has($permissions.consts.SALES_SPECIFIC_URI)"
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="業務綁定連結"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="salesBindUrl"
                    readonly
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  v-if="$permissions.has($permissions.consts.MY_BIND_URI)"
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="綁定網址"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="myBindUrl"
                    readonly
                  ></b-form-input>
                </b-form-group>
                  <b-form-group
                      label-cols="12"
                      label-cols-lg="2" 
                      label-size="sm"
                      :label="displayRequireSign('avatar') + displayTextStaff('staff_avatar', '個人頭像')"
                      label-for="input-1"
                      v-if="hiddenField('avatar')"
                  >
                  <li class="s-image-list-item s-draggable" v-if="image || staff.avatar_url">
                    <img class="s-cropped-image" :src="image || staff.avatar_url">
                    <button v-if="isEdit" class="btn btn-danger s-delete-btn" type="button" @click="deleteImage()">Ｘ</button>
                  </li>
                  <li class="s-image-list-item" v-else>
                    <ImageSelector v-if="isEdit" @input="selectImage">
                      <template #trigger="{ openFileSelector }">
                        <button class="btn s-trigger-btn" @click="openFileSelector">＋ 加入照片</button>
                      </template>
                    </ImageSelector>
                  </li>
                </b-form-group >

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  :label="displayRequireSign('email') + displayTextStaff('email', 'Email')"
                  v-if="hiddenField('email')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.email"
                    :readonly="!isEdit"
                  ></b-form-input>
                </b-form-group
                >

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  :label="displayRequireSign('mobile_phone') + displayTextStaff('mobile', '手機')"
                  v-if="hiddenField('mobile_phone')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.mobile_phone"
                    :readonly="!isEdit"
                  ></b-form-input>
                </b-form-group
                >

                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-if="hiddenField('job_level')"
                  :label="displayRequireSign('job_level') + displayTextStaff('staff_job_level', '職稱')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.job_level"
                    :readonly="!isEdit"
                  ></b-form-input
                  >
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-if="hiddenField('service_unit')"
                  :label="
                   displayRequireSign('service_unit') + displayTextStaff('staff_service_unit', '服務單位')
                  "
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.service_unit"
                    :readonly="!isEdit"
                  ></b-form-input
                  >
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  v-if="hiddenField('dept_name')"
                  :label="displayRequireSign('dept_name') + displayTextStaff('staff_dept', '部門')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.dept_name"
                    :readonly="!isEdit"
                  ></b-form-input
                  >
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  :label="displayRequireSign('description') + displayTextStaff('staff_description', '描述')"
                  v-if="hiddenField('description')"
                >
                  <b-form-textarea
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.description"
                    :state="v$.staff.description.$error ? false : null"
                    :readonly="!isEdit"
                    row="3"
                  ></b-form-textarea>
                  <b-form-invalid-feedback :state="!v$.staff.description.$error">
                    {{ displayTextStaff("staff_is_required", "此欄位為必填") }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  :label="displayRequireSign('office_phone') + displayTextStaff('staff_phone', '市話')"
                  v-if="
                    hiddenField('office_phone_area') ||
                    hiddenField('office_phone') ||
                    hiddenField('office_phone_ext')
                  "
                >
                  <div class="d-flex w-100 city-selector-set-has-value">
                    <b-input-group prepend="(" append=")" class="col-3 px-0">
                      <b-form-input
                        class="mb-2 mb-sm-0"
                        type="text"
                        v-model="staff.office_phone_area"
                        :readonly="!isEdit"
                        placeholder="區碼"
                      ></b-form-input>
                    </b-input-group>
                    <b-input-group append="#" class="col-8 px-0">
                      <b-form-input
                        class="mb-2 mb-sm-0"
                        type="text"
                        v-model="staff.office_phone"
                        :readonly="!isEdit"
                        placeholder="市話"
                      ></b-form-input>
                    </b-input-group>
                    <b-form-input
                      class="mb-2 mb-sm-0 col-1"
                      type="text"
                      v-model="staff.office_phone_ext"
                      :readonly="!isEdit"
                      placeholder="分機"
                    ></b-form-input>
                  </div>
                  <b-form-invalid-feedback :state="!v$.staff.office_phone_area.$error && !v$.staff.office_phone.$error && !v$.staff.office_phone_ext.$error ">
                    此欄位為必須為數字
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols="12"
                  label-cols-lg="2"
                  label-size="sm"
                  label="Line 連結網址"
                  v-if="hiddenField('line_contact_url')"
                >
                  <b-form-input
                    class="mb-2 mr-sm-2 mb-sm-0"
                    type="text"
                    v-model="staff.line_contact_url"
                    :readonly="!isEdit"
                  ></b-form-input
                  >
                </b-form-group>
              </div>
            </div>
            <div
              class="d-flex justify-content-center"
              style="margin-top: 100px"
            >
              <b-button
                class="mr-3"
                @click="handleCancel"
                variant="outline-danger"
              >返回
              </b-button>
              <b-button v-if="isEdit" @click="handleSubmit" variant="success">儲存</b-button>
            </div>
          </b-card>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { mapGetters } from "vuex";
import branchApi from "@/apis/branch";
import {required, numeric} from "@vuelidate/validators";
import meApi from "@/apis/me";
import store from "@/store";
import imageApi from "@/apis/image";
import ImageSelector from '@/components/ImageSelector'
import mixinGoBack from '@/mixins/Dashboard/Me/goBack'
import { displayText } from "@/utils/dashboardUtils";

export default {
  setup: () => ({v$: useVuelidate()}),
  mixins: [mixinGoBack],
  components: { ImageSelector },

  validations() {
    return {
      staff: {
        name: this.isRequiredFormData('name') ? { required } : {},
        employee_code: { required } ,
        office_phone_area: this.isRequiredFormData('office_phone_area') ? { required, numeric } : { numeric },
        office_phone: this.isRequiredFormData('office_phone') ? { required, numeric } : { numeric },
        office_phone_ext: this.isRequiredFormData('office_phone_ext') ? { required, numeric } : { numeric },
        mobile_phone: this.isRequiredFormData('mobile_phone') ? { required } : {},
        email: this.isRequiredFormData('email') ? { required } : {},
        description: this.isRequiredFormData('description') ? { required } : {},
      },
    };
  },

  data() {
    return {
      showLoading: true,
      validationErrors: null,
      staff: {
        name: null,
        mobile_phone: null,
        email: null,
        employee_code: null,
        service_unit: null,
        job_level: null,
        dept_name: null,
        line_contact_url: null,
        avatar_url: null,
        office_phone_area: null,
        office_phone: null,
        office_phone_ext: null,
        description: null,
      },
      image: null,
      allBranches: [],
      branches: [],
    };
  },

  computed: {
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    ...mapGetters("general", ["myBranches", "organization"]),
    branchOptions() {
      return this.allBranches
    },
    isEdit() {
      return this.$route.query.mode == 'edit'
    },
    title() {
      return this.isEdit ? '編輯基本資料' : '檢視基本資料'
    },
    chatBindUrl() {
      const org = this.$store.state.general.organization
      if (! org.short_code) {
        return '尚未設定短網址'
      }
      return `${process.env.VUE_APP_SHORT_URL_HOST}/${org.short_code}/s/${this.staff.id}`
    },
    salesBindUrl() {
      const org = this.$store.state.general.organization
      return `https://liff.line.me/${org.liff_id}/staff/${this.staff.id}/intro/`
    },
    myBindUrl() {
      let url = process.env.VUE_APP_BIND_URL_HOST || 'https://qa.fyr.at/aa/'
      return url + this.staff.id + '&inline=1'
    },
    fromPartnerPage() {
      return !!this.$route.params.partnerId;
    },
  },

  async mounted() {
    await store.dispatch('dashboardModule/fetchModule', {org_id: this.$store.state.general.organization.id, code: 'staff'});
    this.fetchProfile();
    if (this.$permissions.has(this.$permissions.consts.BRANCH_TOP_VIEW)) {
      await this.fetchAllBranches();
    } else {
      this.allBranches = this.myBranches.sort((a, b) => {
        if (a.level === b.level) {
          return a.order - b.order;
        }
        return a.level - b.level;
      })
    }
  },

  methods: {
    displayText,
    orderChanged() {
      this.branches.forEach(branch => {
        if (branch.order == null || branch.order == '') {
          return
        }
        if (branch.order <= 0) {
          branch.order = 1
        }
      })
    },
    displayBranchName(branch) {
      if (branch.level > 1) {
        return '-'.repeat(branch.level - 1) + ` ${branch.name} ${branch.branch_code}`
      }
      return `${branch.name} ${branch.branch_code}`
    },
    addBranch() {
      this.branches.push({ id: null, order: null });
    },
    removeBranch(index) {
      this.branches.splice(index, 1);
    },
    async fetchAllBranches() {
      const { data } = await branchApi.getBranches(this.organization, { is_all: true });
      this.allBranches = data.data.sort((a, b) => {
        if (a.level === b.level) {
          return a.order - b.order;
        }
        return a.level - b.level;
      });
    },
    displayRequireSign(field) {
      if (this.isRequiredFormData(field)) {
        return '* '
      } else {
        return '';
      }
    },
    displayTextStaff(key, defaultText) {
      if (this.fromPartnerPage) {
        return displayText(`partner.${key}`, defaultText);
      } else {
        return displayText(`staff.${key}`, defaultText);
      }
    },
    isRequiredFormData(field) {
      let isRequired = false;
      if (this.getModuleConfig("staff", "staff.required_fields")) {
        isRequired = this.getModuleConfig("staff", "staff.required_fields").includes(field);
      }

      if (this.getModuleConfig("staff", "staff.staff_roles")) {
        const roleConfig = this.getModuleConfig("staff", "staff.staff_roles");
        let roleSetting = roleConfig.find((role) => role.role === this.staff.role_code);
        if (roleSetting) isRequired = roleSetting.required_fields?.includes(field) || isRequired;
      }

      if (!this.hiddenField(field) && isRequired) {
        this.$swal({
          title: "錯誤",
          text: `${field} 欄位為必填，不能被隱藏`,
          type: "error",
        });
      }

      return isRequired;
    },
    hiddenField(field) {
      let isShow = true;
      if (this.getModuleConfig("staff", "staff.hidden_fields")) {
        isShow = !this.getModuleConfig(
          "staff",
          "staff.hidden_fields"
        )?.includes(field);
      }
      if (this.getModuleConfig("staff", "staff.staff_roles")) {
        const roleConfig = this.getModuleConfig("staff", "staff.staff_roles");
        let roleSetting = roleConfig.find((role) => role.role === this.staff.role_code);
        if (roleSetting) isShow = !roleSetting.hidden_fields.includes(field) && isShow;
      }
      return isShow;
    },
    async fetchProfile() {
      const response = await meApi.getProfile();
      this.staff = response.data.data;
      this.branches = this.staff.branches.filter(branch => {
        return branch.org_id == this.$store.state.general.organization.id;
      })
      this.branches.forEach(branch => {
        branch.order = branch.pivot.order
      })
      this.showLoading = false;
    },
    selectImage(image) {
      this.image = image;
    },
    deleteImage() {
      this.image = null;
      this.staff.avatar_url = null;
    },
    async handleSubmit() {
      const result = await this.v$.$validate();
      if (!result) return;

      if (this.image) {
        const resp = await this.uploadImage();
        if (! (resp.status && resp.status === 200)) {
          return;
        }
      }

      try {
        let response = await meApi.updateProfile(this.staff);

        if (response.status && response.status === 200) {
          this.$swal.fire({
            title: "更新成功",
            type: "success",
          });
          await store.dispatch('auth/fetchMe');
          this.$router.push({name: "Profile"});
        }
      } catch (error) {
        console.error(error)
        if (error.response.status && error.response.status === 422 && error.response.data.message) {
          this.validationErrors = error.response.data.message;
        } else {
          this.$swal.fire({
            title: "更新失敗",
            type: "error",
          });
        }
      }
    },
    async uploadImage() {
      try {
        let response = await imageApi.uploadByBase64(this.image);
        this.staff.avatar_url = response.data.data.image.url
        return response
      } catch (error) {
        this.$swal.fire({
          title: `上傳圖片失敗：${error.response.data.message}`,
          type: "error",
        });
      }
    },
    handleCancel() {
      this.isEdit ? this.$router.push({name: "Profile"}) : this.goBackByConfig();
    },
  },
};
</script>

<style scoped>
  .s-image-list {
    margin: -0.75rem;
    display: flex;
    flex-wrap: wrap;
  }

  .s-image-list-item {
    position: relative;
    width: 7rem;
    height: 7rem;
    border: 1px solid #ddd;
    margin: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .s-cropped-image {
    width: 100%;
    height: 100%;
  }

  .s-delete-btn {
    position: absolute;
    top: -0.75rem;
    right: -0.75rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  .s-image-selector {
    width: 100%;
    height: 100%;
  }

  .s-trigger-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
</style>
