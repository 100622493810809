import https from "./https";
import store from "@/store";

const benefitCard = {
  async init(handler) {
    const organization = store.state.liffGeneral.orgCode;
    return await https.get(`${organization}/liff/benefit-cards/init?handler=${handler}`);
  },
  async get(benefitCardCustomerId) {
    const organization = store.state.liffGeneral.orgCode;
    return await https.get(`${organization}/liff/benefit-cards/customer/${benefitCardCustomerId}`);
  },
  async coupons(benefitCardCustomerId,params) {
    const organization = store.state.liffGeneral.orgCode;
    return await https.get(`${organization}/liff/benefit-cards/customer/${benefitCardCustomerId}/redeem-codes`, { params });
  },
};

export default benefitCard;
