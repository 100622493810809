import { render, staticRenderFns } from "./BookingForm.vue?vue&type=template&id=204e5a1d&scoped=true&"
import script from "./BookingForm.vue?vue&type=script&lang=js&"
export * from "./BookingForm.vue?vue&type=script&lang=js&"
import style0 from "./BookingForm.vue?vue&type=style&index=0&id=204e5a1d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "204e5a1d",
  null
  
)

export default component.exports