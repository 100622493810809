import { render, staticRenderFns } from "./BodyShopForm.vue?vue&type=template&id=72df3be2&scoped=true&"
import script from "./BodyShopForm.vue?vue&type=script&lang=js&"
export * from "./BodyShopForm.vue?vue&type=script&lang=js&"
import style0 from "./BodyShopForm.vue?vue&type=style&index=0&id=72df3be2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72df3be2",
  null
  
)

export default component.exports