<template>
  <div>
    <h2>Home</h2>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  data() {
    return {

    }
  },
  mounted() {
    // 登入後，吃系統設定對應角色跳轉頁面
    const pathConfig = this.organizationThemeConfig?.dashboard?.default_path
    // 如果沒有設定 預設是首頁
    if (!pathConfig) {
      return;
    }
    let path = '/' + this.$route.params.org_code + pathConfig.default
    const roleArray = Object.keys(pathConfig.roles)
    if (roleArray.includes(this.role)) {
      path = '/' + this.$route.params.org_code + pathConfig.roles[this.role]
    }

    if (path === this.$route.path) {
      return;
    }

    this.$router.push(path)
  },
  computed: {
    ...mapState("general", {
      organizationThemeConfig: (state) => state.organizationThemeConfig,
    }),
    ...mapGetters('general', ['role']),
  }
}
</script>
