var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"s-layout",style:(_vm.colorObject)},[(_vm.isLoading)?_c('Loading',{attrs:{"loading-message":"載入中...","background-color":'#ffffff'}}):_vm._e(),_c('div',{staticClass:"s-container"},[(_vm.step == 1)?_c('div',[_c('img',{staticClass:"w-100 img-fluid",attrs:{"src":"/familyMart/start.png"},on:{"click":_vm.init}})]):(_vm.step == 2)?_c('div',[_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"p-2"},[_vm._v("score:"+_vm._s(_vm.score))]),_c('canvas',{ref:"gameCanvas",attrs:{"id":"gameCanvas","width":_vm.width,"height":_vm.width}})]),_c('div',{staticClass:"controls"},[_c('button',{staticClass:"controls__btn controls__up",on:{"click":function () {
              if (_vm.d !== 'DOWN') { _vm.d = 'UP'; }
            }}},[_vm._v(" ↑")]),_c('br'),_c('button',{staticClass:"controls__btn controls__left",on:{"click":function () {
              if (_vm.d !== 'RIGHT') { _vm.d = 'LEFT'; }
            }}},[_vm._v(" ← ")]),_c('button',{staticClass:"controls__btn controls__right",on:{"click":function () {
              if (_vm.d !== 'LEFT') { _vm.d = 'RIGHT'; }
            }}},[_vm._v(" → ")]),_c('button',{staticClass:"controls__btn controls__down",on:{"click":function () {
              if (_vm.d !== 'UP') { _vm.d = 'DOWN'; }
            }}},[_vm._v(" ↓ ")])])]):_vm._e(),_c('b-modal',{attrs:{"id":"modal-result","centered":"","no-close-on-esc":"","no-close-on-backdrop":"","hide-header":"","hide-footer":""}},[_c('div',{style:(_vm.colorObject)},[_c('p',{staticClass:"my-4"},[_vm._v("分數："+_vm._s(_vm.score))]),_c('SharedButton',{staticClass:"mb-2",attrs:{"variant":"primary"},on:{"click":_vm.init}},[_vm._v(" 再玩一次 ")]),_c('SharedButton',{staticClass:"mb-2",attrs:{"variant":"primary"}},[_vm._v(" 分享好友 ")]),_c('MemberButton',{attrs:{"variant":"primary","button-text":"回LINE官方帳號","type":"OA"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }