<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>我的主控台</b-breadcrumb-item>
            <b-breadcrumb-item active>系統總覽</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 grid-margin">
        <div class="card card-statistics">
          <div class="row">
            <div class="card-col col-xl-12 col-lg-12 col-md-12 col-12">
              <div class="card-body">
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    flex-column flex-sm-row
                  "
                >
                  <img
                    class="line-icon mr-1"
                    :style="lineIconStyle"
                  />
                  <div class="wrapper text-center text-sm-left">
                    <p class="card-text mb-0">
                        Line 資訊
                      <i
                        role="button"
                        class="fa fa-question-circle s-text-primary"
                        aria-hidden="true"
                        :id="`info-tooltip-line-bot`"
                      ></i>
                      <b-tooltip
                        :target="`info-tooltip-line-bot`"
                        triggers="hover"
                        placement="right"
                      >
                        <div>
                          <p>Basic ID: {{ botInfo.basicId }}</p>
                          <p v-if="botInfo.premiumId"> Premium ID: {{ botInfo.premiumId }}</p>
                          <img class="info-img" v-if="botInfo.pictureUrl" :src="botInfo.pictureUrl" />
                        </div>
                      </b-tooltip>
                    </p>
                    <div class="fluid-container">
                      <h3 class="mb-0 font-weight-medium">{{ botName }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-for="(info, idx) in infos"
              class="card-col col-xl-4 col-lg-4 col-md-4 col-6"
              v-bind:key="info.id"
            >
              <div class="card-body">
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    flex-column flex-sm-row
                  "
                >
                  <i
                    class="fa fa-3x mr-0 mr-sm-4 s-text-primary"
                    :class="info.icon"
                  ></i>
                  <div class="wrapper text-center text-sm-left">
                    <p class="card-text mb-0">
                      {{ info.title }}
                      <i
                        v-if="info.info"
                        role="button"
                        class="fa fa-question-circle s-text-primary"
                        aria-hidden="true"
                        :id="`info-tooltip-${idx}`"
                      ></i>
                      <b-tooltip
                        v-if="info.info"
                        :target="`info-tooltip-${idx}`"
                        triggers="hover"
                        placement="right"
                      >
                        {{ info.info }}
                      </b-tooltip>
                    </p>
                    <div class="fluid-container">
                      <h3 class="mb-0 font-weight-medium">{{ info.value }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import overviewApi from "@/apis/overview";
import { applyThousandsSeperator } from "@/utils/numberUtils";

export default {
  data() {
    const path = require("@/assets/images/sidebar/line-brands-solid.svg");

    return {
      isLoading: false,
      lineOaFollowers: 0,
      lineOaTargetedReaches: 0,
      lineOaBlocks: 0,
      smsPoint: null,
      lineOaMonthLimit: 0,
      lineOaMonthUsage: 0,
      botName: "",
      botInfo: {
        basicId: "",
        premiumId: "",
        pictureUrl: ""
      },
      lineIconStyle: {
        "mask-image": `url(${path})`,
        "-webkit-mask-image": `url(${path})`,
        "background-color": "green",
        "border-radius": "0",
      },
    };
  },
  computed: {
    infos() {
      let infos = [
        {
          title: "好友數量",
          value: applyThousandsSeperator(this.lineOaFollowers),
          icon: "fa-users",
          info: "此為將官方帳號加入好友的用戶數。即使加入好友的用戶將官方帳號封鎖或是刪除自己的帳號,加入好友人數也不會減少。",
        },
        {
          title: "目標好友",
          value: applyThousandsSeperator(this.lineOaTargetedReaches),
          icon: "fa-user-circle",
          info: "依性別、年齡及地區等屬性篩選好友後,所得出可觸及的分眾訊息接收對象的母數。包含經常使用LINE及LINE相關服務、且系統可高度精確地推測其屬性的好友。",
        },
        {
          title: "封鎖",
          value: applyThousandsSeperator(this.lineOaBlocks),
          icon: "fa-ban",
          info: "此為官方帳號被封鎖的次數。若對方解除封鎖您的帳號,封鎖次數將自減少。",
        },
      ];

      if (this.smsPoint !== null) {
        infos.push({
          title: "簡訊餘額",
          value: applyThousandsSeperator(this.smsPoint),
          icon: "fa-comments",
        });
      }

      infos.push({
        title: "已用額度/本月額度",
        value: `${applyThousandsSeperator(
          this.lineOaMonthUsage
        )} / ${applyThousandsSeperator(this.lineOaMonthLimit)}`,
        icon: "fa-comments-o",
      });

      return infos;
    },
  },
  mounted() {
    this.fetchOverview();
  },
  methods: {
    async fetchLine() {
      this.isLoading = true;
      try {
        const lineResponse = await overviewApi.getLineData();
        this.lineOaMonthLimit = lineResponse.data.data.line.month_limit;
        this.lineOaMonthUsage = lineResponse.data.data.line.month_usage;
      } catch (error) {
        this.$bvToast.toast(error.response.data.message, {
          title: "錯誤",
          variant: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchOa() {
      this.isLoading = true;
      try {
        const oaResponse = await overviewApi.getOaData();
        this.lineOaFollowers = oaResponse.data.data.line_oa.followers;
        this.lineOaTargetedReaches =
          oaResponse.data.data.line_oa.targeted_reaches;
        this.lineOaBlocks = oaResponse.data.data.line_oa.blocks;
      } catch (error) {
        this.$bvToast.toast(error.response.data.message, {
          title: "錯誤",
          variant: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchSms() {
      this.isLoading = true;
      try {
        const smsResponse = await overviewApi.getSmsData();
        this.smsPoint = smsResponse.data.data.sms.point;
      } catch (error) {
        this.$bvToast.toast(error.response.data.message, {
          title: "錯誤",
          variant: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    async fetchBot() {
      this.isLoading = true;
      try {
        const botResponse = await overviewApi.getBotData();
        this.botName = botResponse.data.data.bot.displayName;
        this.botInfo = botResponse.data.data.bot;
      } catch (error) {
        this.$bvToast.toast(error.response.data.message, {
          title: "錯誤",
          variant: "danger",
        });
      } finally {
        this.isLoading = false;
      }
    },
    fetchOverview() {
      this.fetchBot();
      this.fetchLine();
      this.fetchOa();
      this.fetchSms();
    },
  },
};
</script>

<style lang="scss">
.icon-lg {
  font-size: 2.5rem;
}

.s-text-primary {
  color: var(--sidebar-bg-color) !important;
}

.info-img {
  max-width: 64px;
  max-height: 64px;
}

.line-icon {
  width: 48px;
  height: 48px;
}
</style>
