<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item aria-current="location" active>
              {{ displayText("partner.partner_area", "合作夥伴專區") }}
            </b-breadcrumb-item>
            <b-breadcrumb-item v-if="fromPartnerPage"
              @click="backToList"
            >
              {{ displayText("partner.partner_management", "合作夥伴管理") }}
            </b-breadcrumb-item>

            <b-breadcrumb-item active v-if="!isVerifyPage">{{
              displayText("partner.partner_worker_management", "夥伴員工管理")
            }}</b-breadcrumb-item>
            <b-breadcrumb-item active v-if="isVerifyPage">{{
              displayText("partner.partner_worker_verify_page", "夥伴員工審核")
            }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div
        class="
          d-flex
          flex-column flex-xl-row
          align-items-start align-items-xl-center
          justify-content-between
          mb-4 mb-xl-2
        "
      >
        <h4
          class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold"
          v-if="!isVerifyPage"
        >
          {{ displayText("partner.partner_worker_management", "夥伴員工管理") }}
        </h4>
        <h4
          class="col-12 col-xl-2 mb-2 mb-xl-0 font-weight-bold"
          v-if="isVerifyPage"
        >
          {{
            displayText("partner.partner_worker_verify_page", "夥伴員工審核")
          }}
        </h4>
        <div
          class="
            col-12 col-xl-8
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
            <b-button
            v-if="
                checkPermission([consts.PARTNER_WORKER_MODIFY]) && hasSelectedWorker
            "
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="showPartnerWorkerChangePartner = true"
            >
            {{
                displayText("partner.partner_worker_batch_update_partner", "批次更新夥伴公司")
            }}
            </b-button>
            <b-button
                v-if="
                    checkPermission([consts.PARTNER_WORKER_MODIFY]) && hasSelectedWorker
                "
                class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
                variant="primary"
                @click="showPartnerWorkerChangeStaff = true"
            >
            {{
                displayText("partner.partner_worker_batch_update_staff", "批次更新業務")
            }}
            </b-button>
          <b-button
            v-if="
              checkPermission([consts.PARTNER_WORKER_VERIFY]) && isVerifyPage
            "
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            @click="approved"
          >
            {{
              displayText("partner.partner_worker_verify_approved", "審核成功")
            }}
          </b-button>
          <b-button
            v-if="
              checkPermission([consts.PARTNER_WORKER_VERIFY]) && isVerifyPage
            "
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="danger"
            @click="rejected"
          >
            {{
              displayText("partner.partner_worker_verify_rejected", "審核失敗")
            }}
          </b-button>
          <b-button
            v-if="checkPermissionAny([consts.PARTNER_WORKER_LIST_VIEW, consts.PARTNER_WORKER_VERIFY_LIST_VIEW])"
             class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
             variant="primary"
             @click="exportPartnerWorker()"
             >{{ displayText('partner.export_partner_worker', '匯出夥伴員工') }}
          </b-button>
          <b-button
            v-if="checkPermission([consts.PARTNER_WORKER_CREATE])"
            v-b-modal.modal-upload
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            ><i class="fa fa-plus"></i
            >{{
              displayText("partner.partner_worker_import", "批次匯入夥伴員工")
            }}</b-button
          >
          <b-button
            v-if="
              checkPermission([consts.PARTNER_WORKER_CREATE]) && !isVerifyPage
            "
            class="flex-shrink-0 mb-2 mb-xl-0 mr-2"
            variant="primary"
            :to="partnerWorkerCreateRoute"
            ><i class="fa fa-plus"></i
            >{{
              displayText("partner.partner_worker_add", "新增夥伴員工")
            }}</b-button
          >

          <b-input-group class="ml-0">
            <b-form-input
              v-bind:placeholder="
                displayText(
                  'partner.worker_search_placeholder',
                  '請輸入姓名、手機號碼、email、員工編號'
                )
              "
              v-model="query.keyword"
              v-on:keyup.enter="getPartnerWorkers()"
            ></b-form-input>
            <b-input-group-append>
              <b-button @click="getPartnerWorkers()"
                ><i class="fa fa-search"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
          <b-button
            v-b-toggle.collapse-1-inner
            variant="link"
            class="flex-shrink-0 ml-0 ml-xl-1"
          >
            {{ displayText("partner.worker_search_advanced", "進階搜尋")
            }}<i class="fa fa-chevron-down"></i>
          </b-button>
        </div>
      </div>
      <b-collapse id="collapse-1-inner" class="mt-2">
        <div class="border p-2">
          <b-form>
            <div class="d-flex flex-column flex-xl-row">
              <b-form-group
                v-if="hiddenSearchField('worker_name')"
                v-bind:label="displayText('partner.worker_name', '員工姓名')"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-lg="9"
                style="width: 100%"
              >
                <b-form-input v-model="query.name" class="mr-2" v-on:keyup.enter="handleSearch()"></b-form-input>
              </b-form-group>

              <b-form-group
                v-bind:label="displayText('partner.title', '合作夥伴')"
                label-cols-sm="3"
                label-cols-lg="2"
                style="width: 100%"
                v-if="hiddenSearchField('partner_name')"
              >
                <AppSingleSelect
                    v-model="query.partner"
                    :options="partners"
                    @input="handleSearch"
                />
              </b-form-group>
            </div>
            <div class="d-flex flex-column flex-xl-row">
              <b-form-group
                v-bind:label="displayText('partner.worker_sales', '業務員')"
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-lg="9"
                style="width: 100%"
                v-if="hiddenSearchField('staff_name')"
              >
                <AppSingleSelect
                    v-model="query.staff"
                    :options="staffs"
                    @input="handleSearch"
                />
              </b-form-group>
              <b-form-group
                v-if="hiddenSearchField('job_specialty')"
                v-bind:label="
                  displayText('partner.worker_job_specialty_name', '服務科室')
                "
                label-cols-sm="3"
                label-cols-lg="2"
                style="width: 100%"
              >
                <AppSingleSelect
                    v-model="query.job_specialties"
                    :options="jobSpecialties"
                    @input="handleSearch"
                />
              </b-form-group>
            </div>

            <div class="d-flex flex-column flex-xl-row">
              <!-- 狀態 -->
              <b-form-group
                v-if="hiddenSearchField('status')"
                v-bind:label="
                  displayText('partner.worker_verify_status', '審核狀態')
                "
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-lg="9"
                style="width: 100%"
              >
                <b-form-select
                  v-model="query.status"
                  :options="statusOptions"
                  class="mr-2 form-control form-control-sm"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                v-if="hiddenSearchField('dept_name')"
                v-bind:label="
                  displayText('partner.worker_dept_name', '部門名稱')
                "
                label-cols-sm="3"
                label-cols-lg="2"
                style="width: 100%"
              >
                <b-form-input
                  v-model="query.dept_name"
                  class="mr-2"
                  v-on:keyup.enter="handleSearch()"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="d-flex flex-column flex-xl-row">
              <!-- 是否綁定Line -->
              <b-form-group
                v-bind:label="
                  displayText('partner.worker_line_bound', '是否綁定Line帳號')
                "
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-lg="9"
                style="width: 100%"
                v-if="hiddenSearchField('line_bound')"
              >
                <b-form-radio-group v-model="query.line_bound">
                  <b-form-radio :value="0" class="mr-2">全部</b-form-radio>
                  <b-form-radio :value="1" class="mr-2">綁定</b-form-radio>
                  <b-form-radio :value="-1" class="mr-2">未綁定</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group
              v-bind:label="
                  displayText('partner.is_enabled', '是否啟用')
                "
                label-cols-sm="3"
                label-cols-lg="2"
                content-cols-lg="9"
                style="width: 100%"
                v-if="hiddenSearchField('is_enabled')"
              >
                <b-form-checkbox
                  v-model="query.is_enabled"
                  class="mr-2"
                >
                </b-form-checkbox>
              </b-form-group>
            </div>

            <div class="flex-column">
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0"
                @click="handleSearch"
                ><i class="fa fa-search"></i
                >{{ displayText("partner.search", "搜尋") }}</b-button
              >
              <b-button
                v-b-toggle.accordion-2
                variant="primary"
                class="mb-2 mb-xl-0"
                @click="resetSearch"
                >{{
                  displayText("partner.search_reset", "重置搜尋條件")
                }}</b-button
              >
            </div>
          </b-form>
        </div>
      </b-collapse>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="partnerWorkers"
                :fields="fields"
                @sort-changed="handleSort"
                show-empty
              >
                <template #head(checkbox)>
                  <b-form-checkbox
                    v-model="selectAll"
                    @change="toggleSelectAll"
                  ></b-form-checkbox>
                </template>
                <template #cell(checkbox)="data">
                  <b-form-checkbox
                    v-model="data.item.selected"
                  ></b-form-checkbox>
                </template>
                <template #cell(worker_name)="data">
                  <div v-if="data.item.highlight">
                    <div :style="{ 'color': data.item.highlightColor }">
                        <span>*</span> {{ data.item.name }}
                    </div>
                  </div>
                  <div v-else>
                    {{ data.item.name }}
                  </div>
                </template>
                <template #cell(staff_name)="data">
                  <div v-if="data.item.staff">
                    {{ data.item.staff.name }}
                  </div>
                </template>
                <template #cell(actions)="data">
                  <b-button
                    v-if="checkPermission([consts.PARTNER_WORKER_LIST_VIEW])"
                    variant="inverse-primary"
                    @click="partnerWorkerViewPush(data.item.id)"
                  >
                   <span class="mdi mdi-eye mr-1"></span> {{ displayText("partner.view", "查看") }}
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.PARTNER_WORKER_MODIFY])"
                    variant="inverse-warning"
                    @click="partnerWorkerEditPush(data.item.id)"
                  >
                   <span class="mdi mdi-lead-pencil mr-1"></span> {{ displayText("partner.edit", "編輯") }}
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.PARTNER_WORKER_DELETE])"
                    variant="inverse-danger"
                    @click="deletePartnerWorker(data.item.id)"
                  >
                   <span class="mdi mdi-delete mr-1"></span> {{ displayText("partner.delete", "刪除") }}
                  </b-button>
                </template>
                <template #empty>
                  <div class="text-center text-muted">
                    <p>查無資料</p>
                  </div>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="container" style="margin-top: 80px">
          <div class="row justify-content-center align-items-center">
            <div class="col-12 col-md-auto mb-3">
              <span>目前第 {{ currentPage }} 頁 / 總共 {{ Math.ceil(totalRows / query.per_page) }} 頁</span>
            </div>

            <div class="col-12 col-md-auto mb-3">
              <b-pagination
                class="my-auto"
                v-if="totalRows > 0"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="query.per_page"
              ></b-pagination>
            </div>

            <div class="col-12 col-md-auto mb-3">
              <span>每頁顯示數量：</span>
              <b-form-select
                v-model="query.per_page"
                :options="perPageOption"
                class="d-inline-block"
                style="width: auto;"

              ></b-form-select>
              <span> / 總數量: {{ totalRows }}</span>
            </div>
          </div>
        </div>

      </b-card-text>
    </b-card>
    <PartnerWorkerAddPartner
      :show-add-partner="showPartnerWorkerChangePartner"
      @selected="handlePartnerSelected"
      @close="showPartnerWorkerChangePartner = false"
    ></PartnerWorkerAddPartner>
    <PartnerWorkerAddStaff
      :show-add-staff="showPartnerWorkerChangeStaff"
      @selected="handleStaffSelected"
      @close="showPartnerWorkerChangeStaff = false"
    ></PartnerWorkerAddStaff>
    <b-modal id="modal-upload" :title="displayText('partner.batch_upload_excel_title', '批次上傳')">
      <b-overlay :show="showLoadingUpload">
        <div class="form-group">
          <label class="flex-shrink-0 mr-2 mb-0">請上傳帳號 Excel 檔案</label>
          <b-form-file plain @input="uploadPartnerWorker" placeholder="尚未選擇檔案" browse-text="瀏覽"></b-form-file>
        </div>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="outline-primary"
          @click="getSampleExcelFile(getModuleConfig('partner', 'sample_file.partner_worker_import_xls') ,'/excel/partner_worker_list_v2.xlsx')"
          ><i class="fa fa-file-text"></i>下載範例檔案</b-button
        >
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          關閉
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import partnerWorkerApi from "@/apis/partner-worker";
import PermissionChecker from "@/utils/PermissionChecker";
import * as consts from "@/consts";
import { displayText, getSampleExcelFile } from "@/utils/dashboardUtils";
import { updateQueryFromRoute, updateUrlWithoutReload } from "@/utils/updateUrl";
import moment from "moment";
import PartnerWorkerAddStaff from "@/pages/Dashboard/PartnerWorker/PartnerWorkerAddStaff.vue";
import PartnerWorkerAddPartner from "@/pages/Dashboard/PartnerWorker/PartnerWorkerAddPartner.vue";
import AppSingleSelect from "@/components/AppSingleSelect.vue";
import _ from "lodash";

export default {
  components: { PartnerWorkerAddStaff, PartnerWorkerAddPartner, AppSingleSelect },
  data() {
    return {
      consts: consts,
      showPartnerWorkerChangeStaff: false,
      showPartnerWorkerChangePartner: false,
      perPageOption: [
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      statusOptions: [
        { text: displayText("partner.all", "全部"), value: null },
        {
          text: displayText("partner.status_line_init", "前台新增人員"),
          value: "line_init",
        },
        {
          text: displayText("partner.status_dashboard_init", "後台新增人員"),
          value: "dashboard_init",
        },
        {
          text: displayText("partner.status_pending", "待審核"),
          value: "pending",
        },
        {
          text: displayText("partner.status_approved", "審核通過"),
          value: "approved",
        },
        {
          text: displayText("partner.status_rejected", "審核失敗"),
          value: "rejected",
        },
      ],
      selectAll: false,
      currentPage: 1,
      initialized: false,
      totalRows: 0,
      showLoading: false,
      showLoadingUpload: false,
      partners: [
        {
          value: null,
          text: displayText("partner.select_partner", "選擇夥伴公司"),
        },
      ],
      partnerWorkers: [],
      categoryOptions: [],
      priorityOptions: [],
      partnerWorker: null,
      update_staff_id: null,
      update_partner_id: null,
      staffs: [],
      jobSpecialties: [],
      query: {
        per_page: 10,
        partner_name: null,
        keyword: "",
        page: 1,
        name: null,
        status: null,
        dept_name: null,
        staff_name: null,
        job_specialty: null,
        line_bound: 0,
        sort_by: "partner_workers.created_at",
        order_by: "desc",
        is_enabled: true,
        partner: {
          text: displayText("partner.select_partner", "選擇夥伴公司"),
          value: null,
        },
        job_specialties: {
          text:"",
          value: null,
        },
        staff: {
          text: "",
          value: null,
        },
      },
      debouncedHandleSearch: _.debounce(() => {
        this.handleSearch();
      }, 1000),
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      dashboardModule: (state) => state.dashboardModule,
    }),
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
    ...mapGetters("route", ["routeQuery", "from", "historyQuery"]),
    fields() {
      let fields = [
        {
          key: "worker_name",
          label: displayText("partner.worker_name", "員工姓名"),
          sortable: true,
        },
        {
          key: "mobile_phone",
          label: displayText("partner.mobile", "手機號碼"),
        },
        {
          key: "partner_name",
          label: displayText("partner.partner_name", "合作夥伴名稱"),
          sortable: true,
        },
        {
          key: "job_specialty",
          label: displayText("partner.worker_job_specialty", "科室"),
          sortable: true,
        },
        {
          key: "job_title",
          label: displayText("partner.worker_job_title", "職稱"),
        },
        {
          key: "staff_name",
          label: displayText("partner.worker_staff_name", "業務"),
          sortable: true,
        },
        {
          key: "priority",
          label: displayText("partner.worker_priority", "重點客戶"),
          formatter: (value) => {
            if (value) {
              if (
                this.priorityOptions.find((options) => options.value === value)
              ) {
                return this.priorityOptions.find(
                  (options) => options.value === value
                ).text;
              } else {
                return "無法顯示";
              }
            }
          },
        },
        {
          key: "category",
          label: displayText("partner.worker_category", "客戶分類"),
          formatter: (value) => {
            if (value) {
              if (
                this.categoryOptions.find((options) => options.value === value)
              ) {
                return this.categoryOptions.find(
                  (options) => options.value === value
                ).text;
              } else {
                return "無法顯示";
              }
            }
          },
        },
        {
          key: "status",
          label: displayText("partner.status", "狀態"),
          formatter: (value) => {
            if (value === "approved") {
              return displayText("partner.status_approved", "審核通過");
            } else if (value === "pending") {
              return displayText("partner.status_pending", "待審核");
            } else if (value === "rejected") {
              return displayText("partner.status_rejected", "審核失敗");
            }  else if (value === "dashboard_init") {
              return displayText("partner.status_dashboard_init", "後台新建人員");
            }  else if (value === "line_init") {
              return displayText("partner.status_line_init", "前台新建人員");
            }
          },
        },
        {
          key: "is_enabled",
          label: displayText("partner.is_enabled", "是否啟用"),
          formatter: (value) => {
            return value
              ? displayText("partner.enabled", "啟用")
              : displayText("partner.disabled", "未啟用");
          },
        },
        {
          key: "created_at",
          sortable: true,
          label: displayText("partner.created_at", "建立時間"),
          formatter: (value) => {
            return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
          },
        },
        {
          key: "actions",
          label: displayText("partner.action", "管理"),
        },
      ];
      if (
        // this.isVerifyPage &&
        this.checkPermissionAny([this.consts.PARTNER_WORKER_VERIFY, this.consts.PARTNER_WORKER_MODIFY])
      ) {
        fields.unshift({
          key: "checkbox",
        });
      }
      const hiddenFields = this.getModuleConfig("partner", "partner_worker.hidden_fields")
      if (hiddenFields) {
        return fields.filter(field => !hiddenFields.includes(field.key))
      }
      return fields
    },
    fromPartnerPage() {
      return this.$route.params.partnerId;
    },
    isVerifyPage() {
      return this.$route.name === "PartnerWorkerListVerifying";
    },
    partnerWorkerCreateRoute() {
      if (!this.query.partner_id) {
        return { name: "PartnerWorkerCreate" };
      }

      const route = {
        name: "PartnerWorkerCreateByPartner",
        params: { partnerId: this.query.partner_id },
      };

      if (this.$route.params.partnerId) {
        route.query = { disablePartnerSelect: true };
      }

      return route;
    },
    queryWatcher() {
      return `${this.query.keyword}-${this.query.page}-${this.query.per_page}-${this.query.partner_id}`;
    },
    hasSelectedWorker() {
      return this.partnerWorkers
        .filter((item) => item.selected)
        .map((item) => item.id).length > 0;
    },
  },
  watch: {
    currentPage: function (newPage, oldPage) {
      if (!this.initialized || newPage === oldPage) return;
      this.query.page = newPage;
    },
    isVerifyPage: {
      handler(value) {
        if (value) {
          this.query.status = "pending";
          this.query.page_type = "verify";
        } else {
          this.query.status = null;
          this.query.page_type = null;
        }
        this.getPartnerWorkers();
      },
    },
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        this.debouncedHandleSearch()
      },
    },
  },
  async created() {
    if (this.$route.params.partnerId)
      this.query.partner_id = this.$route.params.partnerId;

    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);

    this.currentPage = this.query.page;
  },
  async mounted() {
    await this.getPartnerWorkers();
    this.setOptions();
    this.fetchMappings();
    this.fetchPartner();
    this.initialized = true;
  },
  methods: {
    displayText,
    getSampleExcelFile,
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.currentPage = 1;
      this.getPartnerWorkers();
    },
    async fetchMappings() {
      const { data } = await partnerWorkerApi.getMappings(this.organization,);
      this.staffs = data.data.staffs.map((staff) => {
        return {
          value: staff.name,
          text: `${staff.employee_code} - ${staff.name}`,
        };
      });
      this.jobSpecialties = data.data.job_specialties.filter((js) => {
        return js !== null || js !== undefined || js !== "";
      }).map((js) => ({
        value: js,
        text: js,
      }));
    },
    hiddenSearchField(fieldName) {
      if (this.getModuleConfig("partner", "partner_worker.hidden_fields")) {
        return !this.getModuleConfig("partner", "partner_worker.hidden_fields").includes(fieldName);
      } else {
        return true
      }
    },
    async exportPartnerWorker() {
      let params = {
        ...this.query,
        partner_name: this.query.partner?.value ?? null,
        staff_name: this.query.staff?.value ?? null,
        job_specialty: this.query.job_specialties?.value ?? null,
        is_verify: this.isVerifyPage
      };
      const filteredQuery = Object.keys(params).reduce((acc, key) => {
        if (
          params[key] !== null &&
          params[key] !== undefined &&
          params[key] !== ""
        ) {
          acc[key] = params[key];
        }
        return acc;
      }, {});
      partnerWorkerApi.exportPartnerWorker(this.organization, filteredQuery).then(() => {
        this.$swal({
          title: '成功',
          text: "可至“我的主控台”查看紀錄及下載檔案",
          showCancelButton: true,
          cancelButtonText: '確認',
          type: 'success',
          confirmButtonText: '前往查看',
        }).then((result)=>{
            if (result.value) {
              this.$router.push({
                name: "MyConsoleDownloadFileList",
                params: { org_code: this.$route.params.org_code },
              });
            }
          });
      })
    },
    partnerWorkerViewPush(id) {
      if (this.fromPartnerPage) {
        return this.$router.push({
          name: "PartnerWorkerFormByPartner",
          params: {
            partnerWorkerId: id,
            partnerId: this.$route.params.partnerId
          },
        });
      } else {
        return this.$router.push({
          name: this.isVerifyPage ? 'PartnerWorkerVerifyingView':'PartnerWorkerView',
          params: { partnerWorkerId: id },
        });
      }
    },
    partnerWorkerEditPush(id) {
      if (this.fromPartnerPage) {
        return this.$router.push({
          name: "PartnerWorkerFormEditByPartner",
          params: {
            partnerWorkerId: id,
            partnerId: this.$route.params.partnerId
          },
          query: { disablePartnerSelect: true },
        });
      } else {
        const route = {
          name: this.isVerifyPage? "PartnerWorkerVerifyingEdit": "PartnerWorkerEdit",
          params: { partnerWorkerId: id },
        };
        return this.$router.push(route);
      }
    },
    setOptions() {
      if (this.getModuleConfig("partner", "partner_worker.partner_worker_category")) {
        this.categoryOptions =
          this.getModuleConfig("partner", "partner_worker.partner_worker_category")
            .sort((a, b) => a.order - b.order)
            .map((c) => {
              return {
                text: c.text,
                value: c.key,
              };
            });
      }
      if (this.getModuleConfig("partner", "partner_worker.partner_worker_priority")) {
        this.priorityOptions =
          this.getModuleConfig("partner", "partner_worker.partner_worker_priority")
            .sort((a, b) => a.order - b.order)
            .map((c) => {
              return {
                text: c.text,
                value: c.key,
              };
            });
      }
    },
    toggleSelectAll() {
      this.partnerWorkers.forEach((item) => (item.selected = this.selectAll));
    },
    async approved() {
      let payload = {};
      payload.partner_worker_ids = this.partnerWorkers
        .filter((item) => item.selected)
        .map((item) => item.id);
      if (payload.partner_worker_ids.length == 0) {
        this.$swal.fire({
          title: "審核失敗",
          type: "warning",
          text: "至少請勾選一名對象",
        });
        return
      }
      this.showLoading = true;
      try {
        await partnerWorkerApi.verifyPartnerWorkerApproved(
          this.organization,
          payload
        );
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "審核通過",
        });
        this.getPartnerWorkers();
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "更新失敗",
            type: "error",
            text: error.response.data.message,
          });
        }
      }
      this.showLoading = false;
    },
    async rejected() {
      let payload = {};
      payload.partner_worker_ids = this.partnerWorkers
        .filter((item) => item.selected)
        .map((item) => item.id);
      if (payload.partner_worker_ids.length == 0) {
        this.$swal.fire({
          title: "審核失敗",
          type: "warning",
          text: "至少請勾選一名對象",
        });
        return
      }
      this.showLoading = true;
      try {
        await partnerWorkerApi.verifyPartnerWorkerRejected(
          this.organization,
          payload
        );
        this.$swal.fire({
          title: "完成",
          type: "success",
          text: "審核不通過",
        });
        this.getPartnerWorkers();
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "更新失敗",
            type: "error",
            text: error.response.data.message,
          });
        }
      }
      this.showLoading = false;
    },
    async getPartnerWorkers() {
      this.showLoading = true;
      try {
        let params = {
          ...this.query,
          partner_name: this.query.partner?.value ?? null,
          staff_name: this.query.staff?.value ?? null,
          job_specialty: this.query.job_specialties?.value ?? null,
        };

        const { data } = await partnerWorkerApi.getPartnerWorkers(
          this.organization,
          params
        );
        const highlightConfig = this.getModuleConfig("partner", "partner_worker.highlight");
        this.partnerWorkers = data.data.map((worker) => {
          if (highlightConfig) {
            worker.highlight = false;
            worker.highlightColor = highlightConfig.color;
            highlightConfig.config.forEach((condition) => {
              let checkBox = 0
              Object.entries(condition).forEach(([key, value]) => {
                // 時間格式的判斷
                if (key.includes("_at")) {
                  if (worker[key]) {
                    if (moment().subtract(value, 'days').isBefore(moment(worker[key]))) {
                      checkBox++;
                    }
                  }
                } else {
                  if (worker[key] === value) {
                    checkBox++;
                  }
                }
              });
              if (checkBox === Object.entries(condition).length) {
                worker.highlight = true;
              }
            });
          }
          return {
            ...worker,
            selected: false,
          };
        });
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: displayText("partner.api_failed", "讀取資料錯誤"),
          });
        }
      }
      this.showLoading = false;
    },
    async deletePartnerWorker(partnerWorkerId) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await partnerWorkerApi.removePartnerWorker(
              this.organization,
              partnerWorkerId
            );
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除",
              });
              await this.getPartnerWorkers();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: "錯誤",
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    async fetchPartner() {
      if (this.partners.length > 1) return;

      try {
        let params = {
          order_by: "partner_code",
          all: true
        };
        const { data } = await partnerWorkerApi.getPartnerList(
          this.organization,
          params
        );
        data.data.forEach((partner) => {
          this.partners.push({
            text: partner.partner_code + " " + partner.name,
            value: partner.name,
          });
        });
      } catch (error) {
        console.error(error);
        this.$swal(
          "錯誤",
          displayText("partner.api_failed", "讀取資料錯誤"),
          "error"
        );
      }
    },
    async uploadPartnerWorker(file) {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", file);

      try {
        let response = await partnerWorkerApi.uploadPartnerWorker(
          this.organization,
          formData
        );

        if (response.status === 200) {
          this.$swal.fire({
            title: "上傳成功",
            type: "success",
            html: `
              <div class="d-block">
                <div>總筆數 ${response.data.data.total} 筆</div>
                <div>新增 ${response.data.data.created} 筆</div>
                <div>更新 ${response.data.data.updated} 筆</div>
                <div class="text-primary mt-2">
                <div>*查無對應 ${this.displayText('partner.partner_id','合作夥伴 ID') } ${response.data.data.failed_partner} 筆</div>
                <div>*查無對應 ${this.displayText('partner.staff_id', '業務 ID') } ${response.data.data.failed_staff} 筆</div>
                <div>請於" ${this.displayText('partner.partner_worker_management', '夥伴員工管理')} " 進行編輯</div>
                </div>
              </div>`,
          });

          this.query.page = 1;
          await this.getPartnerWorkers();
        }
      } catch (e) {
        console.error(e);
        if (e.response.status === 422 && e.response.data.message) {
          this.$swal.fire({
            title: "檔案內容有誤",
            type: "error",
            text: e.response.data.message ?? null,
          });
        } else {
          this.$swal.fire({
            title: "上傳失敗",
            type: "error",
          });
        }
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-upload");
      }
    },
    handleSearch() {
      updateUrlWithoutReload(this.query, this.$store, this.$route);
      this.getPartnerWorkers();
    },
    resetSearch() {
      this.query = {
        per_page: 10,
        partner_id: this.$route.params.partnerId || null,
        partner_name: null,
        keyword: "",
        page: 1,
        name: null,
        status: null,
        dept_name: null,
        staff_name: null,
        line_bound: 0,
        sort_by: "partner_workers.created_at",
        order_by: "desc",
        is_enabled: true,
        job_specialty: null,
        partner: {
          text: displayText("partner.select_partner", "選擇夥伴公司"),
          value: null,
        },
        job_specialties: {
          text:"",
          value: null,
        },
        staff: {
          text: "",
          value: null,
        },
      };
      if (this.isVerifyPage) {
        this.query.page_type = "verify";
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
    checkPermissionAny(permissions) {
      const checker = new PermissionChecker;
      return checker.checkAny(permissions);
    },
    async handlePartnerSelected(partner) {
        let payload = {};
        payload.partner_worker_ids = this.partnerWorkers
          .filter((item) => item.selected)
          .map((item) => item.id);
        payload.partner_id = partner.id
        if (payload.partner_worker_ids.length == 0) {
          this.$swal.fire({
            title: "審核失敗",
            type: "warning",
            text: "至少請勾選一名對象",
          });
          return
        }
        this.showLoading = true;
        try {
          await partnerWorkerApi.batchUpdatePartnerWorker(
            this.organization,
            payload
          );
          this.$swal.fire({
            title: "成功",
            type: "success",
            text: this.displayText("partner.batch_update_partner_text_one", "已更新夥伴公司為") +
              partner.name +
              this.displayText("partner.batch_update_partner_text_two", "。")
            ,
          });
          this.getPartnerWorkers();
        } catch (error) {
          console.error(error);
          if (error.status !== 401) {
            this.$swal.fire({
              title: "更新失敗",
              type: "error",
              text: error.response.data.message,
            });
          }
        }
        this.showLoading = false;
    },
    async handleStaffSelected(staff) {
        let payload = {};
        payload.partner_worker_ids = this.partnerWorkers
          .filter((item) => item.selected)
          .map((item) => item.id);
        payload.staff_id = staff.id
        if (payload.partner_worker_ids.length == 0) {
          this.$swal.fire({
            title: "審核失敗",
            type: "warning",
            text: "至少請勾選一名對象",
          });
          return
        }
        this.showLoading = true;
        try {
          await partnerWorkerApi.batchUpdatePartnerWorker(
            this.organization,
            payload
          );
          this.$swal.fire({
            title: "成功",
            type: "success",
            text: this.displayText("partner.batch_update_staff_text_one", "已更新指派業務為") +
              staff.name +
              this.displayText("partner.batch_update_staff_text_two", "。")
            ,
          });
          this.getPartnerWorkers();
        } catch (error) {
          console.error(error);
          if (error.status !== 401) {
            this.$swal.fire({
              title: "更新失敗",
              type: "error",
              text: error.response.data.message,
            });
          }
        }
        this.showLoading = false;
    },
    backToList() {
      this.$router.push({ name: "PartnerList", query: this.historyQuery("PartnerList") });
    },
  },
};
</script>
<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}
</style>
