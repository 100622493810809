<!-- 這個 DynamicForm 是給後台使用的，由於兩者的顯示風格差異較大，故分成兩個不同版本，不要用錯了 -->
<template>
  <div>
    <!-- FIXME: 這裡照理 hidden 要在 subjects assign to data 時就處理乾淨，不該留在 template 層清洗-->
    <div
      v-for="(subject, index) in subjects.filter((x) => {
        return x.hidden == undefined || !x.hidden;
      })"
      :key="`field.${index}`"
      class="field"
    >
      <!-- type: text start -->
      <template v-if="subject.type === 'text'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <b-form-input
            :id="subject.id"
            class="mb-2 mr-sm-2 mb-sm-0"
            type="text"
            v-model="form[`${subject.id}`]"
            :state="v$.form[`${subject.id}`].$error ? false : null"
            :disabled="subject.disabled"
            :placeholder="displayPlaceholder(subject)"
          ></b-form-input>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: text end -->

      <!-- type: textarea start -->
      <template v-if="subject.type === 'textarea'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <b-form-textarea
            :id="subject.id"
            class="mb-2 mr-sm-2 mb-sm-0"
            type="text"
            v-model="form[`${subject.id}`]"
            :placeholder="displayPlaceholder(subject)"
            :disabled="subject.disabled"
            :row="subject.rows"
            :state="v$.form[`${subject.id}`].$error ? false : null"
          ></b-form-textarea>
          <small v-if="subject.memo" class="form-text text-muted">
            {{ subject.memo }}
          </small>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: textarea end -->

      <!-- type: select start -->
      <template v-if="subject.type === 'select'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <b-form-select
            :id="subject.id"
            v-model="form[`${subject.id}`]"
            :state="v$.form[`${subject.id}`].$error ? false : null"
            :options="optionsWithPlaceholder(subject)"
            :disabled="subject.disabled"
          ></b-form-select>
          <small v-if="subject.memo" class="form-text text-muted">
            {{ subject.memo }}
          </small>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: select end -->

      <!-- type: radio start -->
      <template v-if="subject.type === 'radio'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <b-form-radio-group
            :id="subject.id"
            v-model="form[`${subject.id}`]"
            :options="subject.options"
            :disabled="subject.disabled"
            :state="v$.form[`${subject.id}`].$error ? false : null"
          ></b-form-radio-group>
          <small v-if="subject.memo" class="form-text text-muted">
            {{ subject.memo }}
          </small>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: radio end -->

      <!-- type: checkbox start -->
      <template v-if="subject.type === 'checkbox'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <b-form-checkbox-group :id="subject.id" v-model="form[`${subject.id}`]">
            <b-form-checkbox
              v-for="(option, optionIndex) in subject.options"
              :key="index + '-' + optionIndex"
              :value="option.value"
              :disabled="subject.disabled"
            >
              {{ option.text }}
            </b-form-checkbox>
          </b-form-checkbox-group>
          <small v-if="subject.memo" class="form-text text-muted">
            {{ subject.memo }}
          </small>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: checkbox end -->

      <!-- type: datetime start -->
      <template v-if="subject.type === 'datetime'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <template v-if="subject.format === 'datetime'">
            <AppDateTimePicker
              :id="subject.id"
              v-model="form[`${subject.id}`]"
              :placeholder-for-date="displayPlaceholder(subject)"
              :placeholder-for-time="displayPlaceholder(subject) || ' '"
              :has-error="v$.form[`${subject.id}`].$error"
              :time-condition="subject.time_condition"
              :start-time="subject.start_time"
              :end-time="subject.end_time"
              :slot-minutes="subject.slot_minutes"
            />
          </template>
          <template v-if="subject.format === 'date'">
            <AppDatePicker
              :id="subject.id"
              v-model="form[`${subject.id}`]"
              :placeholder="displayPlaceholder(subject)"
              :has-error="v$.form[`${subject.id}`].$error"
            />
          </template>
          <template v-if="subject.format === 'time'">
            <AppTimePicker
              :id="subject.id"
              v-model="form[`${subject.id}`]"
              :placeholder="displayPlaceholder(subject) || ' '"
              :has-error="v$.form[`${subject.id}`].$error"
              :style-type="subject.time_condition"
              :start-time="subject.start_time"
              :end-time="subject.end_time"
              :slot-minutes="subject.slot_minutes"
            />
          </template>
          <small v-if="subject.memo" class="form-text text-muted">
            {{ subject.memo }}
          </small>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: datetime end -->

      <!-- type: uploader start -->
      <template v-if="subject.type === 'uploader'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <AppFileUploader v-model="form[`${subject.id}`]" :id="subject.id" :has-error="v$.form[`${subject.id}`].$error" />
        </b-form-group>
      </template>
      <!-- type: uploader end -->

      <!-- type: collection_select start -->
      <template v-if="subject.type === 'collection_select'">
        <CollectionSelectDashboard
          :subject="subject"
          :ref="`collection-select-${subject._id}`"
          :branch-id="getBranchIdByFieldId(subject.branch_field_id)"
          v-model="form[`${subject.id}`]"
          :validateError="v$.form[`${subject.id}`].$error"
        ></CollectionSelectDashboard>
      </template>
      <!-- type: collection_select end -->

      <!-- type: collection carousel start -->
      <template v-if="subject.type === 'collection_carousel'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <CarouselSelection
            :id="subject.id"
            v-model="form[`${subject.id}`]"
            :placeholder="displayPlaceholder(subject, '請選擇')"
            :candidate-fetcher="() => fetchCollectionItemsForCarousel(subject)"
            :value-matcher="matchCandidateForCollectionCarousel"
            :has-error="v$.form[`${subject.id}`].$error">
          </CarouselSelection>
          <small v-if="subject.memo" class="form-text text-muted">
            {{ subject.memo }}
          </small>
          <b-form-invalid-feedback :state="!v$.form[`${subject.id}`].$error">
            輸入的資料有誤或未填寫，請確認
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <!-- type: collection carousel end -->

      <!-- type: shop selection start -->
      <template v-if="subject.type === 'shop_selection'">
        <b-form-group
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          :label="subject.title"
          :label-for="subject.id"
        >
          <ShopSelection
            :value="deepGet(form[`${subject.id}`], 'value')"
            @setBranchModel="(branchModel) => handleShopSelectionChange(branchModel, subject)"
            :ref="`shop-selection-${subject._id}`"
            :branch-provider="subject.branch_provider || ''"
            :show-branch-code="subject.show_branch_code || false"
            :placeholder="subject.default_select_text || '請選擇'"
            api-source="admin"
          />
        </b-form-group>
      </template>
      <!-- type: shop selection end -->
    </div>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import collectionApi from "@/apis/collection";
import AppDatePicker from "@/components/AppDatePicker.vue";
import AppDateTimePicker from "@/components/AppDateTimePicker.vue";
import AppFileUploader from '@/components/AppFileUploader.vue';
import AppTimePicker from "@/components/AppTimePicker.vue";
import ShopSelection from "@/components/Page/Liff/Branch/ShopSelection";
import Collection from "@/models/Collection.model.js";
import CarouselSelection from "./CarouselSelection";
import CollectionSelectDashboard from "./CollectionSelectDashboard.vue";
import deepGet from "lodash/get";

export default {
  components: {
    AppDatePicker,
    AppDateTimePicker,
    AppFileUploader,
    AppTimePicker,
    ShopSelection,
    CarouselSelection,
    CollectionSelectDashboard,
  },
  props: {
    inputSubjects: {
      type: Array,
      default: () => {
        return [];
      },
      validator(subjects) {
        if (subjects.length === 0) {
          return true
        }

        const result = subjects.every(subject => Object.keys(subject).includes('id'))
        if (result === false) {
          console.error('[DynamicForm] The "id" property is required for every subject passed in as prop for DynamicForm.')
        }
        return result
      },
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    inputSubjects: {
      handler: function (val) {
        for (let i = 0; i < val.length; i++) {
          let subject = val[i];

          // 把 subject.config 轉換成 subject 的屬性，這裡是專門用來處理 survey_subjects 的 DB 儲存格式用的
          if (subject.config) {
            let config = subject.config;
            delete subject.config;
            val[i] = {
              ...subject,
              ...config,
            };
          }
        }

        this.subjects = val;

        this.prepareComplexComponentOptions()
      },
      immediate: true,
    },
    form: {
      handler: function (val) {
        this.$emit("input", val);

        this.prepareComplexComponentOptions()
      },
      immediate: true,
    },
    value: {
      handler: function (val) {
        this.form = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      subjects: [],
      form: this.value || {},
      shopSelectionsArePrepared: false,
      branchBondCollectionsArePrepared: false,
    };
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  validations() {
    let form = {};

    for (let i = 0; i < this.subjects.length; i++) {
      let subject = this.subjects[i];
      form[`${subject.id}`] = subject.required ? { required } : {};
    }

    return {
      form,
    };
  },
  methods: {
    deepGet,
    displayPlaceholder(subject, defaultMessage = null) {
      if (subject.placeholder) return subject.placeholder;
      if (defaultMessage !== null) return defaultMessage;
      return "";
    },
    async validate() {
      const result = await this.v$.$validate();

      if (!result) {
        const element = document.getElementById(this.v$.$errors[0].$property);
        element.scrollIntoView({ behavior: "smooth" });
        console.warn("[DynamicFormDashboard] Validation failed.");
        return false;
      }

      return true;
    },
    optionsWithPlaceholder(subject) {
      if (subject.placeholder) {
        return [
          {
            text: subject.placeholder,
            value: null,
          },
          ...subject.options,
        ];
      }

      return subject.options;
    },
    async fetchCollectionItemsForCarousel(subject) { // NOTE: 若 DynamicForm 與 DynamicFormDashboard 需要合併，則將此段邏輯改以 vue provide/inject 方式實作
      try {
        const collection = deepGet(await collectionApi.getCollection(subject.collection_id), 'data.data', null)
        const collectionModel = new Collection(collection)

        const getValueFromCollectionItemByFieldId = (collectionItem, fieldId) => {
          if (fieldId === 'default') {
            return collectionModel.textForOption(collectionItem.id)
          }
          return deepGet(collectionItem.data.find(field => field.field_id === fieldId), 'value', null)
        }

        return collection.items.sort((a, b) => a.order - b.order).map((item) => ({
          id: item.id,
          title: getValueFromCollectionItemByFieldId(item, subject[`collection_title`]),
          image: getValueFromCollectionItemByFieldId(item, subject[`collection_image`]),
          description: getValueFromCollectionItemByFieldId(item, subject[`collection_description`]),
          value: {
            type: 'collection_carousel',
            collection_item_id: item.id,
            collection_id: collection.id,
            text: item.is_enabled ? collectionModel.textForOption(item.id) : `(已停用)${collectionModel.textForOption(item.id)}`,
          },
          placeholderWhenSelected: item.is_enabled ? collectionModel.textForOption(item.id) : `(已停用)${collectionModel.textForOption(item.id)}`,
        }))
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", `讀取 ${subject.title} 選項時發生錯誤，請重新整理後再試一次`, "error")
        return []
      }
    },
    matchCandidateForCollectionCarousel(candidate, incomingValue) {
      if (Array.isArray(incomingValue)) {
        return deepGet(candidate, 'value.collection_id') === deepGet(incomingValue[0], 'collection_id') &&
          deepGet(candidate, 'value.collection_item_id') === deepGet(incomingValue[0], 'collection_item_id')
      } else {
        return deepGet(candidate, 'value.collection_id') === deepGet(incomingValue, 'collection_id') &&
          deepGet(candidate, 'value.collection_item_id') === deepGet(incomingValue, 'collection_item_id')
      }
    },
    async prepareComplexComponentOptions() {
      if (false === this.shopSelectionsArePrepared && this.inputSubjects.length > 0) {
        this.fetchBranchesForShopSelections()
        this.shopSelectionsArePrepared = true
      }

      if (false === this.branchBondCollectionsArePrepared && (Object.keys(this.form).length > 0 || this.inputSubjects.length > 0)) {
        this.prepareBranchBondCollectionItems()
        this.branchBondCollectionsArePrepared = true
      }
    },
    async prepareBranchBondCollectionItems() {
      this.$nextTick(async () => {
        return await Promise.all(
          this.inputSubjects
            .filter(subject => subject.type === 'shop_selection')
            .map(subject => {
              this.updateCollectionItemsByBranchFieldId(subject._id)
            })
        )
      })
    },
    async fetchBranchesForShopSelections() {
      this.$nextTick(async () => {
        return await Promise.all(
          this.inputSubjects
            .filter(subject => subject.type === 'shop_selection')
            .map(subject => {
              this.$refs[`shop-selection-${subject._id}`][0].fetchBranchesOptions()
              this.updateCollectionItemsByBranchFieldId(subject._id)
            })
        )
      })
    },
    handleShopSelectionChange(branchModel, subject) {
      this.$set(this.form, subject.id, {
        branch_provider: subject.branch_provider,
        text: branchModel.name,
        value: branchModel.id,
      })

      this.$nextTick(() => {
        this.updateCollectionItemsByBranchFieldId(subject._id)
      })
    },
    async updateCollectionItemsByBranchFieldId(fieldId) {
      const relatedCollectionSelects = this.inputSubjects.filter(inputSubject => inputSubject.branch_field_id === fieldId)
      relatedCollectionSelects.map(collectionSelect => {
        this.$refs[`collection-select-${collectionSelect._id}`][0].fetchCollectionItems()
      })
    },
    getBranchIdByFieldId(fieldId) {
      return deepGet(this.form, `${fieldId}.value`, null)
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/shared/components/_fields.scss";

body {
  --liff-button-color: #cccccc;
}

.field {
  &__label {
    font-size: 20px;
  }
  &__input {
    font-size: 18px;
    background-color: #ffffff;
  }

  &__desc {
    font-size: 16px !important;
  }

  *[id] {
    scroll-margin-top: 61px; // NOTE: height of header
  }
}



.row {
  margin: 0 -0.5rem;
}
</style>
